import React from "react";
import Breadcrumbs from "Components/Common/Breadcrumb";
import { Container } from "reactstrap";
import { useParams } from "react-router-dom";
import VersionDetail from "./Logs/VersionDetail";

function Version() {
  const {id} = useParams(); 
  document.title = "Activity Logs | Content Pro";

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Activity"   breadcrumbItem="Versioning Log" isGoBackButton = {true} project={true} to={`/posts-details/${id}/logs`}
        />
        <VersionDetail/>
      </Container>
    </div>
  );
}

export default Version;
