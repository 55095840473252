import React, { useCallback, useRef, useState } from "react";
import CustomIcon from "Components/Custom/CustomIcon";
import {Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, Modal, ModalBody, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table,} from "reactstrap";
import classnames from "classnames";
import DATA from "./data/publish"; 
import Breadcrumbs from "Components/Common/Breadcrumb";
import { useParams } from "react-router-dom";
import { LinkWithQuery } from "helpers/link_helper";
import Header from "./UI/activity-header";
import SocialIcon from "../Settings/Integrations/SocialIcon";

const statusBadge = (status) => {
  switch (status){
    case "Published":
        return <span className="font-size-11 badge badge-soft-success">Published</span>;
    case "Scheduled":
        return <span className="font-size-11 badge badge-soft-warning">Scheduled</span>;
    case "Draft":
        return <span className="font-size-11 badge badge-soft-info">Draft</span>;
    case "Cancelled":
      return <span className="font-size-11 badge badge-soft-danger">Cancelled</span>;
    default:
        return <span className="font-size-11 badge badge-soft-secondary">Not specified</span>;
  }
}


const PostPublish = () => {
  document.title = "Publish Activity | Content Pro";

  const {id} = useParams(); 

  const [activeTab, setActiveTab] = useState("All");
  const [publishButton, setPublishButton] = useState<boolean>(false); 
  const [action, setAction] = useState<string>(""); 
  const [modal, setModal] = useState<boolean>(false);


  const toggle = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const actionButton = useCallback((status, id, platform) => {
    switch (status){
      case "Published":
          return <a href="www.google.com" target="blank" className="btn btn-soft-success" title="View Post">
          <CustomIcon className="mdi " iconName= "mdi-eye-outline" id={`actionButton_${id}`} text="View Post"/>
        </a>;
      case "Scheduled":
          return <button className="btn btn-soft-warning" title="Cancel Post" onClick={()=>{setModal(true); setAction("Cancel");}}>
          <CustomIcon className="mdi " iconName= "mdi-cancel" id={`actionButton_${id}`} text="Cancel Post"/>
        </button>;
      case "Draft":
          return <LinkWithQuery project = {true} to={platform} className="btn btn-soft-info" title="Edit Post">
          <CustomIcon className="mdi " iconName= "mdi-pencil-outline" id={`actionButton_${id}`} text="Edit Post"/>
        </LinkWithQuery>;
      case "Cancelled":
        return <LinkWithQuery project = {true} to={platform} className="btn btn-soft-danger" title="Republish">
        <CustomIcon className="mdi " iconName= "mdi-cached" id={`actionButton_${id}`} text="Republish"/>
      </LinkWithQuery>;
      default:
          return <span>Error</span>;
    }
  }, [])

  const data = activeTab === "All" ? DATA : DATA.filter((item) => item.status === activeTab);

  const inputRef = useRef<HTMLInputElement>(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    // Check if inputRef.current is not null before accessing its value
    if (inputRef.current) {
      const selectedDateTime = inputRef.current.value;
      console.log("Selected Date and Time:", selectedDateTime);
    } else {
      console.error("Input ref is null");
    }
    setModal(false);
  };

  // console.log('publish data', DATA)

  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const selectableItems = data
        .filter((item) => item.status !== "Published" && item.status !== "Scheduled" && item.readyStatus !== "in progress");
      setSelectedItems(selectableItems);
    } else {
      setSelectedItems([]);
    }
  };
  

  const handleSelectItem = (item: any) => {
    if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.id));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };
  

  return(
    <>
    <Modal isOpen={modal} toggle={() => setModal(false)} centered={true}>
      <div className="modal-content">
      <ModalBody className="px-4 py-5">
        <button type="button" onClick={() => setModal(false)} className="btn-close position-absolute end-0 top-0 m-3"></button>
        <div className="mb-4 text-center">
          <div className="text-primary font-size-20">
           {action === "Publish" && "Publish All Posts"}
           {action === "Schedule" && "Schedule All Posts"}
           {action === "Cancel" && "Cancel Post Request"}
          </div>
        </div>
          {action === "Publish" && <p className="text-muted font-size-16 mb-4 text-center">Are you sure you want to publish the post?</p>}
          {action === "Cancel" ? <p className="text-muted font-size-16 mb-4 text-center">Are you sure you want to cancel the publish request?</p> :
          <div className="row mx-auto">
            {selectedItems.map(item => (
              <div key={item.id} className="col-md-6">
                <div className="d-flex mb-3"><SocialIcon platform={item.platform} title={item.connectedAccount} type={item.accountType}/></div>
              </div>
            ))}
          </div>
          }
          
          {action === "Schedule" ? <>
            <Form onSubmit={handleSubmit}>
              <FormGroup className="select2-container ">
                <Label className="col-form-label">Pick time</Label>
                <Input type="datetime-local" id="publish-time" name="publish-time" innerRef={inputRef} required/>
              </FormGroup>
              <div className="hstack gap-2 mb-0 justify-content-end">
                <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
                <button type="submit" className="btn btn-success" onClick={() => setModal(false)}>Schedule</button>
              </div>
            </Form>
          </>
          : 
          <div className={`hstack gap-2 mb-0 justify-content-${action === "Publish" ? 'end mt-4 me-2' : 'center'}`}>
            <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
            <button type="submit" className="btn btn-danger" onClick={() => setModal(false)}>Yes</button>
          </div>
        }
      </ModalBody>
      </div>
    </Modal>
    
     <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Activity" breadcrumbItem="Publish" isGoBackButton={true} project={true} to={`/posts-details/${id}`}/>
          <Card>
            <CardBody>
              <Header author="Charles Brown" title="ChatGPT as Visa Application Assistant for Any Country in the World" date="08 Sept, 2019" status="Idea in Motion"/>


              <Row>
               <div className="text-end">
                <Dropdown
                  isOpen={publishButton}
                  toggle={() => setPublishButton(prev => !prev)}
                >
                  <DropdownToggle tag="button" type="button"  className="btn btn-soft-info">
                    Publish All <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => {setModal(true); setAction("Publish")}}><i className="mdi mdi-upload font-size-16 align-middle me-2"></i>Publish Now</DropdownItem>
                    <DropdownItem onClick={() => {setModal(true); setAction("Schedule")}}><i className="mdi mdi-calendar-clock font-size-16 align-middle me-2"></i>Schedule For Later</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                </div>
              </Row>

              <Row>
                <Nav tabs className="nav-tabs-custom mx-3 overflow-hidden">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({active: activeTab === "All",})}
                      onClick={() => { toggle("All");}}
                    >
                      <span>All</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({active: activeTab === "Scheduled",})}
                      onClick={() => { toggle("Scheduled");}}
                    >
                      <span >Scheduled</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({active: activeTab === "Draft",})}
                      onClick={() => { toggle("Draft");}}
                    >
                      <span>Drafts</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({active: activeTab === "Published",})}
                      onClick={() => { toggle("Published");}}
                    >
                      <span>Published</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({active: activeTab === "Cancelled",})}
                      onClick={() => { toggle("Cancelled");}}
                    >
                      <span>Cancelled</span>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab} className="p-3 text-muted table-responsive mt-2 display-grid overflow-auto">
                  <TabPane tabId={activeTab}>
                    <Row>
                      <Col sm="12">
                        <Table className="mb-5 project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline table table-hoverS">
                          <thead className="table-light">
                            <tr>
                              <th>
                                <Input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                              </th>
                              <th>#</th>
                              <th>Platform</th>
                              <th>Publish Status</th>
                              <th>Last Modified</th>
                              <th>Word Count</th>
                              <th>Publication Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.map((item, index) =>
                              <tr key={item.id}>
                                <td>
                                  <Input
                                    type="checkbox"
                                    checked={selectedItems.some((selectedItem) => selectedItem.id === item.id)}
                                    onChange={() => handleSelectItem(item)}
                                    disabled={item.status === "Published" || item.status === "Scheduled" || item.readyStatus === "in progress"}
                                  />
                                </td>
                                <td>{index + 1}</td>
                                <td><div className="d-flex"><SocialIcon platform={item.platform} title={item.connectedAccount} type={item.accountType}/></div></td>
                                <td>{statusBadge(item.status)}</td>
                                <td>{item.modifiedAt}</td>
                                <td>{item.characterCount}</td>
                                <td>{item.readyStatus === "ready" ? <span className="badge bg-success font-size-11">Ready</span> : <span className="bg-info badge font-size-11">In Progress</span>}</td>
                                <td>{actionButton(item.status, item.id, item.platform)}</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default PostPublish
