import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Button, Label, Input, FormFeedback, Form } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import withRouter from "Components/Common/withRouter";
// Import Breadcrumb
import Breadcrumb from "Components/Common/Breadcrumb";
import avatar from "../../assets/images/users/avatar-1.jpg";
import { useDropzone } from 'react-dropzone';


const UserProfile = () => {
  // meta title
  document.title = "Profile | Content Pro";

  const user = JSON.parse(localStorage.getItem("authUser") || "{}");
  const name = user.name;
  const email = user.email;
  // const emailVerification = user.email_verification;
  // const role = user.role;

  const [selectedImage, setSelectedImage] = useState<string | null>(avatar);
  //  img upload
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFile) => {
      let reader = new FileReader();
      console.log(acceptedFile[0])
      let file = acceptedFile[0];
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
        validation.setFieldValue('image', reader.result)
      };
      reader.readAsDataURL(file);
    },
  });

  const validation = useFormik({
    initialValues: {
      username: name || '',
      email: email || "", 
      image: avatar,
      phone: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your UserName"),
      email: Yup.string().required("Please Enter Email address"), 
      image: Yup.string().required("Please Select Image"),
      phone: Yup.number()
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .min(8, "Phone Number is too small")
      // .max(30, "Phone Number is too large"), 
    }),
    onSubmit: (values) => {
      console.log(values)
    }
  });


  //   initialValues: {
  //     email: email || "", 
  //     phone: '',
  //   },
  //   validationSchema: Yup.object({
  //     email: Yup.string().required("Please Enter Email address"), 
  //     phone: Yup.number()
  //     .positive("A phone number can't start with a minus")
  //     .integer("A phone number can't include a decimal point")
  //     .min(8, "Phone Number is too small")
  //     // .max(30, "Phone Number is too large"), 
  //   }),
  //   onSubmit: (values) => {
  //     console.log(values)
  //   }
  // });


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Content Pro" breadcrumbItem="Profile" />
              {/* {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null} */}

          <Row className="justify-content-center mt-5">
            <Col md={8} lg={6} xl={5}>
              {/* <h4 className="card-title mb-4">User Info</h4> */}
              <Card >
                <CardBody>
                  <Form
                    id="user-info"
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >

                  <Row>
                    <Col className="mx-auto">
                      <div className="mb-3">
                        <Label htmlFor="name-input">Image</Label>
                        <div className="text-center" {...getRootProps()}>
                          <div className="position-relative d-inline-block" >
                            <div className="position-absolute bottom-0 end-0">
                              <input className="form-control d-none" id="business-image-input" type="file" accept="image/png, image/gif, image/jpeg" {...getInputProps()}/>
                            </div>
                            <div className="avatar-xl rounded">
                              <div className="avatar-title bg-light ">
                                <img src={selectedImage || ''} id="logo-img" alt="" className="avatar-xl rounded h-auto " />
                              </div>
                            </div>
                          </div>
                          {validation.touched.image && validation.errors.image ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.image}</FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                  </Row>

                    <div className="form-group">
                      <Label className="form-label">User Name</Label>
                      <Input
                        name="username"
                        className="form-control"
                        placeholder="Enter User Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username || ""}
                        invalid={validation.touched.username && !!validation.errors.username}
                      />
                      {validation.touched.username && validation.errors.username ? (
                        <FormFeedback type="invalid">
                          {typeof validation.errors.username === 'string' && validation.errors.username}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="form-group mt-4">
                      <Label className="form-label">Email Address</Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter Email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={validation.touched.email && !!validation.errors.email}
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {typeof validation.errors.email === 'string' && validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="form-group  mt-4">
                      <Label className="form-label">Phone Number</Label>
                      <Input
                        name="phone"
                        className="form-control"
                        placeholder="Enter phone Number"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.phone || ""}
                        invalid={validation.touched.phone && !!validation.errors.phone}
                      />
                      {validation.touched.phone && validation.errors.phone ? (
                        <FormFeedback type="invalid">
                          {typeof validation.errors.phone === 'string' && validation.errors.phone}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="text-end mt-4">
                      <Button type="submit" color="success">Save Changes</Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
