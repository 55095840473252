import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Spinner } from "reactstrap";
import { CardBody, Col, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";

function DataWrapper({ onGetData, pagination, isLoading, children, buttonText = "", isAddButton, to='', isDeleted = false, title, sort, filterData = [{value: '', label: ''}], hasFilter = false, filterId='', query='' }) {
  const dispatch = useDispatch();


  // pagination
  const { current_page, last_page, per_page, total } = pagination;
  const [page, setPage] = useState(current_page);
  const [limit, setLimit] = useState(per_page);
  const [filter, setFilter] = useState(''); 

  const memoizedGetData = useCallback(onGetData, []);

  const memoizedSort = useMemo(() => sort, [sort.sort_column, sort.sort]);

  // sending request through redux
  useEffect(() => {
    dispatch(memoizedGetData({ query, page, limit, keyword: search, sort_column: memoizedSort.sort_column, sort: memoizedSort.sort, filterId, filter }));
  }, [dispatch, page, limit, memoizedGetData, isDeleted, memoizedSort, filter]);


  // search logic 
  const [search, setSearch] = useState("");
  const handleSearch = () => {
    dispatch(memoizedGetData({ query, page: 1, limit, keyword: search, sort_column: memoizedSort.sort_column, sort: memoizedSort.sort }));
  };
  const handleClearSearch = () => {
    setSearch('');
    dispatch(memoizedGetData({ query, page: 1, limit, keyword: '', sort_column: memoizedSort.sort_column, sort: memoizedSort.sort }));
  };

  return (
    <>
      {isLoading ? (
        <Spinner color="primary" className="position-absolute top-50 start-50" />
      ) : (
        <CardBody>
          {/* title */}
          <div className="d-flex align-items-center mb-4">
            <h3 className="mb-0 flex-grow-1 ">{title}</h3>
            <div className="flex-shrink-0">{/* ride justified data */}</div>
          </div>

          {/* Top content */}
          <div className="overflow-hidden">
            <Row>
              {/* Pagination */}
              <Col md={2} sm={4}>
                <div className="mb-2">
                  <select className="form-select pageSize" value={limit} onChange={(event) => { setLimit(parseInt(event.target.value)); setPage(1) }}>
                    <option value={10}>10</option>
                    {/* <option value={2}>2</option> */}
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </Col>
              {/* search */}
              <Col sm={4}>
                <form onSubmit={e => { e.preventDefault(); handleSearch(); }} className="app-search mb-2 p-0">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        className="form-control mb-sm-4"
                        placeholder="Search..."
                      />
                      <div className="input-group-append">
                        <button className="btn btn-soft-success search-button" type="button" onClick={handleSearch}>
                          <i className="mdi mdi-magnify" />
                        </button>
                        {search && <button
                          className="btn btn-soft-danger ms-2"
                          type="button"
                          onClick={handleClearSearch}
                        >
                          <i className="mdi mdi-close" />
                        </button>}
                      </div>
                    </div>
                  </div>
                </form>
              </Col>

              {/* filter */}
              {hasFilter && <Col md={2} sm={4}>
                <div className="mb-2">
                  <select className="form-select pageSize" value={filter} onChange={(event) => {setFilter(event.target.value)}}>
                  <option value=''>All Roles</option>
                    {filterData?.map((item) => <option value={item.value} key={item.value}>{item.label}</option>)}
                  </select>
                </div>
              </Col>}
              
              {/* Add new button */}
              {isAddButton &&
                <Col md={hasFilter ? 4 : 6} sm={4}>
                  <div className="text-sm-end text-end mb-2">
                    <Link to= {to} className= "btn btn-success btn-rounded btn-secondary"><i className="mdi mdi-plus me-1"></i>{buttonText || "Add New"}</Link>
                  </div>
                </Col>
              }
            </Row>
          </div>

          {/* Main content */}
          <div className="content-wrapper">
            {children}
          </div>

          {/* Bottom Pagination */}
          <div className="overflow-hidden">
            <Row>
              <Col md={4}>
                <span className="my-auto dataTables_info">
                  Showing {current_page !== last_page ? current_page * per_page : total} of {total} records
                </span>
              </Col>
              <Col md={8}>
                <div className="dataTables_paginate paging_simple_numbers pagination-rounded">
                  <Pagination
                    totalItemsCount={total}
                    activePage={current_page}
                    itemsCountPerPage={per_page}
                    pageRangeDisplayed={5}
                    prevPageText="<"
                    nextPageText=">"
                    firstPageText="<<"
                    lastPageText=">>"
                    onChange={(page) => { setPage(page); }}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </Col>
            </Row>
          </div>

        </CardBody>
      )}
    </>
  );
}

export default DataWrapper;
