import { loginSuccess, apiError, logoutUserSuccess, resetLoginFlag } from "./reducer";
import { toast } from "react-toastify";

export const loginuser = (user: any, history: any) => {
    return async (dispatch: any) => {
    try {
        const response = await fetch("https://api.stage.contentpro.app/api/login",{
              method: "POST", 
              body: JSON.stringify(user),
              headers: {
                'Content-Type' : 'application/json'
              }
            },
          );

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.errors || data.message || "Something Went wrong. Please try again later.");
        }

        localStorage.setItem("token", data.data.token);
        const current_user = data.data.user
        const userData = {
            name: current_user.name, 
            email: current_user.email,
            role: current_user.user_type, 
            business_id: current_user.business_id, 
            email_verification: current_user.email_verified_at,
            created_at: current_user.created_at, 
        }
        localStorage.setItem("authUser", JSON.stringify(userData));
        dispatch(loginSuccess(userData));
        // userData.role === "Admin" ? history('/admin'): history(`/dashboard?businessId=${userData.business_id}`);
        if(current_user.user_type === 'Business-Owner' && !data.data.subscription.access_status){
            history(`/payments?businessId=${current_user.business_id}`)
        }else{
            history('/')
        }

    } catch (error: any) {
        dispatch(apiError(error.message));
    }
}
}

export const logoutUser = (history: any) => async (dispatch: any) => {
    try {
        const token = localStorage.getItem('token')
        const response = await fetch("https://api.stage.contentpro.app/api/logout",{
            method: "POST", 
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
          },
      );

      const data = await response.json();

      if (!response.ok) {
          throw new Error(data.errors || data.message || "Something Went wrong. Please try again later.");
          
      }
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        dispatch(logoutUserSuccess(true));
        
    } catch (error: any) {
        // console.log(error.message)
        toast.error(error.message, { autoClose: 2000 });
        dispatch(apiError(error.message));
        // history('/');
        
    }
};

export const resetLoginMsgFlag = () => {
    try {
        const response = resetLoginFlag();
        return response;
    } catch (error) {
        return error;
    }
};

