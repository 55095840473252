import React, { useState } from "react";
import { Row, Col, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import ALT from "assets/images/product/Pro-12a.png";
import ALT1 from "assets/images/product/Pro-12b.png";
import ALT2 from "assets/images/product/Pro-17.png";
import ALT3 from "assets/images/product/Pro-4.png";


interface ImageData {
    id: number;
    src: string;
}

function ImageGallery() {
    const [images] = useState<ImageData[]>([
        { id: 1, src: ALT },
        { id: 2, src: ALT1 },
        { id: 3, src: ALT2 },
        { id: 4, src: ALT3 },
        { id: 5, src: ALT },
        { id: 6, src: ALT },
    ]);

    const [modalSelectedImages, setModalSelectedImages] = useState<ImageData[]>([]);
    const [displayedImages, setDisplayedImages] = useState<ImageData[]>([]);
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalSelectedImages(displayedImages); // Load currently displayed images into the modal when it opens
        setModalOpen(!modalOpen);
    };

    const handleSelectImage = (image: ImageData) => {
        setModalSelectedImages((prevSelectedImages) =>
            prevSelectedImages.some((img) => img.id === image.id)
                ? prevSelectedImages.filter((img) => img.id !== image.id)
                : [...prevSelectedImages, image]
        );
    };

    const handleSaveSelection = () => {
        setDisplayedImages(modalSelectedImages); // Save the modal selections to the displayed images
        toggleModal();
    };

    return (
        <Col>
            <div className="position-relative">
                <Button onClick={toggleModal} className="btn-soft-success">Select images</Button>
            </div>

            {/* Display selected images below the button */}
            <Row className="mt-3">
                {displayedImages.map((image) => (
                    <Col key={image.id} xs={2}className="mb-3">
                        <img
                            src={image.src}
                            alt={`selected-${image.id}`}
                            className="img-fluid"
                        />
                    </Col>
                ))}
            </Row>

            {/* Modal for selecting images */}
            <Modal isOpen={modalOpen} toggle={toggleModal} centered={true} backdrop={'static'}>
                <ModalHeader toggle={toggleModal}>Select Images</ModalHeader>
                <ModalBody className="px-4 py-5 text-center"> 
                    <Row className="flex-wrap">
                        {images.map((image) => (
                            <Col key={image.id} xs={6} sm={4} md={3} className="mb-3">
                                <div className="position-relative">
                                    <input
                                        type="checkbox"
                                        checked={modalSelectedImages.some((img) => img.id === image.id)}
                                        onChange={() => handleSelectImage(image)}
                                        className="position-absolute form-check-input"
                                        style={{
                                            top: "10px",
                                            left: "10px",
                                            zIndex: 1,
                                            accentColor: "#008957",
                                            border: "2px solid #004d3a"
                                        }}
                                    />
                                    <img
                                        src={image.src}
                                        alt={`image-${image.id}`}
                                        className="img-fluid"
                                        style={{
                                            cursor: "pointer",
                                            border: modalSelectedImages.some((img) => img.id === image.id)
                                                ? "2px solid #008957"
                                                : "none",
                                        }}
                                    />
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <div className="hstack gap-2 justify-content-end mb-0">
                        <Button color="success" onClick={handleSaveSelection}>
                            Save Selection
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </Col>
    );
}

export default ImageGallery;
