import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";

// Import Images
import error from "assets/images/error-img.png";

const AccessDenied = () => {
  document.title = "Access Denied | Content Pro";
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10); // Set initial countdown value here

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(timer);
      navigate('/');
    }

    return () => clearInterval(timer);
  }, [countdown, navigate]);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 fw-medium">
                  4<i className="bx bx-buoy bx-spin text-success display-3" />
                  3
                </h1>
                <h4 className="text-uppercase">Sorry, Access Denied</h4>
                <p className="mb-0 mt-3 text-muted">
                  Page you are trying to access has restricted access.
                </p>
                <p className="mt-0 text-muted">
                  Please refer to your system administrator
                </p>
                <div className="mt-5 text-center">
                  <p>Redirecting to home in {countdown} seconds...</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="Error" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AccessDenied;
