import { getTeamDetail } from "helpers/API Calls/Space Settings/Users Settings/Teams/Teams";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GoBackButton from "Components/Common/GoBackButton";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Container, Row, Spinner, Table } from "reactstrap";
import convertDateFormat from "Components/Custom/DateFormat";
import { LinkWithQuery } from "helpers/link_helper";

function TeamDetails() {
  document.title = "Team Details | Content Pro";

  const { id } = useParams();

  const [team, setTeam] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const data = await getTeamDetail(id);
      setTeam(data);
      setIsLoading(false);
    };
    getData();
  }, [id]);

  return (
    <div className="page-content">
      <ToastContainer />
      <Container fluid>
        <GoBackButton to="/teams"/>
        {isLoading ? (
          <Spinner color="primary" className="position-absolute top-50 start-50"/>
        ) : team ? (
          <>
           <Row>

            <Col xl={3}>

            <Row>
            
                <Col>
                    <Card>
                        <CardBody className="pb-0">
                        <div className="d-flex align-items-center">
                            <div className="avatar-xs">
                            <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                                <i className="mdi mdi-account-multiple-outline fs-4"></i>
                            </div>
                            </div>
                            <h5 className="ms-3 mb-0">{team.team_name}</h5>
                        </div>
                        <ul className="list-unstyled mt-4">
                            <li className="mt-3">
                                <div className="d-flex">
                                    <h6 className="fs-14 mb-2">Created At</h6>
                                    <p className="text-muted fs-14 mb-0 ms-auto">{convertDateFormat(team.created_at) || 'N/A'}</p>
                                </div>
                            </li>
                            <li className="mt-3">
                                <div className="d-flex">
                                    <h6 className="fs-14 mb-2">Updated At</h6>
                                    <p className="text-muted fs-14 mb-0 ms-auto">{convertDateFormat(team.updated_at) || 'N/A'}</p>
                                </div>
                            </li>
                        </ul>
                        </CardBody>
                    </Card>
                </Col>

                <Col>
                    <Card>
                      <CardBody>
                        <div className="mb-3">
                          <h5 className="fw-semibold">Assigned Projects</h5>
                        </div>
                        {team.projects.map(item => 
                          <div className="d-flex align-items-center mb-4">
                              <div className="avatar-xs">
                              <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded">
                                  {item.project_name?.charAt(0)}
                              </div>
                              </div>
                              <div className="ms-3 ">
                                <h5 className='font-size-14 mb-1'>
                                  <LinkWithQuery to="/project" queries={`projectId=${item.id}`} className='text-dark'>{item.project_name}, ({item.project_key})</LinkWithQuery>
                                </h5>
                                <p className="text-muted mb-0">Created At: {convertDateFormat(item.created_at)}</p>
                              </div>
                          </div>
                        )}
                      </CardBody>
                    </Card>
                </Col>

            </Row>

            </Col>

            <Col xl={9}>
            <Card>
                <CardBody className="table-responsive mt-2 display-grid overflow-auto ">
                <div className="mb-3">
                    <h5 className="fw-semibold">Members Details</h5>
                </div>
                <Table className="mb-5 project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline table table-hoverS">
                <thead className="table-light">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>User Type</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                    {team && team.members.map((item) =>
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.user_type}</td>
                            <td>{item.roles[0].name}</td>
                        </tr>
                    )}
                </tbody>
              </Table>
                </CardBody>
            </Card>   
            </Col>

            </Row>
          </>
        ) : (
          <Row>
            <div className="text-center text-danger">Sorry! No data found.</div>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default TeamDetails;
