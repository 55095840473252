import React from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import SocialIcon from "../../Activity/UI/SocialIcon";
// import CustomIcon from "Components/Custom/CustomIcon";

const socialPlatforms = [
  { id: 1, platform: 'linkedIn' },
  { id: 2, platform: 'facebook' },
  { id: 3, platform: 'instagram' },
  { id: 4, platform: 'twitter' },
  { id: 5, platform: 'website' },
];

const SocialMediaCards = () => {
  return (
      <Card className="p-3">
        <CardBody>
          <div className="align-items-center mb-4">
            <h3 className="">Connect a new account</h3>
            <p className="card-title-desc">Add more social accounts to increase your online performance.</p>
          </div>
          <Row>
            {socialPlatforms.map((item) => (
              <Col md={6} lg={3} key={item.id} className="my-3">
                <Card className="bg-light h-100">
                  <CardBody className="d-flex flex-column align-items-center pb-0">
                    <SocialIcon platform={item.platform} />
                    <Button className="btn btn-sm btn-soft-success mt-3" onClick={() => {}}><i className="mdi mdi-plus" /> Connect
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
  );
};

export default SocialMediaCards;
