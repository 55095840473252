// general Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// role navigation 
import RoleNavigation from "./RoleNavigation";

// Auth
import Login from "../pages/Authentication/login";
import Logout from "../pages/Authentication/Logout";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import SignUp from "../pages/Authentication/Register";

// Profile
import UserProfile from "../pages/Profile/user-profile";
import ChangePassword from "pages/Profile/ChangePassword";

// super admin 
import SuperAdminDashboard from 'pages/SuperAdmin/Dashboard'
import BusinessList from "pages/SuperAdmin/Businesses/Business-list";
import EditBusiness from "pages/SuperAdmin/Businesses/Business-edit";
import Plans from "pages/SuperAdmin/Billing/Plans";
import Plans2 from "pages/SuperAdmin/Billing/Plans2";
import Subscriptions from "pages/SuperAdmin/Billing/Subscriptions";
import General from "pages/SuperAdmin/Settings/General";

// landing page for business owner and all users 
import Dashboard from "pages/Dashboard/Dashboard";

// dashboard
import Home from "../pages/Project/Home/Home";

// activities
import PostEdit from "pages/Project/Activity/activity-edit";
import PostCreate from "pages/Project/Activity/activity-create";
import PostsList from "pages/Project/Activity/activity-list";
import PostsOverview from "pages/Project/Activity/activity-overview";
import PostPublish from "pages/Project/Activity/activity-publish";
import Logs from "pages/Project/Activity/activity-logs";
import Version from "pages/Project/Activity/activity-version";
import EditSocialPost from "pages/Project/Activity/activity-publish-edit";

// space settings 
import SpaceHelp from "pages/Space Settings/Help";

// Project Settings 
import Members from "pages/Project/Settings/project-members";
import Status from "pages/Project/Settings/project-statuses";
import GeneralSettings from "pages/Project/Settings/project-general";
import ActivityType from "pages/Project/Settings/project-activity-type";
import MemberStatus from "pages/Project/Settings/project-mem-status";
import Integrations from "pages/Project/Settings/project-integrations";
import Reports from "pages/Project/Reports/project-report";
import Help from "pages/Project/Help/project-help";
import Files from "pages/Project/Files/project-files";
import Settings from "pages/Space Settings/General/space-general";
import Time from "pages/Space Settings/General/space-timezone";
import Users from "pages/Space Settings/User Settings/Users/space-users";
import Roles from "pages/Space Settings/User Settings/Roles/space-roles";
import Teams from "pages/Space Settings/User Settings/Teams/space-teams";
import ProjectList from "pages/Space Settings/Project Settings/project-list";
import Create from "pages/Space Settings/Project Settings/project-create";
import Payments from "pages/Space Settings/Subscriptions/payments";
import CreateUser from "pages/Space Settings/User Settings/Users/space-users-create";
import EditUser from "pages/Space Settings/User Settings/Users/space-user-edit";
import CreateTeam from "pages/Space Settings/User Settings/Teams/space-team-create";
import EditTeam from "pages/Space Settings/User Settings/Teams/space-team-edit";
import RoleForm from "pages/Space Settings/User Settings/Roles/space-role-create";
import AddFiles from "pages/Project/Files/project-file-new";
import AccessDenied from "pages/Utility/pages-access-denied";
import VerifyEmail from "Components/VerifyEmail";
import BusinessDetails from "pages/SuperAdmin/Businesses/Business-details";
import StripePricingTable from "pages/Space Settings/Subscriptions/StripePricingTable";
import SubscriptionDetails from "pages/SuperAdmin/Billing/SubscriptionDetails";
import EditRoleForm from "pages/Space Settings/User Settings/Roles/space-role-edit";
import TeamDetails from "pages/Space Settings/User Settings/Teams/space-team-details";
import ProjectDetails from "pages/Space Settings/Project Settings/project-details";

const authProtectedRoutes = [
  // ------------ role navigation --------------- // 
  { path: "/", exact: true, component: <RoleNavigation/> },

  // ------------ Super Admin --------------- //
  { path: "/admin", component: <SuperAdminDashboard/> , sideBar: "admin"},
  // Super Admin Businesses
  { path: "/admin/businesses", component: <BusinessList/> , sideBar: "admin"},
  { path: "/admin/businesses/:businessId?", component: <BusinessDetails/> , sideBar: "admin"},
  { path: "/admin/businesses/:businessId?/edit", component: <EditBusiness/> , sideBar: "admin"},
  { path: "/admin/businesses/create", component: <SignUp/>},
  // settings
  { path: "/admin/settings", component: <General/> , sideBar: "admin"},
  // Billings
  { path: "/admin/plans", component: <Plans/> , sideBar: "admin"},
  { path: "/admin/plans2", component: <Plans2/> , sideBar: "admin"},
  { path: "/admin/subscriptions", component: <Subscriptions/> , sideBar: "admin"},
  { path: "/admin/subscriptions/:businessId?", component: <SubscriptionDetails/> , sideBar: "admin"},

  
  // --------- Dashboard ------------- //
  { path: "/dashboard", component: <Dashboard />, sideBar: "dashboard" },

  // --------- Project ------------- //
  { path: "/project", component: <Home />, sideBar: "project" },

  // Activities
  { path: "/posts-list", component: <PostsList />, sideBar: "project", },
  { path: "/posts-create", component: <PostCreate />, sideBar: "project", routePermissions: ["create_post"] },
  { path: "/posts-details/:id?", component: <PostsOverview />, sideBar: "project", routePermissions: ["edit_post"] },
  { path: "/posts-details/:id?/publish", component: <PostPublish />, sideBar: "project", routePermissions: ["publish_post_on_wordpress", "publish_post_on_facebook", "publish_post_on_instagram", "publish_post_on_linkedin", "publish_post_on_twitter"] },
  { path: "/posts-details/:id?/publish/:platform?", component: <EditSocialPost />, sideBar: "project", routePermissions: ["publish_post_on_wordpress", "publish_post_on_facebook", "publish_post_on_instagram", "publish_post_on_linkedin", "publish_post_on_twitter"] },
  { path: "/posts-details/:id?/edit", component: <PostEdit />, sideBar: "project", routePermissions: ["edit_post"] },
  { path: "/posts-details/:id?/logs", component: <Logs />, sideBar: "project", routePermissions: ["view_activity_logs"] },
  { path: "/posts-details/:id?/logs/:logId?", component: <Version />, sideBar: "project", routePermissions: ["view_version_logs"] },

  // Project settings
  { path: "/general", component: <GeneralSettings />, sideBar: "project", routePermissions: ["update_project_settings"] },
  { path: "/members", component: <Members />, sideBar: "project", routePermissions: ["edit_members", "delete_members"] },
  { path: "/members/:mem_id?", component: <MemberStatus />, sideBar: "project", routePermissions: ["edit_members"] },
  { path: "/activity-type", component: <ActivityType />, sideBar: "project", routePermissions: ["add_activity_type", "edit_activity_type", "delete_activity_type"] },
  { path: "/status", component: <Status />, sideBar: "project", routePermissions: ["add_activity_state", "edit_activity_state", "delete_activity_state"] },
  { path: "/integrations", component: <Integrations />, sideBar: "project", routePermissions: ["connect_apps"] },

  // Reports
  { path: "/reports", component: <Reports />, sideBar: "project", routePermissions: ["manage_reports"] },

  // Files
  { path: "/files", component: <Files />, sideBar: "project", routePermissions: ["upload_files", "download_files"] },
  { path: "/add-files", component: <AddFiles />, sideBar: "project", routePermissions: ["upload_files"] },

  // Help
  { path: "/help", component: <Help />, sideBar: "project" },



  // ---------- space settings  ----------- // 
  // general
  { path: "/space", component: <Settings/> , sideBar: "space-settings"},
  { path: "/time", component: <Time/> , sideBar: "space-settings"},
  // roles
  { path: "/roles", component: <Roles/> , sideBar: "space-settings"},
  { path: "/roles/create", component: <RoleForm/> , sideBar: "space-settings"},
  { path: "/roles/:id?", component: <EditRoleForm/> , sideBar: "space-settings"},
  // users
  { path: "/users", component: <Users/> , sideBar: "space-settings"},
  { path: "/users/create", component: <CreateUser/> , sideBar: "space-settings"},
  { path: "/users/:id?/edit", component: <EditUser/> , sideBar: "space-settings"},
  // { path: "/users/:id?", component: <EditUser/> , sideBar: "space-settings"},
  // teams
  { path: "/teams", component: <Teams/> , sideBar: "space-settings"},
  { path: "/teams/create", component: <CreateTeam/> , sideBar: "space-settings"},
  { path: "/teams/:id?", component: <TeamDetails/> , sideBar: "space-settings"},
  { path: "/teams/:id/edit?", component: <EditTeam/> , sideBar: "space-settings"},

  // projects
  { path: "/projects", component: <ProjectList/> , sideBar: "space-settings"},
  { path: "/projects/:id?", component: <ProjectDetails/> , sideBar: "space-settings"},
  { path: "/projects/create", component: <Create/> , sideBar: "space-settings"},
  // subscription
  { path: "/payments", component: <Payments/> , sideBar: "space-settings"},
  { path: "/pricing-tables", component: <StripePricingTable/> , sideBar: "space-settings"},
  // help
  { path: "/space-help", component: <SpaceHelp/> , sideBar: "space-settings"},

  // general pages 
  { path: "/profile", component: <UserProfile />},
  { path: "/change-password", component: <ChangePassword />},

];

const publicRoutes = [
  {path: '/verifyAccount', component : <VerifyEmail/>},
  
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <SignUp />}, 
  { path: "/access-denied", component: <AccessDenied /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/*", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
 
];

export { authProtectedRoutes, publicRoutes};
