import React, { useEffect, useState } from "react";
import withRouter from "Components/Common/withRouter";
import { useNavigate, useLocation } from "react-router-dom";
import { verifyAccount } from "helpers/API Calls/Dashboard/Email Verification";
import { Spinner } from "reactstrap";
import { ToastContainer } from "react-toastify";

const VerifyEmail = (props: any) => {
    const [message, setMessage] = useState('Verifying your email. Please Wait for a few seconds. You will be redirected automatically.');

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    useEffect(() => {
        async function sendRequest() {
            const data = await verifyAccount({ email: email, token: token });
            console.log(data);
            if (data) {
                setMessage(`Redirecting to login...`);
                setTimeout(() => navigate("/login"), 3000); // 3-second delay
            }
        }
        sendRequest();
    }, [token, email, navigate]);

    return (
        <div className="d-flex flex-column align-items-center justify-content-center vh-100">
            {message && (
                <div className="text-center">
                    <Spinner color="primary" />
                    <p className="mt-3">{message}</p>
                </div>
            )}
            <ToastContainer/>
        </div>
    );
}

export default withRouter(VerifyEmail);
