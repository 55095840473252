import React, { useState } from "react";
import { Card, CardBody, Col, Container, Modal, ModalBody, Row, Table } from "reactstrap";
import Breadcrumbs from "Components/Common/Breadcrumb";
import CustomIcon from "Components/Custom/CustomIcon";
import { LinkWithQuery } from "helpers/link_helper";

export const MEMBERS = [
    {
      id: 1,
      name: "David McHenry",
      email: "david@skote.com",
      role: "Author", 
      team: "Team 1"
    },
    {
      id: 2,
      name: "Frank Kirk",
      designation: "Frontend Developer",
      email: "frank@skote.com",
      role: "Content Wtriter", 
      team: "Team 2"
    },
    {
      id: 3,
      name: "Rafael Morales",
      designation: "Backend Developer",
      email: "Rafael@skote.com",
      role: "Proof Reader", 
      team: "Team 1"
    },
    {
      id: 4,
      name: "Mark Ellison",
      designation: "Full Stack Developer",
      email: "mark@skote.com",
      role: "Publisher", 
      team: "Team 3"
    },
    {
      id: 5,
      name: "Minnie Walter",
      designation: "Frontend Developer",
      email: "minnie@skote.com",
      role: "Manager", 
      team: "Team 1"
    },
    {
      id: 6,
      name: "Shirley Smith",
      designation: "UI/UX Designer",
      email: "shirley@skote.com",
      role: "Content Wtriter", 
      team: "Team 1"
    },
    {
      id: 7,
      name: "John Santiago",
      designation: "Full Stack Developer",
      email: "john@skote.com",
      role: "Graphic Designer", 
      team: "Team 2"
    },
    {
      id: 8,
      name: "Colin Melton",
      designation: "Backend Developer",
      email: "colin@skote.com",
      role: "Author", 
      team: "Team 2"
    },
  ]

function Members() {
  //meta title
  document.title = "Members | Content Pro";
  const [modal, setModal] = useState<boolean>(false); 
  const [member, setMember] = useState<any>(); 
  
  return (
   <>
   <Modal isOpen={modal} toggle={() => {setModal(false); setMember({});}} centered={true} backdrop={'static'}>
    <div className="modal-content">
      <ModalBody className="px-4 py-5">
        <button type="button" onClick={() => {setModal(false); setMember({});}} className="btn-close position-absolute end-0 top-0 m-3"></button>
        <div className="mb-4 text-center font-size-20 text-primary">Remove Member</div>
        <p className="text-muted font-size-16 mb-4 text-center">Are you sure you want to remove this member from project?</p>
        <div className="hstack gap-2 mb-0 justify-content-center">
          <button type="button" className="btn btn-secondary" onClick={() => {setModal(false); setMember({});}}>Close</button>
          <button type="submit" className="btn btn-danger" onClick={() => {console.log(member); setModal(false); setMember({});}}>Yes</button>
        </div>
      </ModalBody>
    </div>
   </Modal>
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Project" breadcrumbItem="Members List" />
        <Card className="p-3">
        <CardBody className="table-responsive mt-2 display-grid overflow-auto">            <Row>
              <Col lg={12}></Col>
              <Table className="mb-5 project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline table table-hoverS">
                <thead className="table-light">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Team</th>
                    <th>Role</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                    {MEMBERS && MEMBERS.map((item, index) =>
                        <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.team}</td>
                            <td>{item.role}</td>
                            <td>
                              <LinkWithQuery to={item.id} project={true} className="btn btn-sm btn-soft-info me-2">
                                <CustomIcon className="mdi " iconName= "mdi-pencil" id={`edit-${item.id}`} text="Edit"/>
                              </LinkWithQuery>
                              <button className="btn btn-sm btn-soft-danger" onClick={() => {setModal(true); setMember(item)}}>
                                <CustomIcon className="mdi " iconName= "mdi-close-thick" id={`remove-${item.id}`} text="Remove"/>
                              </button>
                            </td>
                        </tr>
                    )}
                </tbody>
              </Table>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
   </>
  );
}

export default Members;
