import { Card, CardBody, Container, Row, Col,Form, } from "reactstrap";
import Breadcrumbs from "Components/Common/Breadcrumb";
import React, { useState } from "react";
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom";

function AddFiles() {
  document.title = "Project Files | Content Pro";

  const [selectedFiles, setSelectedFiles] = useState<any>([])

  function handleAcceptedFiles(files:any) {
    files.map((file:any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setSelectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes:any, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Project" breadcrumbItem="Add Files" isGoBackButton = {true} project={true} to="/files" />
            <Row>
                <Col className="col-12">
                    <Card>
                        <CardBody>
                            <h6 className="card-title">Upload files</h6>
                            <Form onSubmit={e => {e.preventDefault(); console.log(selectedFiles); return}}>
                                <Dropzone onDrop={(acceptedFiles:any) => {handleAcceptedFiles(acceptedFiles)}}>
                                {({ getRootProps, getInputProps }:any) => (
                                    <div className="dropzone">
                                    <div className="dz-message needsclick mt-2" {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="mb-3"><i className="display-4 text-muted bx bxs-cloud-upload" /></div>
                                        <h4>Drop files here or click to upload.</h4>
                                    </div>
                                    </div>
                                )}
                                </Dropzone>
                                <div className="dropzone-previews mt-3" id="file-previews">
                                {selectedFiles.map((f:any, i:any) => {
                                    return (
                                    <Card key={i + "-file"} className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                        <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" alt={f.name} src={f.preview}/>
                                                </Col>
                                                <Col>
                                                    <Link to="#" className="text-muted font-weight-bold">{f.name}</Link>
                                                    <p className="mb-0"><strong>{f.formattedSize}</strong></p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                    )
                                })}
                                </div>

                                <div className="text-end mt-4">
                                    <button type="submit"className="btn btn-success ">Save Changes</button>
                                </div>
                            </Form>

                        </CardBody>
                    </Card>
                </Col>
          </Row>
      </Container>
    </div>
  );
}

export default AddFiles;
