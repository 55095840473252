import { toast } from "react-toastify";
import request from "../../request_helper";
import getFormData from "Components/Custom/Formdata";


export const getBusinesses = async (query: any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `businesses?limit=${query.limit}&page=${query.page}&keyword=${query.keyword}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        // toast.success(response.message, { autoClose: 2000 });
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const getBusinessDetail = async (Id: any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `businesses/${Id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        // toast.success(response.message, { autoClose: 2000 });
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const editBusiness = async (businessData, id) => {
    // console.log(businessData)
    const formData = getFormData(businessData);
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "businesses/" + id,
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            body : formData,
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return data; 
        // history();
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const deleteBusiness = async (businessId) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "businesses/" + businessId,
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        toast.success(response.message, { autoClose: 2000 });
        const data = await response;
        // console.log('delete data')
        console.log(data)
        // setTimeout(()=> {history();}, 1000)
        return true; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
        return false; 
    }
}


