import React from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import CustomIcon from "../../../../Components/Custom/CustomIcon";
import { useDropzone } from 'react-dropzone';
import placeholderImage from "assets/images/img.png";

function ImageHolder({ tag = "", selectedImage, onImageUpload, onDeleteImage }) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFile) => {
      let reader = new FileReader();
      let file = acceptedFile[0];
      reader.onloadend = () => {
        onImageUpload(reader.result as string);
      };
      reader.readAsDataURL(file);
    },
  });

  return (
    <Col>
      <Card className="p-0 mb-0" style={{ width: "auto"}}> 
        <CardBody className="p-0" style={{}}>
          <div className="text-center position-relative"> 
            <div {...getRootProps()} className="d-inline-block"> 
              <div className="avatar-xl rounded">
                <div className="avatar-title bg-light">
                  {selectedImage ? (
                    <img src={selectedImage} id={`${tag}-img`} alt="" className="avatar-xl rounded h-auto" />
                  ) : (
                    <img src={placeholderImage} id="logo-img" alt="" className="avatar-xl rounded h-auto" />
                  )}
                </div>
              </div>
              <input
                className="form-control d-none"
                id={`${tag}-image-input`}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                {...getInputProps()}
              />
            </div>
            {selectedImage && (
              <Button
                type="button"
                className="btn btn-danger btn-sm p-1 position-absolute top-0 end-0"
                onClick={onDeleteImage}
             >
                <CustomIcon className="mdi" iconName="mdi-trash-can" id={`deleteButton_${tag}`} text="" />
              </Button>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}

export default ImageHolder;
