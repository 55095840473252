import React, {useState } from "react";
import { Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import Breadcrumbs from "Components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import DeleteModal from "Components/Common/DeleteModal";
import CustomIcon from "Components/Custom/CustomIcon";
import { LinkWithQuery } from "helpers/link_helper";
import PaginatedCards from "Components/Custom/Table and Cards/PaginatedCards";
import { getSubscriptions } from "slices/thunk";

export const data = [
  { id: 1, role: "Author" },
  { id: 2, role: "Content Writer" },
  { id: 3, role: "Proof Reader" },
  { id: 4, role: "Publisher" },
  { id: 5, role: "Manager" },
  { id: 6, role: "Content Writer" },
  { id: 7, role: "Graphic Designer" },
  { id: 8, role: "Author" },
];

const pricings = [
  {
    id: 1,
    title: "Starter",
    description: "Neque quis est",
    icon: "bx-walk",
    price: "19",
    duration: "Per month",
    link: "",
    features: [
      { title: "Free Live Support" },
      { title: "Unlimited User" },
      { title: "No Time Tracking" },
      { title: "Free Setup" },
    ],
  },
  {
    id: 2,
    title: "Professional",
    description: "Quis autem iure",
    icon: "bx-run",
    price: "29",
    duration: "Per month",
    link: "",
    features: [
      { title: "Free Live Support" },
      { title: "Unlimited User" },
      { title: "No Time Tracking" },
      { title: "Free Setup" },
    ],
  },
  {
    id: 3,
    title: "Enterprise",
    description: "Sed ut neque unde",
    icon: "bx-cycling",
    price: "39",
    duration: "Per month",
    link: "",
    features: [
      { title: "Free Live Support" },
      { title: "Unlimited User" },
      { title: "No Time Tracking" },
      { title: "Free Setup" },
    ],
  },
  {
    id: 4,
    title: "Unlimited",
    description: "Itaque earum hic",
    icon: "bx-car",
    price: "49",
    duration: "Per month",
    link: "",
    features: [
      { title: "Free Live Support" },
      { title: "Unlimited User" },
      { title: "No Time Tracking" },
      { title: "Free Setup" },
    ],
  },
]

function Plans() {
  document.title = "Pricing | Content Pro";
  // document.title = "Roles | Content Pro";

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [role, setRole] = useState<any>(null);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Billing" breadcrumbItem="Pricing Plans" />

          <Row className="mt-4">
            {pricings.map((pricing, key) => (
              <CardPricing pricing={pricing} key={"_pricing_" + key} />
            ))}
          </Row>
        </Container>
      </div>
      <>
    <DeleteModal
      show={deleteModal}
      onDeleteClick={() => {console.log(role); setRole(null); setDeleteModal(false)}}
      onCloseClick={() => {setRole(null); setDeleteModal(false)}}
    />

    <div className="page-content">
      <Container fluid>
        <Card>
          <PaginatedCards 
          onGetData = {getSubscriptions}
          pagination = {{"current_page":1,"last_page":2,"per_page":10,"total":11}}
          isLoading = {false}
          isAddButton = {true} 
          buttonText="Add New Role"
          title = 'Roles'
          >
             <Row>
              {data.map(item => 
                <Col lg={4} md={6} key={item.id}>
                  <div className="carousel-item active">
                    <div className="bg-light p-3 d-flex justify-content-between mb-3 rounded">
                      <h5 className="font-size-15 my-auto mb-2">{item.role}</h5>
                        <div className="my-auto text-end ">
                          <UncontrolledDropdown>
                            <DropdownToggle className="btn btn-soft-success" type="button" id="dropdownMenuButton11">
                              <CustomIcon className="bx " iconName="bx-dots-vertical-rounded" id={`action-${item.id}`} text="actions" />
                            </DropdownToggle>
                            <DropdownMenu aria-labelledby="dropdownMenuButton11" className="dropdown-menu-end">
                              <LinkWithQuery to={item.id}><li><DropdownItem href="#">Edit</DropdownItem></li></LinkWithQuery>
                              <li onClick={() => {setRole(item); setDeleteModal(true);}}><DropdownItem href="#">Delete</DropdownItem></li>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </div>
                  </div>
                </Col>
              )}
            </Row>
          </PaginatedCards>
          
        </Card>
      </Container>
    </div>
    </>
    </React.Fragment>
  )
}


const CardPricing = (props:any) => {
  return (
    <React.Fragment>
      <Col xl="3" md="6">
        <Card className="plan-box">
          <CardBody className="p-4">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h5>{props.pricing.title}</h5>
                <p className="text-muted">{props.pricing.description}</p>
              </div>
              <div className="flex-shrink-0 ms-3">
                <i
                  className={"bx " + props.pricing.icon + " h1 text-success"} style={{lineHeight:1}}
                />
              </div>
            </div>
            <div className="py-4">
              <h2>
                <sup>
                  <small>$</small>
                </sup>{" "}
                {props.pricing.price}/{" "}
                <span className="font-size-13">{props.pricing.duration}</span>
              </h2>
            </div>

            <div className="text-center plan-btn">
              <Link to={props.pricing.link} className="btn btn-success btn-sm waves-effect waves-light ">
                Features
              </Link>
            </div>

            <div className="plan-features mt-5">
              {props.pricing.features.map((feature:any, key:any) => (
                <p key={"_feature_" + key}>
                  <i className="bx bx-checkbox-square text-success me-2" />{feature.title}
                </p>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}
export default Plans