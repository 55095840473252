import React, { useState } from 'react'
import { Button, Col, Form, FormGroup, Label } from 'reactstrap';
import Select from "react-select"
import makeAnimated from 'react-select/animated';

const optionGroup = [
    {
      label: "Category 1",
      options: [
        { label: "Landscape", value: "Landscape" },
        { label: "Food", value: "Food" },
        { label: "Business", value: "Business" },
      ],
    },
    {
      label: "Category 2",
      options: [
        { label: "Entertainment", value: "Entertainment" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Paper", value: "Toilet Paper" },
      ],
    },
  ]

const animatedComponents = makeAnimated();


function Category() {
  const initialValue = [{ label: "Landscape", value: "Landscape" }]; 
  const [selectedCategory, setSelectedCategory] = useState(initialValue);
  const [isOptionSelected, setIsOptionSelected] = useState(false); 

  return (
    <Col md={6} sm={12}>
        <Form className="">
        <FormGroup className="select2-container mb-4" row style={{ zIndex: 1000, position: 'relative' }}>
            <Label md={2} className="col-form-label">
            Category
            </Label>
            <Col md={7} xs={8}>
            <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                value={selectedCategory}
                onChange={(selected: any) => {setSelectedCategory(selected); setIsOptionSelected(true); }}
                isMulti
                isClearable={true}
                isSearchable={true}
                name="colors"
                options={optionGroup}
                className="basic-multi-select"
                classNamePrefix="select"
            />
            </Col>
            <Col sm={3}  xs={4} className='d-flex'>
            <Button type="button" className="btn btn-soft-success waves-effect waves-light me-1" 
              onClick={() => {console.log(selectedCategory);  setIsOptionSelected(false); }}
              disabled={!isOptionSelected}
            >
            <i className="bx bx-check-circle"/>
            </Button>
            <Button type="button" className="btn btn-soft-danger waves-effect waves-light" 
              onClick={() => {setSelectedCategory(initialValue);  setIsOptionSelected(false); }}
              disabled={!isOptionSelected}
            >
            <i className="mdi mdi-close-thick"/>
            </Button>
            </Col>
        </FormGroup>
        </Form>
    </Col>
  )
}

export default Category