import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap";
import {Collapse,} from "reactstrap";
import classnames from "classnames";


import avatar4 from "assets/images/users/avatar-5.jpg"
import avatar5 from "assets/images/users/avatar-4.jpg"

export interface FeedDataType {
  type: string,
  name?: string,
  action?: string,
  jobTitle?: string,
  timestamp: string,
  img?: string,
  accountType?: string,
  message?: string,
  actionLink?: string
}

const activityFeedData: FeedDataType[] = [
  {
      type: "activity",
      name: "Charles Brown",
      action: "applied for the job",
      timestamp: "3 min ago",
      img: avatar5,
  },
  {
      type: "message",
      name: "Charles Brown",
      action: "Your subscription expires today",
      actionLink: "#",
      timestamp: "53 min ago",
  },
  {
      type: "activity",
      name: "Jennifer Alexandar",
      action: "created a new account as a",
      timestamp: "1 hrs ago"
  },
  {
      type: "message",
      name: "Acolin Zelton",
      action: "There are many variations of passages of Lorem Ipsum available.",
      timestamp: "1 hrs ago",
      img: avatar4,
  },
  {
      type: "message",
      name: "Mark Ellison",
      action: "applied for the job",
      timestamp: "3 hrs ago",
      img: avatar4,
  },
  {
      type: "activity",
      name: "Acolin Zelton",
      action: "created a new account as a",
      timestamp: "1 hrs ago"
  },
  {
      type: "activity",
      name: "Acolin Zelton",
      action: "There are many variations of passages of Lorem Ipsum available.",
      timestamp: "1 hrs ago"
  }
]

const ActivityFeed: React.FC = () => {
  const [text, setText] = useState<any>("");
  const [collapse, setCollapse] = useState<boolean>(true)

  return (
    <React.Fragment>
        <Card>
        <div className="accordion">
          <button
            className={classnames("accordion-button rounded-top", "fw-medium", {collapsed: !collapse})} type="button" onClick={() => {setCollapse(prev => !prev)}} style={{ cursor: "pointer" }}>
            <h5 className="fw-bold font-size-21 pb-1">Activity:</h5>
          </button>
        </div>
          {/* <h5 className="fw-bold font-size-21 pb-1">Activity:</h5> */}
          <Collapse isOpen={collapse} className="accordion-collapse">
          <CardBody className="pb-3 bg-light rounded">
              <div className="pb-3 overflow-hidden rounded rounded-3 col-lg-9">
                <Row>
                  <Col>
                      <Input type="text" className=" p-2 form-control rounded" placeholder="Enter Message..." value={text} onChange={e => setText(e.target.value)} />
                  </Col>
                  <div className="col-auto">
                    <Button type="submit" color="success" className="chat-send w-md " onClick={() => {console.log(text)}}>
                      <span className="d-none d-sm-inline-block me-2">Add</span>
                      <i className="mdi mdi-send" />
                    </Button>
                  </div>
                </Row>
              </div>
              <ul className="activity-timeline list-unstyled">
                {(activityFeedData || [])?.map(
                  (event: FeedDataType, index: number) => (
                    <li key={index} className="event-list">
                      <div className="d-flex">
                        {/* img */}
                        <div className="flex-shrink-0 me-3">
                          {event.img ? 
                           <img
                              src={event.img}
                              alt=""
                              className="avatar-xs rounded-circle"
                            />
                          :
                            <div className="avatar-xs">
                              <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                                {event.name?.charAt(0)}
                              </div>
                            </div>
                          }
                        </div>
                        {/* data or message */}
                        <div className="flex-grow-1">
                          <div>
                            {event.type === "activity" && (
                              <>
                                <b>{event.name}</b> {event.action}
                                <b>{event.jobTitle}</b>
                                <p className="mb-0 text-muted">{event.timestamp}</p>
                              </>
                            )}
                            {event.type === "message" && (
                              <>
                                <b className="me-1">{event.name}</b> {event.timestamp}
                                <CardBody className="bg-white py-3 px-2 rounded rounded-3 col-lg-9">
                                  {event.action}
                                </CardBody>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
          </CardBody>
          </Collapse>
        </Card>
    </React.Fragment>
  );
};

export default ActivityFeed;
