import React, { useRef, useState } from 'react'
import { Card, Container, CardBody, Col, Form, FormGroup, Label, Row, Input, Button, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, Modal, ModalBody, } from "reactstrap";
import Breadcrumbs from "Components/Common/Breadcrumb";
import { useParams } from 'react-router-dom';
import Header from './UI/activity-header';
import SocialIcon from './UI/SocialIcon';
import CustomEditor from 'Components/Custom/Editor';
import DATA from "./data/publish"; 
import Select from "react-select"
import makeAnimated from 'react-select/animated';
// import ALT from "assets/images/Placeholder.png"
import ImageSelector from './Publish/image-selector';
const animatedComponents = makeAnimated();

const authorsOptions = [{ value: 'Daniel Candles', label: 'Daniel Candles' },{ value: 'auth2', label: 'Author 2' },]
const categoryOptions = [{ value: 'Entertainment', label: 'Entertainment' },{ value: 'Blog', label: 'Blog' },{ value: 'Health', label: 'Health' },{ value: 'Food', label: 'Food' },]
const statusOptions = [{ value: 'Draft', label: 'Draft' },{ value: 'Published', label: 'Published' },]

function EditSocialPost() {
    document.title = "Edit Social Post | Content Pro";

    const {id, platform} = useParams(); 
    const data = DATA.find(item => item.platform === platform)

    // dropdown stats 
    const [publishButton, setPublishButton] = useState<boolean>(false); 
    // modal states
    const [action, setAction] = useState<string>(""); 
    const [modal, setModal] = useState<boolean>(false);

    // form states 
    const [formData, setFormData] = useState<any>({
      author: authorsOptions.find(item => item.value === data?.author),
      status: statusOptions.find(item => item.value === data?.websiteStatus),
      category: categoryOptions.find(item => item.value === data?.category),
      webLink: data?.webLink || '',
      tags: data?.tags || '',
    });

    const handleFormSubmit = () => {
      console.log("Form Data:", formData);
    }


    // DATES HANDLING IN MODAL FOR SCHEDULING
    const dateInputRef = useRef<HTMLInputElement>(null);
    const handleSchedule = (event) => {
      event.preventDefault();
      // Check if inputRef.current is not null before accessing its value
      if (dateInputRef.current) {
        const selectedDateTime = dateInputRef.current.value;
        console.log("Selected Date and Time:", selectedDateTime);
      } else {
        console.error("Input ref is null");
      }
      setModal(false);
    };

  return (
    <>
      <Modal isOpen={modal} toggle={() => setModal(false)} centered={true}>
        <div className="modal-content"></div>
        <ModalBody className="px-4 py-5">
          <button type="button" onClick={() => setModal(false)} className="btn-close position-absolute end-0 top-0 m-3"></button>
          <div className="mb-4 text-center">
            <div className="text-primary font-size-20">
              {action === "Publish" && "Post Request"}
              {action === "Schedule" && "Schedule Post"}
              {action === "Draft" && "Save as Draft"}
              {action === "Ready" && "Mark as Ready"}
            </div>
          </div>
            {action === "Publish" && <p className="text-center text-muted font-size-16 mb-4">Are you sure you want to publish the post?</p>}
            {action === "Draft" && <p className="text-center text-muted font-size-16 mb-4">Are you sure you want to save the post as draft?</p>}
            {action === "Ready" && <p className="text-center text-muted font-size-16 mb-4">Are you sure you want to mark the post as ready to publish?</p>}
            {action === "Schedule" ?
              <Form onSubmit={handleSchedule}>
                <FormGroup className="select2-container ">
                  <Label className="col-form-label">Pick time</Label>
                  <Input type="datetime-local" id="publish-time" name="publish-time" innerRef={dateInputRef}/>
                </FormGroup>
                <div className="hstack gap-2 mb-0 justify-content-end">
                  <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
                  <button type="submit" className="btn btn-success" onClick={() => {handleFormSubmit(); setModal(false);}}>Schedule</button>
                </div>
              </Form>
              : 
              <div className="hstack gap-2 mb-0 justify-content-center">
                <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
                <button type="submit" className="btn btn-danger" onClick={() => {handleFormSubmit(); setModal(false);}}>Yes</button>
              </div>
            }
        </ModalBody>
      </Modal>
      <div className="page-content">
       <Container fluid>
         <Breadcrumbs title="Activity" breadcrumbItem="Edit Post" isGoBackButton={true} project={true} to={`/posts-details/${id}/publish`}/>
         <Card>
            <CardBody>
              <Header author="Charles Brown" title="ChatGPT as Visa Application Assistant for Any Country in the World" date="08 Sept, 2019" status="Idea in Motion"/>
              <Row>
                <Col className='mt-4'>
                  <div className="d-flex my-auto">
                    <SocialIcon platform={data?.platform}/>
                      {data?.connectionStatus === "connected" && <div className='ms-2 my-auto'>Connected to: <b className="btn-link  text-success">{data?.connectedAccount}</b> </div>}
                  </div>
                </Col>
                {/* <Col sm={4} className="mt-4 pt-2" >
                  <div className="text-md-center text-sm-start">
                    Connection Status: <b className={`text-${data?.connectionStatus === "connected" ? "success" : "danger"}`}>{data?.connectionStatus === "connected" ? "Connected" : "Not Connected"}</b>
                  </div>
                </Col>
                {data?.connectionStatus === "connected" && <Col sm={4} className="mt-4 pt-2">
                  <div className="text-md-center text-sm-start">
                    Account: <b className="btn-link  text-success">{data?.connectedAccount}</b>
                  </div>
                </Col>} */}
              </Row>
              <Form className='overflow-hidden' onSubmit = {handleFormSubmit}>
                <FormGroup className="select2-container mt-4">

                  <CustomEditor description={data?.details}/>

                  {/* tags */}
                  {data?.platform !== "website" ? 
                    <Row className='mt-3'>
                      <Col md={7} className='mt-3'>
                        <Row> 
                          <Col md={2}><Label className="col-form-label">Website URL</Label></Col>
                          <Col md={6} >
                            <Input
                              id="webLink"
                              name="webLink"
                              type="url"
                              placeholder="Enter Web Link..."
                              value={formData.webLink}
                              onChange={(event) => setFormData((prev : typeof formData) => {return {...prev, webLink: event.target.value}})}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col md={5} className='mt-3'>
                        <Row> 
                          <Col md={3}><Label className="col-form-label">Tags</Label></Col>
                          <Col md={9}>
                            <Input
                              id="tags"
                              name="tags"
                              type="text"
                              placeholder="Enter Tags..."
                              value={formData.tags}
                              onChange={(event) => setFormData((prev : typeof formData) => {return {...prev, tags: event.target.value}})}
                            />
                          </Col>
                        </Row>
                      </Col>
                      
                    </Row>
                    :
                    <Row>
                      <Col md={7} className='mt-3'>
                        <Row> 
                          <Col md={2}><Label className="col-form-label">Author</Label></Col>
                          <Col md={6} >
                            <Select
                              components={animatedComponents}
                              value={formData.author}
                              onChange={(selectedOption) => setFormData((prev : typeof formData) => {return {...prev, author: selectedOption}})}
                              isClearable={true}
                              isSearchable={true}
                              name="author"
                              options={authorsOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                            </Col>
                        </Row>
                      </Col>
                      <Col md={5} className='mt-3'>
                        <Row> 
                          <Col md={3}><Label className="col-form-label">Status</Label></Col>
                          <Col md={9}>
                            <Select
                              components={animatedComponents}
                              value={formData.status}
                              onChange={(selectedOption) => setFormData((prev : typeof formData) => {return {...prev, status: selectedOption}})}
                              isClearable={true}
                              isSearchable={true}
                              name="websiteStatus"
                              options={statusOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                          </Col>
                          
                        </Row>
                      </Col>
                      <Col md={7} className='mt-3'>
                        <Row> 
                          <Col md={2}><Label className="col-form-label">Category</Label></Col>
                          <Col md={6} >
                            <Select
                              components={animatedComponents}
                              value={formData.category}
                              onChange={(selectedOption) => setFormData((prev : typeof formData) => {return {...prev, category: selectedOption}})}
                              isClearable={true}
                              isSearchable={true}
                              name="category"
                              options={categoryOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  }
                  {/* images */}
                  {/* <Row className='mt-3'>
                    <Col md={7} className='mt-3'>
                      <Row> 
                        <Col md={2}><Label htmlFor="images" className="col-form-label">Image</Label></Col>
                        <Col xl={6} sm={6}>
                          <div className="position-relative">
                            <img style={{ height: "auto" }} src={data?.media || ALT} alt="" className="img-thumbnail img-fluid mx-auto d-block" />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row> */}
                  <ImageSelector/>
                  {/* buttons */}
                  <Row className='mt-3'>
                  <div className="d-flex flex-wrap gap-2 justify-content-end mx-auto">
                    {/* ready button */}
                    {data?.readyStatus !== "ready" && <Button onClick={() => {setModal(true); setAction("Ready")}} type="button" color="success" >Ready for Publish<i className="mdi mdi-sticker-check-outline font-size-16 align-middle ms-2"/></Button>}
                    {/* draft button */}
                    <Button onClick={() => {setModal(true); setAction("Draft")}} type="button" color="success" >Save<i className="mdi mdi-content-save-outline font-size-16 align-middle ms-2"/></Button>
                    {/* Publish drop down */}
                    <Dropdown
                      isOpen={publishButton}
                      toggle={() => setPublishButton(prev => !prev)}
                    >
                      <DropdownToggle tag="button" type="button"  className="btn btn-success ">
                        Publish <i className="mdi mdi-chevron-down font-size-16 align-middle" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => {setModal(true); setAction("Publish")}}><i className="mdi mdi-upload font-size-16 align-middle me-2"></i>Publish Now</DropdownItem>
                        <DropdownItem onClick={() => {setModal(true); setAction("Schedule")}}><i className="mdi mdi-calendar-clock font-size-16 align-middle me-2"></i>Schedule For Later</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>

                  </div>
                  </Row>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default EditSocialPost