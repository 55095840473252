import React from 'react'
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import DATA from "../../Activity/data/publish";
import SocialIcon from './SocialIcon';

function ConnectedAccounts() {
  return (
    <Card className="p-3">
      <CardBody >
        <div className="align-items-center mb-4">
          <h3 className="">Connected social accounts</h3>
          <p className="card-title-desc">View and manage your social accounts in one place.</p>
        </div>
        <Row>
          {(DATA || []).map((item) => (
            <Col md={6} lg={4} key={item.id} className="my-3">
              <Card className="bg-light h-100">
                <CardBody className="d-flex flex-column pb-0">
                  <div className="d-flex flex-wrap align-items-start">
                    <SocialIcon platform={item.platform} title={item.connectedAccount} type={item.accountType}/>
                    <div className="ms-auto">
                      <Button className="btn btn-soft-danger btn-sm" onClick={() => {}}>Disconnect</Button>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap border-bottom mt-3">
                      <div className="my-auto">
                        <span><i className="bx bx-message-square-dots me-1"></i>{item.characterCount} posts</span>
                      </div>
                  </div>

                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  )
}

export default ConnectedAccounts