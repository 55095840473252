import React, { useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Label, Row, Table } from "reactstrap";
import Breadcrumbs from "Components/Common/Breadcrumb";
import { STATUSES } from "./project-statuses";
import { MEMBERS } from "./project-members";
import { useParams } from "react-router-dom";
import IMG from "assets/images/users/avatar-2.jpg";

function MemberStatus() {
  const { mem_id } = useParams();
  const member = MEMBERS.find((item) => item.id === Number(mem_id));

  const [actionStatus, setActionStatus] = useState({});
  const [listingStatus, setListingStatus] = useState({});

  const handleActionCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setActionStatus((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const handleListingCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setListingStatus((prev) => {
      const newStatus = { ...prev };
      if (checked) {
        newStatus[id] = {
          ...newStatus[id],
          checked,
          radio: newStatus[id]?.radio || null,
        };
      } else {
        delete newStatus[id];
      }
      return newStatus;
    });
  };

  const handleListingRadioChange = (event) => {
    const { name, value } = event.target;
    setListingStatus((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        radio: value,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Action Status:", actionStatus);
    console.log("Listing Status:", listingStatus);
  };
  // console.log("Action Status:", actionStatus);
  //   console.log("Listing Status:", listingStatus);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Project"
          breadcrumbItem="Members Status"
          isGoBackButton={true}
          project={true}
          to={`/members`}
        />
        <Card className="p-3">
          <CardBody className="border-bottom">
            <div className="d-flex">
              <img src={IMG} alt="" height="50" />
              <div className="flex-grow-1 ms-3">
                <h5 className="fw-semibold">{member?.name}</h5>
                <ul className="list-unstyled hstack gap-2 mb-0">
                  <li>
                    <i className="mdi mdi-account-cog-outline"></i>{" "}
                    <span className="text-muted">{member?.role}</span>
                  </li>
                  <li>
                    <i className="mdi mdi-account-supervisor-outline"></i>{" "}
                    <span className="text-muted">{member?.team}</span>
                  </li>
                </ul>
              </div>
            </div>
          </CardBody>

          <CardBody>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg={12} className="my-4">
                  <h2 className="card-title">Action Status</h2>
                  <div className="control-group my-4">
                    {STATUSES.map((item) => (
                      <div className="form-check" key={item.id}>
                        <input
                          type="checkbox"
                          className="form-check-input me-3"
                          id={`action-${item.id}`}
                          onChange={handleActionCheckboxChange}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor={`action-${item.id}`}
                        >
                          {item.title}
                        </Label>
                      </div>
                    ))}
                  </div>
                </Col>

                <Col lg={12}>
                  <h2 className="card-title mb-0">Listing Status</h2>
                  <div className="control-group mb-4">
                    <div className="table-responsive mt-2 display-grid overflow-auto">
                      <Table className="mb-2 project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline table table-hover">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Completed</th>
                            <th>Pending</th>
                            <th>Archived</th>
                          </tr>
                        </thead>
                        <tbody>
                          {STATUSES.map((item) => (
                            <tr key={item.id}>
                              <td className="p-0">
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input me-3"
                                    id={`listing-${item.id}`}
                                    onChange={handleListingCheckboxChange}
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor={`listing-${item.id}`}
                                  >
                                    {item.title}
                                  </Label>
                                </div>
                              </td>
                              <td className="p-0 ps-4">
                                <div className="form-check form-radio-success text-center">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`listing-${item.id}`}
                                    value="completed"
                                    onChange={handleListingRadioChange}
                                    disabled={!listingStatus[`listing-${item.id}`]?.checked}
                                  />
                                </div>
                              </td>
                              <td className="p-0 ps-4">
                                <div className="form-check form-radio-info">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`listing-${item.id}`}
                                    value="pending"
                                    onChange={handleListingRadioChange}
                                    disabled={!listingStatus[`listing-${item.id}`]?.checked}
                                  />
                                </div>
                              </td>
                              <td className="p-0 ps-4">
                                <div className="form-check form-radio-danger">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`listing-${item.id}`}
                                    value="archived"
                                    onChange={handleListingRadioChange}
                                    disabled={!listingStatus[`listing-${item.id}`]?.checked}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="d-flex flex-wrap gap-2 justify-content-end mx-auto px-1 mt-1">
                <Button type="submit" color="success">
                  Save
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default MemberStatus;
