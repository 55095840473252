import request from "helpers/request_helper";
import { getUserRequest, getUserSuccess, getUserFailure, resetGetUserState } from "./reducer";
// import { toast } from "react-toastify";
 
export const getUser = () => {
    return async (dispatch: any) => {
        dispatch(getUserRequest());
        try {
            const token = localStorage.getItem('token');
            const args = {
                url: `user`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            };
            const response = await request(args); 
            // toast.success(response.message, { autoClose: 2000 });
            const data = await response;
            // console.log(data) ;
                       
            dispatch(getUserSuccess(data));
        }
        catch (error: any) {
            dispatch(getUserFailure(error.message));
            // toast.error(error.message);
        }
    };
};

export const resetUserFlag = () => {
    return (dispatch: any) => {
        dispatch(resetGetUserState());
    };
};
