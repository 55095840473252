import { Col, Input, Label, Row, FormFeedback } from "reactstrap";

const InputElement = ({
  name,
  type,
  identifier,
  value,
  onChange,
  errors,
  touched,
  onBlur,
  disabled = false
}) => {
  return (
    <Row>
      <Col lg={7} md={10} className="mx-auto">
        <div className="mb-3">
          <Label htmlFor={identifier}>{name}</Label>
          <Input
            id={identifier}
            name={identifier}
            type={type}
            placeholder={`Enter ${name}`}
            value={value || ""}
            onChange={onChange}
            invalid={touched && errors ? true : false}
            disabled={disabled}
            onBlur={onBlur}
          />
          {errors && touched ? (
            <FormFeedback type="invalid">{errors}</FormFeedback>
          ) : null}
        </div>
      </Col>
    </Row>
  );
};
export default InputElement; 