import React, { useState } from "react";
import { Nav, NavItem, NavLink, Offcanvas, OffcanvasBody, Row } from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
//SimpleBar
import SimpleBar from "simplebar-react";
import "./rightbar.scss";
// images 
import avatar1 from "assets/images/users/avatar-1.jpg";
import avatar2 from "assets/images/users/avatar-2.jpg";
import avatar3 from "assets/images/users/avatar-3.jpg";
import avatar4 from "assets/images/users/avatar-4.jpg";
import avatar5 from "assets/images/users/avatar-5.jpg";
import avatar6 from "assets/images/users/avatar-6.jpg";

interface sidebar {
  show: boolean;
  toggleCanvas: any;
}

const NOTIFICATIONS = [
  {
    id: 1,
    name: "Your were assigned a task",
    message: "If several languages coalesce the grammar",
    time: "3 min ago",
    image: avatar1,
  },
  {
    id: 2,
    name: "James Lemire",
    message: "It will seem like simplified English",
    time: "10 min ago",
    image: avatar2,
  },
  {
    id: 3,
    name: "Your got a payment",
    message: "If several languages coalesce the grammar",
    time: "3 min ago",
    image: avatar3,
  },
  {
    id: 4,
    name: "Salena Layfield",
    message: "As a skeptical Cambridge friend of mine occidental",
    time: "1 hours ago",
    image: avatar4,
  },
  {
    id: 5,
    name: "Your were assigned a task",
    message: "If several languages coalesce the grammar",
    time: "3 min ago",
    image: avatar5,
  },
  {
    id: 6,
    name: "Salena Layfield",
    message: "As a skeptical Cambridge friend of mine occidental",
    time: "1 hours ago",
    image: avatar6,
  },
  {
    id: 7,
    name: "James Lemire",
    message: "It will seem like simplified English",
    time: "1 hours ago",
    image: avatar1,
  },
  {
    id: 8,
    name: "Your were assigned a task",
    message: "If several languages coalesce the grammar",
    time: "3 min ago",
    image: avatar1,
  },
  {
    id: 9,
    name: "James Lemire",
    message: "It will seem like simplified English",
    time: "10 min ago",
    image: avatar2,
  },
  {
    id: 10,
    name: "Your were assigned a task",
    message: "If several languages coalesce the grammar",
    time: "3 min ago",
    image: avatar1,
  },
  {
    id: 11,
    name: "James Lemire",
    message: "It will seem like simplified English",
    time: "10 min ago",
    image: avatar2,
  },
];

const RightSidebar = ({ toggleCanvas, show }: sidebar, ...props: any) => {

  const html = document.getElementsByTagName("html")[0];

  const [activeTab, setActiveTab] = useState("All");
  const toggle = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <Offcanvas isOpen={show} toggle={toggleCanvas} direction={html.hasAttribute("dir") ? "start" : "end"} className="right-bar border-0">
        <OffcanvasBody className="p-0">
          <SimpleBar style={{ height: "900px" }}>
            <div className="rightbar-title px-3 py-4">
              <Link className="right-bar-toggle float-end" to="#" onClick={toggleCanvas}>
                <i className="mdi mdi-close noti-icon"></i>
              </Link>
              <h5 className="m-0">Notifications</h5>
            </div>
            <hr className="my-0" />
            <Row>
              <Nav tabs className="nav-tabs-custom mx-3">
                <NavItem className="nav-item">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: activeTab === "All" })}
                    onClick={() => toggle("All")}
                  >
                    <span>All</span>
                  </NavLink>
                </NavItem>
                <NavItem className="nav-item">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: activeTab === "Activities" })}
                    onClick={() => toggle("Activities")}
                  >
                    <span>Activities</span>
                  </NavLink>
                </NavItem>
                <NavItem className="nav-item">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: activeTab === "Assignments" })}
                    onClick={() => toggle("Assignments")}
                  >
                    <span>Assignments</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Row>
            <div className="p-4">
              {NOTIFICATIONS.map(item => (
                <div key={item.id}>
                  <Link to="#" className="text-reset notification-item">
                    <div className="d-flex">
                      <img src={item.image} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                      <div className="flex-grow-1">
                        <h6 className="mt-0 mb-1">{item.name}</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{item.message}</p>
                          <p className="mb-0"><i className="mdi mdi-clock-outline" />{item.time}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <hr className="mt-1" />
                </div>
              ))}
            </div>
          </SimpleBar>
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default RightSidebar;