import React from "react";
import { Col, Row, Container } from "reactstrap";
import VersioningFeed from "./Logs/VersoningFeed";
import Breadcrumbs from "../../../Components/Common/Breadcrumb";
import { useParams } from "react-router-dom";

function Logs() {
  const {id} = useParams(); 
  document.title = "Activity Logs | Content Pro";
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Activity" breadcrumbItem="Versioning Logs" isGoBackButton = {true} project={true} to={`/posts-details/${id}`}/>
          <Row>
            <Col>
              <VersioningFeed />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Logs;
