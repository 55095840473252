const convertDateFormat = (dateString) => {
  if (!dateString) {
    return;
  }
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-GB');
  return formattedDate;
};

export default convertDateFormat; 
