import React, { useState } from "react";
import {Col, Container, Row,} from "reactstrap";
import { Link } from "react-router-dom";
// images 
import logo from "../../assets/images/logo-sm.png";
import logoDark from "../../assets/images/logo-large.png";
// Components 
import Projects from "./Projects";
import Posts from "./Posts";
import BacklogTabs from "./BacklogTabs";
import Backlogs from "./Backlogs";
import Notification from "./Notification";
import { ToastContainer } from "react-toastify";

function Home() {
  document.title = "Dashboard | Content Pro";

  const [showNotification, setShowNotification] = useState<boolean>(false);

  return (
    <>
    <Notification showNotification={showNotification} setShowNotification={setShowNotification}/>

      <div id="main-content" className="page-content" style={showNotification ? {"paddingTop": "0px"} : {}}>
        <Container fluid className="overflow-hidden">
          {/* Brand Icon */}
          <Row>
            <Col className="col-12">
              {/* logo */}
              <div className="d-flex">
                <div className="navbar-brand-box mx-auto my-4">
                  <Link to="/" className="logo logo-dark">
                    <span className="logo-sm">
                      <img src={logo} alt="" height="22" />
                    </span>
                    <span className="logo-lg">
                      <img src={logoDark} alt="" height="22" />
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          {/* Brand Icon ends*/}

          {/* Main content */}
          <Row>
            {/* Left column */}
            <Col lg={6}>
              <Projects/>
              <Posts/>
              <BacklogTabs/>
            </Col>

            {/* Right Column */}
            <Col lg={6}>
              <Backlogs/>
            </Col>
          </Row>

        </Container>
        <ToastContainer/>
      </div>
    </>
  );
}

export default Home;
