import React, { useEffect, useState } from 'react'
import NotificationUI from "./UI/Notification";
import { useLocation } from 'react-router-dom';
import { getBusinessDetail } from 'helpers/API Calls/Super Admin/businesses';
import { resendVerificationEmail } from 'helpers/API Calls/Dashboard/Email Verification';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

function Notification({showNotification, setShowNotification}) {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get('businessId');

  const [notificationMessage, setNotificationMessage] = useState<string>(""); 
  const [showResendButton, setShowResendButton] = useState<boolean>(true);
  const [businessData, setBusinessData] = useState<any>(); 

  const selectProperties = createSelector(
    (state: any) => state.user,
    (user) => ({user: user.user,})
  );
  const { user } = useSelector(selectProperties);

  useEffect(() => {
    async function fetchData() {
        const data = await getBusinessDetail(businessId);
        // console.log('from email verification notification: !data.email_verified_at', !data.email_verified_at);
        // console.log('from email verification notification: Business data', data);
        setBusinessData(data);
        // console.log('user', user)
        // console.log("user.user_type === 'Admin'", user.email_verified_at)
        if(user.user_type === 'Admin'){
          if (data && !data.email_verified_at) {
            setNotificationMessage(`<b class="me-2">Verification Email Sent: </b>
              Check your inbox and follow the instructions. For more info, visit our <Link to="/help">help</Link> guide. Didn't receive it?`); 
            setShowNotification(true);
          }
        }
        else{
          if (user.user_type !== 'Admin' && !user.email_verified_at) {
            setNotificationMessage(`<b class="me-2">Verification Email Sent: </b>
              Check your inbox and follow the instructions. For more info, visit our <Link to="/help">help</Link> guide. Didn't receive it?`); 
            setShowNotification(true);
          }
        }
    }
    fetchData();
  }, [setShowNotification, businessId]);
    
  const onClickReSend = async () => {
      console.log(user.user_type)
      let id;
      if(user.user_type === 'Admin'){
        id = businessData.owner_id
      }else{
        id = user.id
      }
      const data = await resendVerificationEmail(id);
      // console.log(data.success)
      if (data && data.success) {
        setNotificationMessage(` An email has been sent to your inbox to verify your email address.`)
        setShowResendButton(false)
      }
  }  

  return (
    showNotification && (
      <div className={`notification ${showNotification ? 'show' : ''}`}>
        <NotificationUI message={notificationMessage}>
          {showResendButton && (
            <button className="ms-2 btn btn-sm btn-warning" onClick={() => onClickReSend()}>
              Resend Email
            </button>
          )}
        </NotificationUI>
      </div>
    )
  );
}

export default Notification