import React from "react";
import SidebarContent from "./SidebarContent";

const Sidebar = (props: any) => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
      <div className="navbar-brand-box"></div>
        <div data-simplebar className="h-100 margin-70">
          {props.type !== "condensed" ? <SidebarContent variant = {props.variant}/> : <SidebarContent variant = {props.variant}/>}
          {/* <SidebarContent variant = {props.variant}/> */}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;