import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    user: null,
    error: false,
    loading: false,
    errorMsg: "",
};

const userSlice = createSlice({
    name: "currentUser",
    initialState,
    reducers: {
        getUserRequest(state) {
            state.loading = true;
            state.errorMsg = "";
            state.error = false;
        },
        getUserSuccess(state, action) {
            state.user = action.payload;
            state.loading = false;
            state.error = false;
            state.errorMsg = "";
        },
        getUserFailure(state, action) {
            state.errorMsg = action.payload;
            state.loading = false;
            state.error = true;
        },
        resetGetUserState(state) {
            state.errorMsg = "";
            state.loading = false;
            state.error = false;
            state.user = null;
        },
    },
});

export const { getUserRequest, getUserSuccess, getUserFailure, resetGetUserState } = userSlice.actions;
export default userSlice.reducer;
