import React from 'react'
import { Col, Row } from 'reactstrap'

function Header({author, date, status = "", title}) {
  return (
    <>
        <Row>
            <Col sm={4}>
                <div className="mb-2">
                Created by <b>{author}</b> on <b>{date}</b>.
                </div>
            </Col>
            {status && <Col sm={4} className="">
                <div className="mb-2 text-md-center text-sm-start">
                Status: <b className="text-success">{status}</b>
                </div>
            </Col>}
        </Row>
                
        <Row>
            <div className="">
                <h3 className="text-truncate fw-bold font-size-21 mt-2">{title}</h3>
            </div>
        </Row>
    </>
  )
}

export default Header