import React, { useEffect, useState } from "react";
import { Col, Label, Row } from "reactstrap";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";

import createIframelyPlugin from "@jimmycode/draft-js-iframely-plugin";
const { Iframely } = createIframelyPlugin();

// api d87405ca0bb3109d1ef1c5


function CustomEditor({description}) {
  // Convert HTML to ContentState
  const contentBlocks = convertFromHTML(description || "");
  // console.log("contentBlocks")
  // console.log(contentBlocks)
  const contentState = ContentState.createFromBlockArray(contentBlocks);
  // Initialize the editorState
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );
  const [convertedContent, setConvertedContent] = useState("");

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);

//   console.log(convertedContent);

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  const toolbarOptions = {
    options: ['inline', 'blockType', 'list', 'history', 'emoji',],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
    },
    list: {
      options: ['unordered', 'ordered'],
    },
    history: {
      options: ['undo', 'redo'],
    },
    emoji: {}, // enabling emoji option
  };
  

  return (
    <>
      <Row>
        <Col className="mx-auto">
          <div className="">
            <Label htmlFor="activityDec" className="col-form-label">Description </Label>
            <Editor
              placeholder="Place Your Content Here..."
              editorState={editorState}
              onEditorStateChange={setEditorState}
            //   editorStyle={{ height: "500px" }}
            toolbar={toolbarOptions}
              plugins={[Iframely]}
            />
          </div>
        </Col>
      </Row>
      {/* <Row>
        <div className="bg-light mb-4">{convertedContent}</div>
      </Row>

      <Row className="bg-primary-subtle">
        <div
          className="preview"
          dangerouslySetInnerHTML={createMarkup(convertedContent)}
        ></div>
      </Row> */}
    </>
  );
}

export default CustomEditor;
