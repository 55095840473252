import React from 'react'


function SocialIcon({platform, title, type}) {

    let social =  { title: "", bgColor: "", iconClass: ""}; ;
    switch(platform){
        case "facebook": 
            social =  { title: "Facebook", bgColor: "bg-primary", iconClass: "mdi-facebook"}; 
            break; 
        case "instagram": 
            social =  { title: "Instagram", bgColor: "bg-pink", iconClass: "mdi-instagram"};            ; 
            break; 
        case "website": 
            social =  { title: "Wordpress", bgColor: "bg-warning", iconClass: "mdi-web"}; 
            break; 
        case "twitter": 
            social =  { title: "Twitter", bgColor: "bg-info", iconClass: "mdi-twitter",};            ; 
            break; 
        case "linkedIn": 
            social =  { title: "LinkedIn", bgColor: "bg-primary", iconClass: "mdi-linkedin"}; 
            break; 
        default: 
            social =  { title: "", bgColor: "bg-danger", iconClass: "mdi-error"}; 
            break;
    } 
  
  return (
    <div className="social-source d-flex">
        <div className="avatar-xs mx-auto">
            <span className={"avatar-title rounded-circle " + social.bgColor + " font-size-16"} >
            <i className={"mdi " +  social.iconClass + " text-white"}></i>
            </span>
        </div>
        <div className='ps-2'>
          <h5 className="font-size-15 my-auto">{title}</h5>
          <p className='me-auto mt-1 card-title-desc mb-0'>{type}</p>
        </div>
    </div>
  )
  }

export default SocialIcon