import React, { useRef, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, Modal, ModalBody, Row, UncontrolledDropdown } from "reactstrap";
import Breadcrumbs from "Components/Common/Breadcrumb";
import DeleteModal from "Components/Common/DeleteModal";
import CustomIcon from "Components/Custom/CustomIcon";


export const STATUSES = [
    {
      id: 1,
      title: "Media In Progress", 
      color: "#F0FFFF",
    },
    {
        id: 11,
        title: "Idea In Motion", 
        color: "#65451F",
    },
    {
      id: 2,
      title: "Published	", 
      color: "#088F8F",
    },
    {
      id: 3,
      title: "Archived", 
      color: "#0047AB",
    },
    {
      id: 4,
      title: "Published on Social Media", 
      color: "#CCCCFF",
    },
    {
      id: 5,
      title: "Published on Website", 
      color: "#A91D3A",
    },
    {
      id: 6,
      title: "Ready To Publish", 
      color: "#B5C18E",
    },
    {
      id: 7,
      title: "Ready To Add Image/Media", 
      color: "#EADBC8",
    },
    {
      id: 8,
      title: "Ready to Proofread", 
      color: "#FCDC2A",
    },
    {
      id: 9,
      title: "Ready to Write", 
      color: "#B3A398",
    },
    {
      id: 10,
      title: "Idea Approved", 
      color: "#FF8F8F",
    },
    
  ]

function Status() {
  //meta title
  document.title = "Status | Content Pro";
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [status, setStatus] = useState<any>(null); // Change status state to store ID instead of object

  const inputRef = useRef<HTMLInputElement>(null);
  const colorRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (inputRef.current && colorRef.current) {
      const enteredTitle = inputRef.current.value;
      const enteredColor = colorRef.current.value;
      console.log("Entered Title:", enteredTitle, "Entered Color: ", enteredColor);
    } else {
      console.error("No inputs");
    }
    setStatus(null);
    setModal(false);
  };



  return (
    <>
    <Modal isOpen={modal} toggle={() => {setStatus(null); setModal(false)}} centered={true} backdrop={'static'}>
      <div className="modal-content">
        <ModalBody className="px-4 pt-5">
          <button type="button" onClick={() => {setStatus(null); setModal(false);} } className="btn-close position-absolute end-0 top-0 m-3"></button>
          <div className="mb-4 text-center font-size-20 text-primary">{status ? "Edit" : "Add"} Status</div>
          <Form onSubmit={handleSubmit}>
            <FormGroup className="select2-container">
                <Label className="col-form-label">Title</Label>
                <Input 
                type="text" id="title" name="title" 
                className="form-control"
                innerRef={inputRef} 
                defaultValue={status ? status.title : ""} required/>
                <Label className="col-form-label">Color</Label>
                <Input 
                  type="color" 
                  innerRef={colorRef}  
                  id="color" 
                  name="color" 
                  className="form-control form-control-color w-100" 
                  defaultValue={`${status ? status.color : "#556ee6"}`}/>
            </FormGroup>
            <div className="hstack gap-2 mb-0 justify-content-end">
              <button type="button" className="btn btn-secondary" onClick={() => {setStatus(null); setModal(false)}}>Close</button>
              <button type="submit" className="btn btn-success">Save</button>
            </div>
          </Form>
        </ModalBody>
      </div>
    </Modal>
    <DeleteModal
      show={deleteModal}
      onDeleteClick={() => {console.log(status); setStatus(null); setDeleteModal(false)}}
      onCloseClick={() => {setStatus(null); setDeleteModal(false)}}
    />
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Status" breadcrumbItem="Status List" />
        <Card>
          <CardBody>
            <CardHeader className="bg-white">
                <div className="text-sm-end text-end mb-2">
                    <Button type="button" className="btn btn-success btn-rounded btn-secondary" onClick={() => {setModal(true)}} >
                        <i className="mdi mdi-plus me-1"></i>Add New Status
                    </Button>
                </div>
            </CardHeader>
            <Row>
              {STATUSES.map(item => 
                <Col lg={4} md={6} key={item.id}>
                  <div className="carousel-item active">
                      <div className="bg-light p-3 d-flex mb-3 rounded">
                      <div className="rounded me-3" style={{backgroundColor : `${item.color}`, height: "2rem", width: "4rem"}} > </div>
                          <div className="my-auto">
                              <h5 className="font-size-15 mb-2"><a href="candidate-overview" className="text-body">{item.title}</a></h5>
                          </div>
                          <div className="flex-grow-1 my-auto text-end">
                              <UncontrolledDropdown>
                                  <DropdownToggle className="btn btn-soft-primary" type="button" id="dropdownMenuButton11">
                                    <CustomIcon className="bx " iconName="bx-dots-vertical-rounded" id={`action-${item.id}`} text="actions" />
                                  </DropdownToggle>
                                  <DropdownMenu aria-labelledby="dropdownMenuButton11">
                                      <li onClick={() => {setStatus(item); setModal(true);}}><DropdownItem href="#">Edit</DropdownItem></li>
                                      <li onClick={() => {setStatus(item); setDeleteModal(true);}}><DropdownItem href="#">Delete</DropdownItem></li>
                                  </DropdownMenu>
                              </UncontrolledDropdown>
                          </div>
                      </div>
                  </div>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
    </>
  );
}

export default Status;
