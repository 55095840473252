import React, { useRef, useState, useEffect } from "react";
// import Breadcrumbs from "Components/Common/Breadcrumb";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Input, Label, Row, Button, Form, Spinner } from "reactstrap";
import { createRole, getPermissions } from 'helpers/API Calls/Space Settings/Users Settings/Roles/Roles';
import { useLocation, useNavigate } from "react-router-dom";
import GoBackButton from "Components/Common/GoBackButton";

interface Permission {
  id: number;
  name: string;
  slug: string;
}

interface PermissionCategory {
  category: string;
  permissions: Permission[];
}

const Checkbox = ({ id, label, checked, onChange }: { id: string; label: string; checked: boolean; onChange: (event: React.ChangeEvent<HTMLInputElement>) => void; }) => (
  <div className="form-check">
    <input className="form-check-input me-3" type="checkbox" id={id} checked={checked} onChange={onChange} />
    <label className="form-check-label" htmlFor={id}>{label}</label>
  </div>
);

function RoleForm() {
  document.title = "Create Role | Content Pro";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");

  const navigate = useNavigate(); 

  const inputRef = useRef<HTMLInputElement>(null);
  const [permissions, setPermissions] = useState<{ [key: string]: { checked: boolean, id: number } }>({});
  const [permissionsData, setPermissionsData] = useState<PermissionCategory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    const fetchPermissions = async () => {
      const response = await getPermissions(); 
      if (response) {
        const categorizedPermissions = response.reduce((acc: { [key: string]: PermissionCategory }, permission: { id: number; permission_parent: string; name: string; slug: string; }) => {
          const { id, permission_parent, name, slug } = permission;
          if (!acc[permission_parent]) {
            acc[permission_parent] = {
              category: permission_parent,
              permissions: []
            };
          }
          acc[permission_parent].permissions.push({ id, name, slug });
          return acc;
        }, {});

        setPermissionsData(Object.values(categorizedPermissions));
      } else {
        console.error('No permissions data found');
        setPermissionsData([]);
      }
      setIsLoading(false);
    };
    fetchPermissions();
  }, []);

  const handlePermissionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    setPermissions((prev) => {
      const newStatus = { ...prev };
      const permissionId = parseInt(id, 10);
      if (checked) {
        newStatus[id] = {
          checked,
          id: permissionId,
        };
      } else {
        delete newStatus[id];
      }
      return newStatus;
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const selectedPermissions = Object.values(permissions)
      .filter(permission => permission.checked)
      .map(permission => ({ permission_id: permission.id }));

    const response = await createRole({
      name: inputRef.current?.value,
      business_id: businessId,
      permissions: selectedPermissions,
    });

    if (response?.success) {
      setPermissions({});
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      setTimeout(() => {navigate(`/roles?businessId=${businessId}`);}, 2000);
    }
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* <Breadcrumbs title="Role" breadcrumbItem="Create Role" isGoBackButton={true} to="/roles" /> */}
        <GoBackButton to="/roles" />
        <Row>
          <Col>
            {isLoading ? (
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <Card>
                <CardBody>
                  {permissionsData && permissionsData.length !== 0 ? (
                  <>
                    <div className="d-flex align-items-center mb-2">
                      <h3 className="mb-0 flex-grow-1 ">Create Role</h3>
                      <div className="flex-shrink-0">{/* ride justified data */}</div>
                    </div>
                    <Form
                      id="role-form"
                      onSubmit={handleSubmit}
                      autoComplete="off"
                      className="overflow-hidden"
                    >
                      <Row>
                        <Col md={6} className="mb-4">
                          <div className="my-4">
                            <Label htmlFor="name-input">Title</Label>
                            <Input id="title" name="title" type="text" placeholder="Enter Title" required innerRef={inputRef} />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        {permissionsData.map((category) => (
                          <Col lg={4} md={6} className="mb-4" key={category.category}>
                            <h2 className="card-title">{category.category}</h2>
                            <div className="control-group my-4">
                              {category.permissions.map((permission) => (
                                <Checkbox
                                  key={permission.id}
                                  id={permission.id.toString()}
                                  label={permission.name}
                                  checked={permissions[permission.id]?.checked || false}
                                  onChange={handlePermissionsChange}
                                />
                              ))}
                            </div>
                          </Col>
                        ))}
                      </Row>

                      <div className="d-flex flex-wrap gap-2 justify-content-end mx-auto px-1 mt-1">
                        <Button type="submit" color="success">
                          Create Role
                        </Button>
                      </div>
                    </Form>
                    </>
                  ) : (
                    <p className="text-danger text-center">Failed to fetch data. Please refresh the page or try later.</p>
                  )}
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </div>
  );
}

export default RoleForm;
