import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import withRouter from "../../Components/Common/withRouter";

const VerticalLayout = (props: any) => {

  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    if (open) {
      document.body.classList.add("right-bar-enabled")
      document.body.style.paddingRight = ''
    } else {
      document.body.classList.remove("right-bar-enabled")
    }
  }, [open])

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header toggleCanvas={() => setOpen(!open)} />
        <Sidebar variant = {props.variant}/>
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(VerticalLayout);
