import React from "react";
import { Card, CardBody, Container} from "reactstrap";
import Breadcrumbs from "Components/Common/Breadcrumb";

function Help() {
  document.title = "Help | Content Pro";

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Space" breadcrumbItem="Help" />
        <Card className="p-3">
          <CardBody className="border-bottom">Help</CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default Help;
