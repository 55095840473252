import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Row, Spinner, Modal, ModalBody, Form, FormGroup, Label, Input, ModalHeader  } from "reactstrap";
// import Breadcrumbs from "Components/Common/Breadcrumb";
import { useParams } from "react-router-dom";
import { getSubscription } from "helpers/API Calls/Space Settings/Subscriptions/Subscription";
import { ToastContainer } from "react-toastify";
import convertDateFormat from "Components/Custom/DateFormat";
import { editSubscription } from "helpers/API Calls/Super Admin/Billing";
import GoBackButton from "Components/Common/GoBackButton";

function SubscriptionDetails() {
  document.title = "Subscription Details | Content Pro";
  
  // getting subscription data 
  const params = useParams(); 
  const businessId = params.businessId;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subscriptionData, setSubscriptionData] = useState<any>(null);

  const getData = useCallback(async () => {
    setIsLoading(true);
    const data = await getSubscription(businessId);
    setSubscriptionData(data);
    setIsLoading(false);
  }, [businessId])

  useEffect(() => {
    getData();
  }, [getData]);

  // Actions on subscription data 
  const [modal, setModal] = useState<boolean>(false); 
  const [blockModal, setBlockModal] = useState<boolean>(false);
  const [allowAccessTill, setAllowAccessTill] = useState(""); 

  const handleSchedule = async (event) => {
    event.preventDefault();
    if (allowAccessTill) {
      const selectedDateTime = allowAccessTill;
      const response = await editSubscription({allow_access_till: selectedDateTime}, subscriptionData.business_id); 
      if(response?.success === true){
        getData();
        setModal(false);
      }
    } else {
      console.error("Input is null");
    }
  };

  const handleBlockAccess = async (event) => {
    event.preventDefault();
      const response = await editSubscription({allow_access_till: null}, subscriptionData.business_id); 
      if(response?.success === true){
        getData();
        setBlockModal(false);
      }
  };

  const handleSelectSubscription = (item) => {
    setAllowAccessTill(item.allow_access_till || "");
    setModal(true);
  };


  return (
   <>
    <Modal isOpen={modal} toggle={() => setModal(false)} centered={true}>
      <ModalHeader toggle={() => setModal(false)} tag="h4">Allow Access</ModalHeader>
      <ModalBody className="">
        <div className="mb-4 text-center">
          <div className="font-size-18" style={{ fontWeight: 'bold', color: '#333' }}>
              Business: <span className="text-success">{subscriptionData?.business_name}</span> - Current Plan: <span className="text-success">{subscriptionData?.product_name}</span>
          </div>
        </div>
        <Form onSubmit={handleSchedule}>
          <FormGroup className="mb-3">
            <Label for="publish-time">Select Date:</Label>
            <Input type="date" id="publish-time" name="publish-time" value={allowAccessTill} onChange={(e) => {setAllowAccessTill(e.target.value)}} required />
          </FormGroup>
          <div className="d-flex justify-content-end">
            <Button color="secondary" onClick={() => setModal(false)} className="me-2">Cancel</Button>
            <Button type="submit" color="success">Allow Access</Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>

    <Modal isOpen={blockModal} toggle={() => setBlockModal(false)} centered={true} backdrop={'static'}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button type="button" onClick={() => setBlockModal(false)} className="btn-close position-absolute end-0 top-0 m-3"></button>
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-block-helper"></i>
            </div>
          </div>
          <p className="text-muted font-size-16 mb-4">Are you sure you want to block access of <strong>{`Business ${subscriptionData?.business_name} with ${subscriptionData?.product_name} plan`}</strong>.</p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button type="button" className="btn btn-secondary" onClick={() => setBlockModal(false)}>Close</button>
            <button type="button" className="btn btn-danger" onClick={handleBlockAccess}>Block Now</button>
          </div>
        </ModalBody>
      </div>
    </Modal>

    <div className="page-content">
    <ToastContainer/>
    <Container fluid>
      {/* <Breadcrumbs title="Subscriptions" breadcrumbItem="Subscription Details" isGoBackButton = {true} to="/admin/subscriptions" admin={true}/> */}
      <GoBackButton to="/admin/subscriptions" admin={true}/> 
      {isLoading ? (
        <Spinner color="primary" className="position-absolute top-50 start-50"/>
      ) : (
        subscriptionData ?
        <>
        <Row>

          <Col xl={3}>

            <Card>
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="avatar-xs">
                    <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded">
                      {subscriptionData.business_name?.charAt(0)}
                    </div>
                  </div>
                  <h5 className="ms-3 mb-0">{subscriptionData.business_name}</h5>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="avatar-xs">
                    <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                      <i className="bx bx-user fs-4"></i>
                    </div>
                  </div>
                  <h5 className="ms-3 mb-0">{subscriptionData.user_name}</h5>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <h5 className="fw-semibold">Subscription Information</h5>

                  <ul className="list-unstyled mt-4">
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-list-ol text-success fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">{subscriptionData.product_name}</h6>
                            <p className="text-muted mb-0">{subscriptionData.product_description || "No description available"}</p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-dollar text-success fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Amount</h6>
                          <p className="text-muted fs-14 mb-0">${subscriptionData.amount || 0}{subscriptionData.currency?.toUpperCase() || "USD"}/{subscriptionData.interval || "month"}</p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                        <div className="d-flex">
                            <i className="bx bx-time-five text-success fs-4"></i>
                            <div className="ms-3">
                                <h6 className="fs-14 mb-2">Interval</h6>
                                <p className="text-muted fs-14 mb-0">{subscriptionData.interval.charAt(0).toUpperCase() + subscriptionData.interval.slice(1)}</p>
                            </div>
                        </div>
                    </li>
                    <li className="mt-3">
                        <div className="d-flex">
                            <i className="bx bx-pulse text-success fs-4"></i>
                            <div className="ms-3">
                                <h6 className="fs-14 mb-2">Status</h6>
                                <p className="text-muted fs-14 text-break mb-0">{subscriptionData.stripe_status.charAt(0).toUpperCase() + subscriptionData.stripe_status.slice(1)}</p>
                            </div>
                        </div>
                    </li>
                  </ul>
              </CardBody>
            </Card>
  
          </Col>

          <Col xl={9}>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="fw-semibold">Payment Details</h5>
                    <div>
                    <Button className="btn btn-sm btn-soft-info mx-2" onClick={() => {handleSelectSubscription(subscriptionData)}} disabled={subscriptionData.stripe_id === null}>
                      Extend Access                         
                    </Button>
                    <Button className="btn btn-sm btn-soft-danger" onClick={() => {setBlockModal(true)}} disabled={subscriptionData.stripe_id === null || !subscriptionData.allow_access_till}>
                      Block Access
                    </Button>
                    </div>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <tbody>  
                      <tr>
                        <th scope="col">Stripe ID</th>
                        <td headers="jobTitleHeader">{subscriptionData.stripe_id}</td>
                      </tr>
                      <tr>
                        <th scope="row">Stripe Price ID</th>
                        <td>{subscriptionData.stripe_price}</td>
                      </tr>
                      <tr>
                        <th scope="row">Due Date</th>
                        <td>{convertDateFormat(subscriptionData.due_date) || 'N/A'}</td>
                      </tr>
                      <tr>
                          <th scope="row">Allow Access Till</th>
                          <td>{convertDateFormat(subscriptionData.allow_access_till) || 'N/A'}</td>
                       </tr>
                        <tr>
                          <th scope="row">Subscription Created At</th>
                          <td>{convertDateFormat(subscriptionData.created_at) || 'N/A'}</td>
                        </tr>
                        <tr>
                          <th scope="row">Last Updated At</th>
                          <td>{convertDateFormat(subscriptionData.updated_at) || 'N/A'}</td>
                        </tr>
                        <tr>
                          <th scope="row">Access Status</th>
                          <td><span className={`badge badge-soft-${subscriptionData.access_status ? 'success' : 'danger'}`}>{subscriptionData.access_status ? "Active" : 'In-Active'}</span></td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>   
          </Col>

        </Row>
        </>
        :
        <Row><div className="text-center text-danger">Sorry! No data found.</div></Row>
      )}
    </Container>
  </div>
   </>
  );
}

export default SubscriptionDetails;
