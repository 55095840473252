import React from "react";
import { Container} from "reactstrap";
import Breadcrumbs from "Components/Common/Breadcrumb";
import SocialMediaCards from "./Integrations/social-media-platforms";
import ConnectedAccounts from "./Integrations/connected-accounts";

function Integrations() {
  document.title = "Integrations | Content Pro";
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Project" breadcrumbItem="Integrations" />
        <ConnectedAccounts />
        <SocialMediaCards />
      </Container>
    </div>
  );
}

export default Integrations;
