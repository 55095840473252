import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, CardBody, Card, Container, Form, Input, Label, FormFeedback, Alert } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
// import images
import logo from "assets/images/logo-sm.png";
import logoDark from "assets/images/logo-large.png";
import { loginuser, resetLoginMsgFlag } from "slices/auth/login/thunk";
import withRouter from "Components/Common/withRouter";
import { createSelector } from 'reselect';

const Login = (props: any) => {
  const [show, setShow] = useState(false);
  const dispatch: any = useDispatch();

  //meta title
  document.title = "Login | Content Pro";

// This function creates a memoized selector.
// A selector is a function that computes derived state from the Redux store state. 
// Memoization means that the selector will only recalculate its value if its input selectors have changed.
  const selectProperties = createSelector(
    // The first argument to createSelector is a function that selects the relevant part of the Redux state. 
    // It takes the entire Redux state as an argument and 
    // returns the part of the state that the selector is interested in.
    (state: any) => state.Login,
    // The second argument is a function that computes the derived state based on the selected state. 
    // It takes the selected state as an argument and returns the derived state.
    (login) => ({error: login.error})
  );

  //  Hook for accessing the Redux store state in a functional component. 
  // It takes a selector function as an argument and returns the selected state from the Redux store.
  const { error } = useSelector(selectProperties);

  // Form validation 
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values: any) => {
      dispatch(loginuser(values, props.router.navigate));
    }
  });




  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetLoginMsgFlag())
      }, 5000);
    }
  }, [dispatch, error])

  return (
    <React.Fragment>
       <div className="home-btn-auth d-none d-sm-block">
        <Link to="/" className="text-dark d-flex">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logo} alt="" height="40" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="40" />
              </span>
            </Link>
          </div>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="py-3">
                  <h2 className="px-4 pt-4 text-dark"><strong>Welcome Back!</strong></h2>
                  {/* <p className="px-4">Sign in to continue to Content Pro.</p> */}
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        {error ? <Alert color="danger">{error}</Alert> : null}
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                          autocomplete="username"
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={show ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                            autocomplete="current-password"
                          />
                          <button onClick={() => setShow(!show)} className="btn btn-light " type="button" id="password-addon"><i className="mdi mdi-eye-outline"></i></button>
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div> */}

                      <div className="mt-4 d-grid">
                        <button className="btn btn-success btn-block " type="submit">Log In</button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock me-1" /> Forgot yourpassword?</Link>
                        <hr/>
                        <div className="text-center">
                          <p><strong>
                            Don&apos;t have an account? 
                            <Link to="/register" className="fw-medium text-success"> Signup now</Link>
                            </strong>
                          </p>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
