import { toast } from "react-toastify";
import request from "../../request_helper";


export const getSettings = async () => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "settings",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        // toast.success(response.message, { autoClose: 2000 });
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const updateSettings = async (settings) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "settings",
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body : JSON.stringify(settings),
        };
        const response = await request(args); 
        const data = await response;
        toast.success(response.message, { autoClose: 2000 });
        return data.data; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}
