import React, { useState } from "react";
import withRouter from "Components/Common/withRouter";
import { Button, Card, CardBody, Col, Row, Modal, ModalBody } from "reactstrap";
import { useParams } from "react-router-dom";

import { data } from "./VersoningFeed";

const VersionDetail = (props: any) => {
  let { logId } = useParams();
  const log = data.find((item) => item.version === logId);

  const [modal, setModal] = useState<boolean>(false); 

  return (
    <React.Fragment>
      
      <Modal isOpen={modal} toggle={() => setModal(false)} centered={true}>
        <div className="modal-content">
          <ModalBody className="px-4 py-5 text-center">
            <button type="button" onClick={() => setModal(false)} className="btn-close position-absolute end-0 top-0 m-3"></button>
            <div className="mb-4 mx-auto">
              <div className="text-primary font-size-20">
                Restore Version
              </div>
            </div>
            <p className="text-muted font-size-16 mb-4">Are you sure you want to restore the version.</p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
              <button type="button" className="btn btn-danger" onClick={() => setModal(false)}>Restore</button>
            </div>
          </ModalBody>
        </div>
      </Modal>

          <Card>
            <CardBody>
              <Row>
                <Col sm={4}>
                  <div className="mb-2">
                    Created by <b>Charles Brown</b> on <b>08 Sept, 2019</b>.
                  </div>
                </Col>
                <Col sm={4} className="">
                  <div className="mb-2 text-md-center text-sm-start">
                    Version: <b className="text-success">{log?.version}</b>
                  </div>
                </Col>
                <Col sm={4} className="text-end">
                  <Button className="btn btn-success" onClick={() => {setModal(true)}}>Restore</Button>
                </Col>
              </Row>
              {/* title */}
              <Row>
                <div className="">
                  <h3 className="fw-bold font-size-21">{log?.title}</h3>
                </div>
              </Row>
              <Row>
                <CardBody>
              <blockquote className="blockquote font-size-14 mb-0">
                <p>{log?.content}</p>
                <footer className="blockquote-footer mt-0 font-size-12 d-flex">
                  <div>
                    Updated by <b>{log?.name}</b>
                    <cite title="Source Title"> on {log?.date}</cite>
                  </div>
                </footer>
              </blockquote>

            </CardBody>
            </Row>
            </CardBody>
          </Card>
    </React.Fragment>
  );
};

export default withRouter(VersionDetail);
