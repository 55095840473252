import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row, Spinner } from "reactstrap";
import { ToastContainer } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select"
import { useLocation, useNavigate } from "react-router-dom";
import { createProject, getProjectType } from "helpers/API Calls/Space Settings/Projects/projects";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import "flatpickr/dist/themes/material_blue.css";
import { useDropzone } from 'react-dropzone';
import placeholderImage from "assets/images/Placeholder2.jpg"

const generateKey = (title) => {
  // Get the first letter of each word in the business name, ignoring empty strings
  const initials = title
    .split(' ')
    .filter(word => word)  // Filter out empty strings
    .map(word => word[0].toUpperCase())
    .join('');

  // Generate a timestamp
  const timestamp = Math.floor(Date.now() / 1000).toString().slice(-2);

  // Combine initials and timestamp
  return `${initials} ${timestamp}`;
};


function GeneralSettings() {
  document.title = "Create Project | Content Pro";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");

  const navigate = useNavigate(); 

  
  const selectProperties = createSelector(
    (state: any) => state.user,
    (user) => ({user: user.user,})
  );
  const { user } = useSelector(selectProperties);

  const [projectType, setProjectType] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    const fetchTypes = async () => {
      const response = await getProjectType(); 
      if (response) {
        setProjectType(response?.map(item => {return {label: item.project_type, value: item.id}})) 
      } else {
        console.error('No type data found');
        setProjectType([]);
      }
      setIsLoading(false);
    };
    fetchTypes();
  }, []);

  //  img upload
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const { getRootProps, getInputProps } = useDropzone({ onDrop: (acceptedFiles) => { setSelectedImage(acceptedFiles[0]); } });


// validation 
  // validation
  const validation: any = useFormik({
    initialValues: {
      title: "",
      type: null,
      startDate: '',
      dueDate: ''
    },
    validationSchema: Yup.object({
      title: Yup.string().matches(/^[A-Za-z0-9\s]+$/, 'Project Name can only contain letters, numbers, and spaces').required("Please Enter Project Name"),
      type: Yup
      .object()
      .shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
      .required("Please Select type"),
      startDate: Yup.string(),
      dueDate: Yup.string(),
    }),
    onSubmit: async (values: any, { setSubmitting }) => {
      const data: any = {
        project_name: values.title,
        business_id: businessId,
        project_key: generateKey(values.title),
        project_type_id: values.type.value,
        started_by: user.id
      };
  
      // Conditionally add startDate and dueDate if they are provided
      if (values.startDate) {
        data.started_at = `${values.startDate} 00:00:00`;
      }
      if (values.dueDate) {
        data.due_date = `${values.dueDate} 00:00:00`;
      }
  
      const response = await createProject({ data: data, thumbnail: selectedImage || null });
  
      if (response) {
        setSubmitting(false);
      }
  
      if (response.success) {
        validation.resetForm();
        setTimeout(() => { navigate(`/projects?businessId=${businessId}`); }, 2000);
      }
    }
  });

  const handleSelectChange = (selectedOption) => {
    validation.setFieldValue("type", selectedOption);
  };

  
  return (
    <div className="page-content">
      <Container fluid>
        {isLoading ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
          ) : (
          <Card>
            <CardBody>
              <Row>
                <>
                  <div className="d-flex align-items-center mb-2">
                    <h3 className="mb-0 flex-grow-1 ">Create Project</h3>
                    <div className="flex-shrink-0">{/* ride justified data */}</div>
                  </div>
                  <Form className="mt-4" onSubmit={(e: any) => {e.preventDefault(); validation.handleSubmit(); return false;}}>

                    <Col sm={7} className="mx-auto">
                      <Row>
                        <Col className="mx-auto">
                          <div className="mb-3">
                            <Label htmlFor="name-input">Project Logo</Label>
                            <div className="text-center" {...getRootProps()}>
                              <div className="position-relative d-inline-block" >
                                <div className="position-absolute bottom-0 end-0">
                                  <input className="form-control d-none" id="business-image-input" type="file" accept="image/png, image/gif, image/jpeg" {...getInputProps()}/>
                                </div>
                                <div className="avatar-xl rounded">
                                  <div className="avatar-title bg-light ">
                                    {/* {selectedImage && <img src={URL.createObjectURL(selectedImage)} id="logo-img" alt="" className="avatar-xl rounded h-auto "/>} */}
                                    {selectedImage ? (
                                      <img src={URL.createObjectURL(selectedImage)} id="logo-img" alt="" className="avatar-xl rounded h-auto "/>
                                    ) : (
                                      <img src={placeholderImage} id="logo-img" alt="" className="avatar-xl rounded h-auto "/>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="mx-auto">
                          <div className="mb-3">
                            <Label htmlFor="name-input">Project Name</Label>
                            <Input
                              id="title"
                              name="title"
                              type="text"
                              placeholder="Enter Project Title"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.title}
                              invalid={validation.touched.title && validation.errors.title ? true : false}
                            />
                            {validation.touched.title && validation.errors.title ? (
                              <FormFeedback type="invalid" className="d-block">{validation.errors.title}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col className="mx-auto">
                          <Label htmlFor="type">Type</Label>
                          <Select
                            id="type"
                            name="type"
                            placeholder= "Select Project Type"
                            options={projectType}
                            value={validation.values.type}
                            onChange={handleSelectChange}
                            onBlur={validation.handleBlur}
                            className={
                              validation.touched.type && validation.errors.type
                                ? "is-invalid"
                                : ""
                            }
                            
                          />
                          {validation.touched.type && validation.errors.type ? (
                            <FormFeedback type="invalid" className="d-block">
                              {validation.errors.type}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col className="mx-auto">
                          <Label>Start Date</Label>
                          <Input 
                            id="startDate"
                            name="startDate"
                            type="date" 
                            placeholder="Enter Due Date"
                            className="form-control d-block"
                            onChange={(event: any) => validation.setFieldValue("startDate", event.target.value)}
                            onBlur={validation.handleBlur}
                            value={validation.values.startDate || ''}
                            invalid={validation.touched.startDate && validation.errors.startDate ? true : false}
                          />
                          {validation.errors.startDate && validation.touched.startDate ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.startDate}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col className="mx-auto">
                          <Label>Due Date</Label>
                          <Input 
                            id="dueDate"
                            name="dueDate"
                            type="date" 
                            placeholder="Enter Due Date"
                            className="form-control d-block"
                            onChange={(event: any) => validation.setFieldValue("dueDate", event.target.value)}
                            onBlur={validation.handleBlur}
                            value={validation.values.dueDate || ''}
                            invalid={validation.touched.dueDate && validation.errors.dueDate ? true : false}
                          />
                          {validation.errors.dueDate && validation.touched.dueDate ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.dueDate}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row>
                        <div className="d-flex flex-wrap gap-2 justify-content-end mx-auto px-1 mt-4">
                          <Button 
                            type="submit" 
                            color="success" 
                            disabled={validation.isSubmitting} // Disable button during submission
                          >
                            {validation.isSubmitting ? 'Creating...' : 'Create Project'}
                          </Button>
                        </div>
                      </Row>
                      
                    </Col>
                  </Form>
                </>
              </Row>
            </CardBody>
          </Card>
        )}
        <ToastContainer />
      </Container>
    </div>
  );
}

export default GeneralSettings;
