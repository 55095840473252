import React, { useState } from "react";
import {Col, Row, CardText,TabContent,TabPane,Nav,NavItem,NavLink,CardBody,} from "reactstrap";
import CollapseItem from "./UI/CollapseItem";
import classnames from "classnames";


function BacklogTabs() {
    const [collapseStates, setCollapseStates] = useState(true);
    const [activeTab, setActiveTab] = useState("5");
    const toggle1 = (tab: any) => { activeTab !== tab && setActiveTab(tab);}

  return (
    <Row>
        <CollapseItem title="Backlogs" col={collapseStates} toggle={() => setCollapseStates((prevState) => !prevState)}>
            <CardBody>
                
                <Nav pills className="navtab-bg nav-justified">
                <NavItem>
                    <NavLink style={{ cursor: "pointer" }} className={classnames({active: activeTab === "5",})} onClick={() => {toggle1("5");}}>
                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                    <span className="d-none d-sm-block">Home</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink style={{ cursor: "pointer" }} className={classnames({active: activeTab === "6",})} onClick={() => {toggle1("6");}}>
                    <span className="d-block d-sm-none"><i className="fas fa-user-edit"></i></span>
                    <span className="d-none d-sm-block">Profile</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink style={{ cursor: "pointer" }} className={classnames({active: activeTab === "7",})} onClick={() => {toggle1("7");}}>
                        <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                        <span className="d-none d-sm-block">Messages</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink style={{ cursor: "pointer" }} className={classnames({active: activeTab === "8",})} onClick={() => {toggle1("8");}}>
                    <span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
                    <span className="d-none d-sm-block">Settings</span>
                    </NavLink>
                </NavItem>
                </Nav>

                <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane tabId="5">
                    <Row>
                    <Col sm="12">
                        <CardText className="mb-0">
                        Raw denim you probably haven&apos;t heard of them jean
                        shorts Austin. Nesciunt tofu stumptown aliqua, retro
                        synth master cleanse. Mustache cliche tempor,
                        williamsburg carles vegan helvetica. Reprehenderit
                        butcher retro keffiyeh dreamcatcher synth. Cosby
                        sweater eu banh mi, qui irure terry richardson ex
                        squid. Aliquip placeat salvia cillum iphone. Seitan
                        aliquip quis cardigan american apparel, butcher
                        voluptate nisi qui.
                        </CardText>
                    </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="6">
                    <Row>
                    <Col sm="12">
                        <CardText className="mb-0">
                        Food truck fixie locavore, accusamus mcsweeney&apos;s
                        marfa nulla single-origin coffee squid. Exercitation
                        +1 labore velit, blog sartorial PBR leggings next
                        level wes anderson artisan four loko farm-to-table
                        craft beer twee. Qui photo booth letterpress,
                        commodo enim craft beer mlkshk aliquip jean shorts
                        ullamco ad vinyl cillum PBR. Homo nostrud organic,
                        assumenda labore aesthetic magna delectus mollit.
                        Keytar helvetica VHS salvia yr, vero magna velit
                        sapiente labore stumptown. Vegan fanny pack odio
                        cillum wes anderson 8-bit.
                        </CardText>
                    </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="7">
                    <Row>
                    <Col sm="12">
                        <CardText className="mb-0">
                        Etsy mixtape wayfarers, ethical wes anderson tofu
                        before they sold out mcsweeney&apos;s organic lomo retro
                        fanny pack lo-fi farm-to-table readymade. Messenger
                        bag gentrify pitchfork tattooed craft beer, iphone
                        skateboard locavore carles etsy salvia banksy hoodie
                        helvetica. DIY synth PBR banksy irony. Leggings
                        gentrify squid 8-bit cred pitchfork. Williamsburg
                        banh mi whatever gluten-free, carles pitchfork
                        biodiesel fixie etsy retro mlkshk vice blog.
                        Scenester cred you probably haven&apos;t heard of them,
                        vinyl craft beer blog stumptown. Pitchfork
                        sustainable tofu synth chambray yr.
                        </CardText>
                    </Col>
                    </Row>
                </TabPane>

                <TabPane tabId="8">
                    <Row>
                    <Col sm="12">
                        <CardText className="mb-0">
                        Trust fund seitan letterpress, keytar raw denim
                        keffiyeh etsy art party before they sold out master
                        cleanse gluten-free squid scenester freegan cosby
                        sweater. Fanny pack portland seitan DIY, art party
                        locavore wolf cliche high life echo park Austin.
                        Cred vinyl keffiyeh DIY salvia PBR, banh mi before
                        they sold out farm-to-table VHS viral locavore cosby
                        sweater. Lomo wolf viral, mustache readymade
                        thundercats keffiyeh craft beer marfa ethical. Wolf
                        salvia freegan, sartorial keffiyeh echo park vegan.
                        </CardText>
                    </Col>
                    </Row>
                </TabPane>
                </TabContent>

            </CardBody>
        </CollapseItem>
    </Row>
  )
}

export default BacklogTabs