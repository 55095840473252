import React, { useState } from "react";
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap";
import SimpleBar from "simplebar-react";


const AuthorNotes = ({ comments }) => {
  const [text, setText] = useState<any>("");
  
  return (
    <Card>

        <h5 className="text-truncate fw-bold font-size-21 pb-1">Notes:</h5>
        <CardBody className="pb-0 bg-light rounded">
        <div className="pb-3 overflow-hidden">
          <Row>
            <Col>
                <Input type="text" className="form-control rounded" placeholder="Enter Message..." value={text} onChange={e => setText(e.target.value)} />
            </Col>
            <div className="col-auto">
              <Button type="submit" color="success" className="chat-send w-md " onClick={() => {console.log(text)}}>
                <span className="d-none d-sm-inline-block me-2">Add</span>
                <i className="mdi mdi-send" />
              </Button>
            </div>
          </Row>
        </div>
          <div>
            <div className="chat-conversation">
              <SimpleBar style={{ marginBottom: "1rem", maxHeight: "260px" }}>
                <ul className="list-unstyled">
                  {(comments || [])?.map((comment: any, index: number) => (
                    <div className="d-flex mb-4" key={index}>
                      <div className="me-3">
                        {comment.userImg ? (
                          <img
                            className="media-object rounded-circle avatar-xs"
                            alt=""
                            src={comment.userImg}
                          />
                        ) : (
                          <div className="avatar-xs">
                            <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-16">
                              {comment.username.charAt(0)}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-13 mb-1"> {comment.username}</h5>
                        <small className="text-muted float-end">April 20 at 1:52PM</small>
                        <p className="text-muted mb-1">{comment.comment}</p>
                      </div>

                    </div>
                  ))}
                </ul>
              </SimpleBar>
            </div>
          </div>
        </CardBody>
        
    </Card>
  );
};

export default AuthorNotes;
