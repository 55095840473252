import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container,Row } from "reactstrap";
import CustomIcon from "Components/Custom/CustomIcon";
// import { LinkWithQuery } from "helpers/link_helper";
import { ToastContainer } from "react-toastify";
import PaginatedTable from "Components/Custom/Table and Cards/PaginatedTable";
import { Link, useLocation } from "react-router-dom";
import { getUsers } from "slices/thunk";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import convertDateFormat from "Components/Custom/DateFormat";
import DeleteModal from "Components/Custom/DeleteModalWithMsg";
import { deleteUser } from "helpers/API Calls/Space Settings/Users Settings/Users/users";
import { getRoles } from "helpers/API Calls/Space Settings/Users Settings/Roles/Roles";


const head = [
  { id: 1, name: "#", isSortable: true, key: "id" },
  { id: 2, name: "User", isSortable: true, key: "name"},
  { id: 3, name: "User Type", isSortable: true, key: "user_type"},
  { id: 4, name: "Role", isSortable: true, key: "role"},
  { id: 5, name: "Created At", isSortable: true, key: "created_at"},
  { id: 6, name: "Updated At", isSortable: true, key: "updated_at"},
  { id: 7, name: "Actions"}
];


function Users() {
  document.title = "Users | Content Pro";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");

  // redux code
  const selectProperties = createSelector(
    (state: any) => state.users,
    (users) => ({
      users: users.usersList,
      loading: users.loading,
      pagination: users.pagination
    })
  );
  const { users, loading, pagination } = useSelector(selectProperties);

  // local state mng for getting business data
  const [usersData, setUsersData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(loading);
    setUsersData(users);
    // console.log('data from business component local state', businesses)
  }, [users, loading]);

  // for deletion 
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] =useState<any>(null);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const handleDelete = async () => {
    if (deleteItem) {
      const response = await deleteUser(deleteItem.id);
      setDeleteItem(null);
      setDeleteModal(false);
      setIsDeleted(response); 
    }
  };

  useEffect(() => {
    if (isDeleted) {
      setIsDeleted(false);
    }
  }, [isDeleted]);

  const [roles, setRoles] = useState([]); 

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const role = await getRoles();
      setRoles(role?.map(item => ({ label: `${item.name}`, value: item.id })));
      setIsLoading(false);
    }
    getData();
  }, []);

  // const [modal, setModal] = useState<boolean>(false); 
  // const [member, setMember] = useState<any>(); 
  // const [action, setAction] =useState<string>(''); 

  // const onClose = () => {setModal(false); setMember({});setAction("");}

  // const onSubmit = () => {console.log(member); onClose();}

  return (
  <>
    {/* <Modal isOpen={modal} toggle={onClose} centered={true} backdrop={'static'}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5">
          <button type="button" onClick={onClose} className="btn-close position-absolute end-0 top-0 m-3"></button>
          <div className="mb-4 text-center font-size-20 text-primary">{action === "delete" ? "Delete User" : "Block User"}</div>
          <p className="text-muted font-size-16 mb-4 text-center">Are you sure you want to {action} this user?</p>
          <div className="hstack gap-2 mb-0 justify-content-center">
            <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
            <button type="submit" className="btn btn-danger" onClick={onSubmit}>Yes</button>
          </div>
        </ModalBody>
      </div>
    </Modal> */}
    <DeleteModal
      show={deleteModal}
      onDeleteClick={handleDelete}
      onCloseClick={() => {setDeleteModal(false); setDeleteItem(null);}}
      message={`User ${deleteItem?.name} with role ${deleteItem?.roles[0].name}`}
    />

    <div className="page-content">
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <PaginatedTable
                tableHead={head}
                onGetData = {getUsers}
                pagination = {pagination}
                isLoading = {isLoading}
                buttonText = "Add New User" 
                isAddButton = {true} 
                to={`create?businessId=${businessId}`}
                isDeleted = {isDeleted}
                title = 'Users List'
                filterData= {roles}
                hasFilter = {true}
                filterId = 'role_id'
              >
                {!isLoading && usersData.length !== 0 && usersData.map((item : any, index : any) => (
                  <tr key={item.id}>

                    <th scope="row">{item.id}</th>
                    <td className="d-flex">
                      <div className="flex-shrink-0 me-2">
                        {item?.image ? 
                          <img src={item?.image} alt="" className="avatar-xs rounded-circle"/>
                          :
                          <div className="avatar-xs">
                            <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                              {item.name?.charAt(0)}
                            </div>
                          </div>
                        }
                        </div>
                        <div>
                        <h5 className='font-size-14 mb-1'>
                          {item.name}
                          {/* <Link to={`${item.id}`} className='text-dark'>{item.name}</Link> */}
                        </h5>
                        <p className="text-muted mb-0">{item.email}</p>
                      </div>
                    </td>
                    <td>{item.user_type}</td>
                    <td>{item.roles[0].name}</td>
                    <td>{convertDateFormat(item.created_at)}</td>
                    <td>{convertDateFormat(item.updated_at)}</td>
                    <td className="d-flex">
                      {/* <Link to={`${item.id}`} className="btn btn-sm btn-soft-primary">
                          <CustomIcon className="mdi " iconName= "mdi-eye-outline" id={`view-${item.id}`} text="View"/>
                      </Link> */}
                      <Link to={`${item.id}/edit?businessId=${businessId}`} className="btn btn-sm btn-soft-info mx-2 ">
                        <CustomIcon className="mdi " iconName= "mdi-pencil" id={`edit-${item.id}`} text="Edit"/>
                      </Link> 
                      <Button className="btn btn-sm btn-soft-danger " onClick={() => {setDeleteModal(true); setDeleteItem(item)}}>
                        <CustomIcon className="mdi " iconName= "mdi-trash-can" id={`delete-${item.id}`} text="Delete"/>
                      </Button>
                      {/* <button className="btn btn-sm btn-soft-warning mx-2" onClick={() => {setModal(true); setMember(item); setAction("block");}} title="Block User">
                        <CustomIcon className="mdi " iconName= "mdi-block-helper" id={`block-${item.id}`} text="Block"/>
                      </button> */}
                    </td>

                  </tr>
                ))}
                {isLoading && usersData.length === 0 && <tr className="text-center"><td colSpan={head.length} className="text-danger">No Data Found</td></tr>}
              </PaginatedTable>
            </Card>
          </Col>
        </Row>
        <ToastContainer />

      </Container>
    </div>
  </>
  );
}

export default Users;
