import { Link, useLocation } from 'react-router-dom';

export const LinkWithQuery = ({ children, to, admin = false, project = false, ...props }) => {
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = !admin && searchParams.get('businessId');
  const projectId = project && searchParams.get('projectId');
  const query = admin ? '' : (project ? `projectId=${projectId}&businessId=${businessId}`: `businessId=${businessId}`)

   // Remove the businessId parameter to avoid duplication
   searchParams.delete('businessId');

   // get the query string with the given parameters
   const queryString = props.queries;
 
   // Append the businessId parameter to the link
   const linkTo = to !== '*' ? (queryString ? `${to}?${queryString}&${query}` : `${to}?${query}`) : '*';
 

  return (
    <Link to={linkTo} {...props}>
      {/* {console.log('link helper function', 'props to', to, 'Linkto',  linkTo, 'type', typeof linkTo)} */}
      {children}
    </Link>


  );
};