import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUser } from "slices/thunk";
import { Spinner } from "reactstrap";
import { Container, Row, Col } from "reactstrap"
import error from "assets/images/error-img.png"


const AuthProtected = (props: any) => {
  const dispatch = useDispatch<any>();
  // const { platform } = useParams();

  const selectProperties = createSelector(
    (state: any) => state.user,
    (user) => ({
      user: user.user,
      loading: user.loading,
      error: user.error,
      errorMsg: user.errorMsg,
    })
  );
  const { user, error, loading, errorMsg } = useSelector(selectProperties);

  const [isLoading, setIsLoading] = useState(true);

  
  // let role ; 
  // on page refresh, we will get the user-role again from the backend.  
  // useEffect(() => {
    // api call 
    // role = response user-role
    // console.log("getting role")
    // the role will have permissions too
    // even if we will store the role in local storage, on every page refresh 
    // it will be get again and updated 
  // } , [])

  // instead of checking user, check token 
  // cannot rely on tokens only, because they can be set manually 
  // data would be protected because it will come from backend 
  // but views will be expose 

  useEffect(() => {
    async function getData() {
      await dispatch(getUser());
      setIsLoading(false);
    }
    getData();
  }, [dispatch]);
  
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }
  
  if (isLoading || loading) {
    return <Spinner
    color="primary"
    className="position-absolute top-50 start-50"
    />
  }

  if (error || !user) {
    return <ErrorMessage message={errorMsg}/>
  }
  
  console.log('Auth check');
  console.log('From auth protected, current User', user)
  // console.log("asdf", user.roles[0].permissions)

  const permissions = user.roles?.[0].permissions.map(permission => permission.slug) ;

  const routePermissions = props.routePermissions || [];
  // console.log('asdfasdf', routePermissions)
  // const platformPermission = `publish_post_on_${platform}`;
  const hasPermission = routePermissions.length === 0 || routePermissions.some(permission => permissions?.includes(permission)) 
  // || (routePermissions.includes(platformPermission) && permissions.includes(platformPermission));

  if (user.user_type === 'Member' && !hasPermission) {
    return <Navigate to="/access-denied" />;
  }

  if (!isLoading && props.variant && props.variant === 'admin') {
    if (user.user_type !== 'Admin') {
      // console.log('navigate from admin page');
      return <Navigate to="/access-denied" />;
    }
    console.log('admin permitted only');
  }
  
  if (!isLoading && props.variant && props.variant === 'space-settings') {
    if (user.user_type !== 'Business-Owner' && user.user_type !== 'Admin') {
      // console.log('navigate from space settings page');
      return <Navigate to="/access-denied" />;
    }
    console.log('business owner and admin permitted only');
  }
  

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AuthProtected;

const ErrorMessage = ({ message }) => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10); // Set initial countdown value here

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(timer);
      navigate('/login');
    }

    return () => clearInterval(timer);
  }, [countdown, navigate]);

  return (
    <div className="account-pages my-5 pt-5">
      <Container>
        <Row>
          <Col lg="12">
            <div className="text-center mb-5">
              <h1 className="display-2 fw-medium">
                4<i className="bx bx-buoy bx-spin text-success display-3" />
                3
              </h1>
              <h4 className="text-uppercase">{message}</h4>
              <p className="mb-0 mt-3 text-muted">We are unable to verify your credentials.</p>
              <p className="mt-0 text-muted">Please login again to continue.</p>
              <div className="mt-5 text-center">
                <p>Redirecting to login in {countdown} seconds...</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8" xl="6">
            <div>
              <img src={error} alt="Error" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

