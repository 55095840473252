import React from "react";
import { Container, Card, CardBody, Button, Label, Input, FormFeedback, Form, Row, Col } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumb from "Components/Common/Breadcrumb";

function ChangePassword() {

    const passwordValidation = useFormik({
        initialValues: {
          oldPassword: "", 
          newPassword: '',
          confirmPassword: "", 
        },
        validationSchema: Yup.object({
          oldPassword: Yup.string().required("Password is Required"), 
          newPassword: Yup.string().required("Password is Required"),
          confirmPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'Passwords must match').required("Password is Required"),  
        }),
        onSubmit: (values) => {
          console.log(values)
        }
      });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
            <Breadcrumb title="Content Pro" breadcrumbItem="Change Password" />
            <Row className="justify-content-center mt-5">
              <Col md={8} lg={6} xl={5}>
                {/* <h4 className="card-title mb-4">Change Password</h4> */}
                <Card >
                <CardBody>
                    <Form
                    id="passwordForm"
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        passwordValidation.handleSubmit();
                        return false;
                    }}
                    >
                    <div className="form-group">
                        <Label className="form-label">Previous Password</Label>
                        <Input
                        name="oldPassword"
                        className="form-control"
                        placeholder="Enter Previous Password"
                        type="password"
                        onChange={passwordValidation.handleChange}
                        onBlur={passwordValidation.handleBlur}
                        value={passwordValidation.values.oldPassword || ""}
                        invalid={passwordValidation.touched.oldPassword && !!passwordValidation.errors.oldPassword}
                        />
                        {passwordValidation.touched.oldPassword && passwordValidation.errors.oldPassword ? (
                        <FormFeedback type="invalid">
                            {typeof passwordValidation.errors.oldPassword === 'string' && passwordValidation.errors.oldPassword}
                        </FormFeedback>
                        ) : null}
                    </div>

                    <div className="form-group mt-4">
                        <Label className="form-label">New Password</Label>
                        <Input
                          name="newPassword"
                          className="form-control"
                          placeholder="Enter New Password"
                          type="password"
                          onChange={passwordValidation.handleChange}
                          onBlur={passwordValidation.handleBlur}
                          value={passwordValidation.values.newPassword || ""}
                          invalid={passwordValidation.touched.newPassword && !!passwordValidation.errors.newPassword}
                        />
                        {passwordValidation.touched.newPassword && passwordValidation.errors.newPassword ? (
                        <FormFeedback type="invalid">
                            {typeof passwordValidation.errors.newPassword === 'string' && passwordValidation.errors.newPassword}
                        </FormFeedback>
                        ) : null}
                    </div>

                    <div className="form-group mt-4">
                        <Label className="form-label">Confirm Password</Label>
                        <Input
                        name= "confirmPassword"
                        className="form-control"
                        placeholder="Confirm Password"
                        type= "password"
                        onChange={passwordValidation.handleChange}
                        onBlur={passwordValidation.handleBlur}
                        value={passwordValidation.values.confirmPassword || ""}
                        invalid={passwordValidation.touched.confirmPassword && !!passwordValidation.errors.confirmPassword}
                        />
                        {passwordValidation.touched.confirmPassword && passwordValidation.errors.confirmPassword ? (
                        <FormFeedback type="invalid">
                            {typeof passwordValidation.errors.confirmPassword === 'string' && passwordValidation.errors.confirmPassword}
                        </FormFeedback>
                        ) : null}
                    </div>

                    <div className="text-end mt-4">
                        <Button type="submit" color="success">Save Changes</Button>
                    </div>
                    </Form>
                </CardBody>
                </Card>
              </Col>
            </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ChangePassword