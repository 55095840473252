import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Label, Row, Form, Button } from "reactstrap";
import { getTimezones } from "helpers/API Calls/General/timezone";
import Select from "react-select";
import { getBusinessDetail, editBusiness } from "helpers/API Calls/Super Admin/businesses";
import { useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import { ToastContainer } from "react-toastify";

function Time() {
  document.title = "Time Zone | Content Pro";

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  // businessId id extracted out from useLocation hook which provides a location object
  // when the state changes and the component is re-rendered, the location object remain same, 
  // so does the businessId,  

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [businessData, setBusinessData] = useState<any>(null); // business data
  const [selectedZone, setSelectedZone] = useState<any>(null); //current zone or user input
  const [timezones, setTimezones] = useState<any>([]); //list of zones


  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const timezone = await getTimezones();
      setTimezones(
        timezone.map((item) => ({
          label: `${item.name}, ${item.timezone}`,
          value: item.id,
        }))
      );
      const data = await getBusinessDetail(businessId);
      setBusinessData(data);
      setSelectedZone({
        label: `${data?.timezone_name}, ${data?.timezone}`,
        value: data?.timezone_id,
      });
      setIsLoading(false);
    };
    getData();
  }, [businessId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { ...businessData, timezone_id: selectedZone.value };
    await editBusiness({data: data,}, businessData.id);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          {isLoading ? (
            <Spinner
              color="primary"
              className="position-absolute top-50 start-50"
            />
          ) : (
            <CardBody className="border-bottom pb-5">
              <Row>
                <div>
                  <h3>Time Zone</h3>
                </div>
              </Row>
              <Row>
                <Col sm={7} className="mx-auto ">
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Col className="mx-auto">
                        <Label htmlFor="timezone">Select Timezone</Label>
                        <Select
                          id="timezone"
                          name="timezone"
                          options={timezones}
                          onChange={(selectedOption) =>setSelectedZone(selectedOption)}
                          value={selectedZone}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <div className="d-flex flex-wrap gap-2 justify-content-end mx-auto px-1 mt-4">
                        <Button type="submit" color="success">
                          Save Changes
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          )}
        </Card>
      </Container>
      <div className="my-5"></div>
      <ToastContainer />
    </div>
  );
}

export default Time;
