import React from "react";
import { Col, Label, Row } from "reactstrap";
import ImageGallery from "./image-gallery";

function ImageSelector() {
  return (
    <Row className="mt-3">
      <Col md={7} className="mt-3">
        <Row>
          <Col md={2}>
            <Label htmlFor="images" className="col-form-label">
              Image
            </Label>
          </Col>
          <Col col={10}>
            <ImageGallery />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ImageSelector;
