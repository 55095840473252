import React, { useRef, useState } from "react";
import { Form, Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Modal, ModalBody, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Breadcrumbs from "Components/Common/Breadcrumb";
import DeleteModal from "Components/Common/DeleteModal";
import CustomIcon from "Components/Custom/CustomIcon";

const data = [
  { id: 1, name: "Accounting & Finance" },
  { id: 2, name: "Development" },
  { id: 3, name: "Customer Service" },
  { id: 4, name: "Marketing" },
  { id: 5, name: "Human Resource" },
  { id: 6, name: "Health and Care" },
  { id: 7, name: "Project Management" },
  { id: 8, name: "Automotive Jobs" },
];

function ActivityType() {
  document.title = "Activity Type | Content Pro";

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [type, setType] = useState<any>(null); // Change type state to store ID instead of object

  const inputRef = useRef<HTMLInputElement>(null);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (inputRef.current) {
      const enteredTitle = inputRef.current.value;
      console.log("Entered Title:", enteredTitle);
    } else {
      console.error("Input ref is null");
    }
    setModal(false);
    setType(null);
  };

  return (
    <>
    
    <DeleteModal
      show={deleteModal}
      onDeleteClick={() => {console.log(type); setType(null); setDeleteModal(false)}}
      onCloseClick={() => {setType(null); setDeleteModal(false)}}
    />
    <Modal isOpen={modal} toggle={() => {setType(null); setModal(false)}} centered={true} backdrop={'static'}>
      <div className="modal-content">
        <ModalBody className="px-4 pt-5">
          <button type="button" onClick={() => {setType(null); setModal(false);} } className="btn-close position-absolute end-0 top-0 m-3"></button>
          <div className="mb-4 text-center font-size-20 text-primary">{type ? "Edit" : "Add"} Activity Type</div>
          <Form onSubmit={handleSubmit}>
            <FormGroup className="select2-container">
                <Label className="col-form-label">Title</Label>
                <Input 
                type="text" id="title" name="title" 
                className="form-control"
                innerRef={inputRef} 
                defaultValue={type ? type.name : ""} required/>
            </FormGroup>
            <div className="hstack gap-2 mb-0 justify-content-end">
              <button type="button" className="btn btn-secondary" onClick={() => {setType(null); setModal(false)}}>Close</button>
              <button type="submit" className="btn btn-success">Save</button>
            </div>
          </Form>
        </ModalBody>
      </div>
    </Modal>
     
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Project" breadcrumbItem="Activity Type" />
          <Card className="p-3">
            <CardHeader className="bg-white">
              <div className="text-sm-end text-end mb-2">
                <Button type="button" className="btn btn-success btn-rounded btn-secondary" onClick={() => setModal(true)}>
                  <i className="mdi mdi-plus me-1"></i>Add New Types
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <Row>
                {(data || []).map((item, key) => (
                  <Col lg={4} md={6} key={item.id}>
                  <div className="carousel-item active">
                      <div className="bg-light p-3 d-flex justify-content-between mb-3 rounded">
                      <h5 className="font-size-15 my-auto mb-2">{item.name}</h5>
                          <div className="my-auto text-end ">
                              <UncontrolledDropdown>
                                  <DropdownToggle className="btn btn-soft-primary" type="button" id="dropdownMenuButton11">
                                      <CustomIcon className="bx " iconName="bx-dots-vertical-rounded" id={`action-${item.id}`} text="actions" />
                                  </DropdownToggle>
                                  <DropdownMenu aria-labelledby="dropdownMenuButton11" className="dropdown-menu-end">
                                      <li onClick={() => {setType(item); setModal(true);}}><DropdownItem href="#">Edit</DropdownItem></li>
                                      <li onClick={() => {setType(item); setDeleteModal(true);}}><DropdownItem href="#">Delete</DropdownItem></li>
                                  </DropdownMenu>
                              </UncontrolledDropdown>
                          </div>
                      </div>
                  </div>
                </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default ActivityType;
