import React, { useEffect, useState } from 'react';

const IconComponent = ({className, iconName, text, id, ...props}) => {
  const [hasBeforeClass, setHasBeforeClass] = useState(true);

  useEffect(() => {
    const element = document.querySelector(`#${id}`);
    if (element) {
      const computedStyle = window.getComputedStyle(element, '::before');
      const content = computedStyle.getPropertyValue('content');
      if (content === 'none') {
        element.setAttribute('data-icon-content', `"${text}"`);
        setHasBeforeClass(false);
      }
    }
  }, [id, text]);

  return (
    <div>
      {hasBeforeClass ? (
        <React.Fragment>
          <i className={`${className} ${iconName}`} id={id} {...props}></i>
        </React.Fragment>
      ) : (
        <span id = {id}>{text}</span>
      )}
    </div>
  );
};

export default IconComponent;
