import React, { useState } from 'react'
import { Button, Col, Form, FormGroup, Label } from 'reactstrap';
import Select from "react-select"
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const priorityOptions = [
  { label: "Medium", value: "medium" },
  { label: "High", value: "high" },
  { label: "Low", value: "low" },
]

function Priority() {
    const [priority, setPriority] = useState<any>({ label: "Low", value: "low" }); 
    const [isOptionSelected, setIsOptionSelected] = useState(false); 

    // Function to handle option change
    const handlePriorityChange = (selectedOption: any) => {
        setPriority(selectedOption);
        setIsOptionSelected(true); 
    };

    // Function to handle button click
    const handleButtonClick = () => {
        console.log(priority);
        setIsOptionSelected(false); 
    };

  return (
    <Col md={6} sm={12}>
    <Form className="">
      <FormGroup className="select2-container mb-4" row>
        <Label md={2} className="col-form-label">
          Priority
        </Label>
        <Col md={7} xs={8}>
          <Select
            components={animatedComponents}
            value={priority}
            onChange={handlePriorityChange}
            isClearable={true}
            isSearchable={true}
            name="priority"
            options={priorityOptions}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </Col>
        <Col xs={2}>
          <Button type="button" className="btn btn-soft-success waves-effect waves-light" onClick={handleButtonClick}
          disabled={!isOptionSelected}
          >
          <i className="bx bx-check-circle"/>
          </Button>
        </Col>
      </FormGroup>
    </Form>
  </Col>
  )
}

export default Priority