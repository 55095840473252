export const url = "https://api.stage.contentpro.app/api/";

const request = async (requestConfig: any) => {
  try {
    const response = await fetch(url + requestConfig.url, {
      method: requestConfig.method ? requestConfig.method : "GET",
      headers: requestConfig.headers ? requestConfig.headers : {},
      body: requestConfig.body ? requestConfig.body : null,
    });

    const data = await response.json();
    // console.log("data from backend -- request helper function")
    // console.log(data)

    if (!response.ok) {
      let errorMessage: string;
      switch (response.status) {
        case 500:
          errorMessage = response.statusText || "Internal Server Error"; //server errors
          break;
        case 401:
          errorMessage = (data.error && formatValidationErrors(data.error)) || data.message || "Unauthenticated."; //auth error
          break;
        case 404:
          errorMessage = data.message || response.statusText; //not found
          break;
        case 422:
          errorMessage = formatValidationErrors(data.error); //validation error
          break;
        default:
          errorMessage = data.message || "Something went wrong."; //fallback 
      }
      throw new Error(errorMessage);
    }

    return data;
  } catch (error: any) {
    throw new Error(error.message || "Something went wrong."); //fallback
  }
};

const formatValidationErrors = (error: { [key: string]: string[] }) => {
  let errorMessage = "Validation error:";
  for (const key in error) {
    errorMessage += `\n${error[key].join(", ")}`;
  // console.log(errorMessage)
  }
  return errorMessage;
};

export default request;

