import React, { useEffect } from "react";
import { Container,} from "reactstrap";

const StripePricingTable = () => {
  document.title = "Pricing Tables | Content Pro";
  return (
    <div className="page-content">
      {/* <ToastContainer/> */}
      <Container fluid>
        <div className="mt-4">
        <Tables/>
        </div>
      </Container>
    </div>
  );
};
export default StripePricingTable;

const Tables = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": "prctbl_1PcuzcBqDqlelu6l5c6sVKWE",
    "publishable-key":
      "pk_test_51PQPeBBqDqlelu6lmsRt9XX8Sy4sFE4LRj2a8v4aStBkPaRK52FLw7gEWsMd1uZXS11VQBbtxw9X0xma0gjedQjT006fOSw7HQ",
  });
};

// {/* <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
// <stripe-pricing-table pricing-table-id="prctbl_1PcuzcBqDqlelu6l5c6sVKWE"
// publishable-key="pk_test_51PQPeBBqDqlelu6lmsRt9XX8Sy4sFE4LRj2a8v4aStBkPaRK52FLw7gEWsMd1uZXS11VQBbtxw9X0xma0gjedQjT006fOSw7HQ">
// </stripe-pricing-table> */}