import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GoBackButton from "Components/Common/GoBackButton";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Container, Row, Spinner, Table } from "reactstrap";
import convertDateFormat from "Components/Custom/DateFormat";
import { LinkWithQuery } from "helpers/link_helper";
import { getProjectDetail } from "helpers/API Calls/Space Settings/Projects/projects";

function ProjectDetails() {
  document.title = "Project Details | Content Pro";

  const { id } = useParams();

  const [project, setProject] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const data = await getProjectDetail(id);
      setProject(data);
      setIsLoading(false);
    };
    getData();
  }, [id]);

  return (
    <div className="page-content">
      <ToastContainer />
      <Container fluid>
        <GoBackButton to="/projects"/>
        {isLoading ? (
          <Spinner color="primary" className="position-absolute top-50 start-50"/>
        ) : project ? (
          <>
           <Row>

            <Col xl={3}>

            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                        {project?.thumbnail ? 
                        <img src={project?.thumbnail} alt="" className="avatar-sm rounded-circle"/>
                        :
                        <div className="avatar-sm">
                            <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                            {project.project_name?.charAt(0)}
                            </div>
                        </div>
                        }
                    </div>
                  <div className="ms-3 mb-0">
                  <h5>{project.project_name} - ({project.project_key})</h5>
                  <p className="m-0 p-0 text-muted">{project?.project_type || 'Type Not Specified'}</p>
                  </div>
                </div>
              </CardBody>
            </Card>

            </Col>

            <Col xl={9}>
              <Card>
                <CardBody>
                    <h5 className="fw-semibold">Project Details</h5>
                
                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                            <tr>
                                <th scope="col">Start At</th>
                                <td>{convertDateFormat(project.started_at) || 'Not Specified'}</td>
                            </tr>
                            <tr>
                                <th scope="row">Started By</th>
                                <td>{project?.started_by_user || 'User Not Specified'}</td>
                            </tr>
                            <tr>
                                <th scope="col">Due At</th>
                                <td>{convertDateFormat(project.due_date) || 'Not Specified'}</td>
                            </tr>
                            <tr>
                                <th scope="col">Created At</th>
                                <td>{convertDateFormat(project.created_at) || 'Not Specified'}</td>
                            </tr>
                            <tr>
                                <th scope="col">Updated At</th>
                                <td>{convertDateFormat(project.updated_at) || 'Not Specified'}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </CardBody>
                </Card>   
            </Col>

            </Row>
          </>
        ) : (
          <Row>
            <div className="text-center text-danger">Sorry! No data found.</div>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default ProjectDetails;
