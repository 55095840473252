import { toast } from "react-toastify";
import request from "../../request_helper";


export const getPlans = async () => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "stripe/plans",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        // toast.success(response.message, { autoClose: 2000 });
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const editSubscription = async (date, id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `business/access/${id}`,
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(date)
        };
        const response = await request(args); 
        toast.success(response.message, { autoClose: 2000 });
        const data = await response;
        return data
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}