//import Images
import companies01 from "assets/images/product/img-6.png";
import companies02 from "assets/images/product/img-2.png";
import companies03 from "assets/images/product/img-3.png";
import companies04 from "assets/images/product/img-4.png";
// import companies05 from "assets/images/product/img-5.png";
// import companies06 from "assets/images/companies/img-6.png";

const DATA = [
  {
    id: 1,
    platform: "facebook",
    connectionStatus: "connected",
    accountType: 'Facebook Page', 
    connectedAccount: "Steven Carlson", 
    connectedAccounts: [
      { id: 1, name: "Steven Carlson" },
      { id: 2, name: "John Doe" }
    ],
    details: "Many brands and content creators alike use Facebook as a key social media marketing platform. Distributing content and engaging with an audience on networks seems to be an easy task at first glance, but as your brand grows, it becomes more challenging to come up with new, creative post ideas regularly.",
    characterCount: 100,
    tags: "@abc@def",
    webLink: "https://stage.contentpro.app/facebook-post/623",
    media: companies01,
    status: "Cancelled",
    readyStatus: "ready",
    modifiedAt: "08 Sept, 2019",
  },
  {
    id: 11,
    platform: "facebook",
    connectionStatus: "connected",
    accountType: 'Facebook Profile', 
    connectedAccount: "Steven Carlson", 
    connectedAccounts: [
      { id: 1, name: "Steven Carlson" },
      { id: 2, name: "John Doe" }
    ],
    details: "Many brands and content creators alike use Facebook as a key social media marketing platform. Distributing content and engaging with an audience on networks seems to be an easy task at first glance, but as your brand grows, it becomes more challenging to come up with new, creative post ideas regularly.",
    characterCount: 100,
    tags: "@abc@def",
    webLink: "https://stage.contentpro.app/facebook-post/623",
    media: companies01,
    status: "Cancelled",
    readyStatus: "ready",
    modifiedAt: "08 Sept, 2019",
  },
  {
    id: 2,
    platform: "instagram",
    connectionStatus: "connected",
    accountType: 'Facebook Profile', 
    connectedAccount: "Steven Carlson", 
    details: "To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is. The European languages are members of the same family. Their separate existence is a myth. For science, music, sport, etc,",
    characterCount: 90,
    tags: "",
    webLink: "https://stage.contentpro.app/facebook-post/623",
    media: companies02,
    status: "Scheduled",
    readyStatus: "ready",
    modifiedAt: "22 Oct, 2019", 
  },
  {
    id: 4,
    platform: "linkedIn",
    connectionStatus: "connected",
    accountType: 'LinkedIn Profile', 
    connectedAccount: "Steven Carlson", 
    details: "To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is. The European languages are members of the same family. Their separate existence is a myth. For science, music, sport, etc,",
    characterCount: 220,
    tags: "@abc@def",
    webLink: "https://www.quantum.com/",
    media: companies03,
    status: "Published",
    readyStatus: "ready",
    modifiedAt: "22 Oct, 2019", 
  },
  {
    id: 5,
    platform: "twitter",
    connectionStatus: "connected",
    accountType: 'Twitter Profile', 
    connectedAccount: "Steven Carlson", 
    details: "To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is.",
    characterCount: 30,
    tags: "@abc@def",
    webLink: "https://www.quantum.com/",
    media: companies04,
    status: "Draft",
    readyStatus: "inProgress",
    modifiedAt: "22 Oct, 2019", 
  },
  {
    id: 6,
    platform: "website",
    connectionStatus: "connected",
    accountType: 'Website', 
    connectedAccount: "Steven Carlson", 
    details: "Nulla ac hendrerit tortor, maximus hendrerit nulla. Integer vulputate et mauris vel pellentesque. Sed feugiat lacus ut nunc venenatis, sit amet ullamcorper turpis vehicula. Cras luctus sapien a ligula porta, aliquet tristique ipsum faucibus. Suspendisse posuere suscipit turpis, sit amet vehicula magna varius ac. Nullam nec elementum nulla. In a luctus metus. Curabitur laoreet accumsan dui, a sodales ex tempus at. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Pellentesque pharetra sapien nec leo volutpat, vitae accumsan nibh volutpat. Etiam ullamcorper quis felis vel euismod. Praesent commodo eget enim finibus posuere. Sed pulvinar eget lacus a ultrices. Morbi sollicitudin ante et aliquet tincidunt. Sed fermentum quis ante eu sodales. Integer est lacus, sodales eget varius ac, interdum id quam. Donec dictum rhoncus neque eu euismod. Aliquam aliquam elementum scelerisque. Aenean eu luctus arcu, quis porta purus.",
    characterCount: 250,
    category: "Entertainment",
    websiteStatus: "Draft", 
    author: "Daniel Candles",
    media: "",
    status: "Draft",
    readyStatus: "ready",
    modifiedAt: "22 Oct, 2019", 
  },
];

export default DATA;
