import React from 'react'

function SocialIcon({platform}) {

    let social =  { title: "", bgColor: "", iconClass: ""}; ;
    switch(platform){
        case "facebook": 
            social =  { title: "Facebook", bgColor: "bg-primary", iconClass: "mdi-facebook"}; 
            break; 
        case "instagram": 
            social =  { title: "Instagram", bgColor: "bg-pink", iconClass: "mdi-instagram"};            ; 
            break; 
        case "website": 
            social =  { title: "Wordpress", bgColor: "bg-warning", iconClass: "mdi-web"}; 
            break; 
        case "twitter": 
            social =  { title: "Twitter", bgColor: "bg-info", iconClass: "mdi-twitter",};            ; 
            break; 
        case "linkedIn": 
            social =  { title: "LinkedIn", bgColor: "bg-primary", iconClass: "mdi-linkedin"}; 
            break; 
        default: 
            social =  { title: "", bgColor: "bg-danger", iconClass: "mdi-error"}; 
            break;
    } 

  return (
    <div className="social-source text-center d-flex">
        <div className="avatar-xs mx-auto">
            <span className={"avatar-title rounded-circle " + social.bgColor + " font-size-16"} >
            <i className={"mdi " +  social.iconClass + " text-white"}></i>
            </span>
        </div>
        <h5 className="font-size-15 my-auto ps-2">
            {/* {platform.charAt(0).toUpperCase() + platform.slice(1)} */}
            {social.title}
        </h5>
    </div>
  )
}

export default SocialIcon