import React from "react";
import withRouter from "Components/Common/withRouter";
import { isEmpty } from "lodash";
import { Col, Container, Row } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "Components/Common/Breadcrumb";
import {ACTIVITIES} from './activity-list'; 

import ProjectDetail from "./Details/PostDetail";


const ProjectsOverview = (props: any) => {

  //meta title
  document.title = "Activity Overview | Content Pro";

  
  const params = props.router.params
  let projectDetail;
  if (params && params.id) {
    projectDetail = (ACTIVITIES.find(item => item.id === Number(params.id)))
  } else {
    projectDetail = (ACTIVITIES.find(item => item.id === Number(params.id)))
  }

  console.log('projectDetail',ACTIVITIES.find(item => item.id === Number(params.id)))

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Activity" breadcrumbItem="Details" isGoBackButton = {true} project={true} to="/posts-list"/>

          {!isEmpty(projectDetail) && (
            <>
              <Row>
                <Col lg="12">
                  <ProjectDetail project={projectDetail} comments={projectDetail.comments}/>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProjectsOverview);
