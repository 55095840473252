import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import ImageHolder from "./ImageHolder"; // Adjust the import path accordingly

function ImageGallery() {
    const [images, setImages] = useState<(string | null)[]>([null]);

    const handleImageUpload = (index: number, image: string | null) => {
        const newImages = [...images];
        newImages[index] = image;

        // If the last item is not null, add a new empty slot
        if (newImages[newImages.length - 1] !== null) {
            newImages.push(null);
        }

        setImages(newImages);
    };

    const handleDeleteImage = (index: number) => {
        const newImages = images.filter((_, i) => i !== index);
        setImages(newImages);
    };

    return (
        <Col style={{ maxWidth: "fit-content" }}>
            <Row className="flex-nowrap justify-content-start">
                {images.map((image, index) => (
                    <ImageHolder
                        key={index}
                        tag={`image-${index}`}
                        selectedImage={image}
                        onImageUpload={(img) => handleImageUpload(index, img)}
                        onDeleteImage={() => handleDeleteImage(index)}
                    />
                ))}
            </Row>
        </Col>
    );
}

export default ImageGallery;
