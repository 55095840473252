import React from "react";
import { Card, CardBody, Table } from "reactstrap";
import { LinkWithQuery } from "../../../../helpers/link_helper";

export const data = [
  {
    date: "10 Nov",
    title: "Posted",
    version: "5",
    name: "Charles Brown",
    content:
      "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds in these matters to this principle of selection: he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains.",
  },
  {
    date: "08 Nov",
    title: "If several languages coalesce, the grammar of the resulting...",
    version: "4",
    name: "Charles Brown",
    content:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?",
  },
  {
    date: "02 Nov",
    title: "Create",
    version: "3",
    name: "Charles Brown",
    content:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, se",
  },
  {
    date: "24 Oct",
    title: "In enim justo, rhoncus ut, imperdiet a venenatis vitae",
    version: "2",
    name: "Charles Brown",
    content:
      "The standard Lorem Ipsum passage, used since the 1500 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    date: "21 Oct",
    title: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut",
    version: "1",
    name: "Charles Brown",
    content:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
];

const VersioningFeed: React.FC = () => {

  return (
    <React.Fragment>
      <Card>
        <CardBody className="table-responsive mt-2 display-grid overflow-auto">
          <Table className="mb-0 project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline table table-hoverS">
            <thead className="table-light">
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Version</th>
                <th>Log Date</th>
                <th>Updated By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => 
                <tr key={item.version} className="ps-0">
                  <td>{index + 1}</td>
                  <td>{item.title}</td>
                  <td>Version-{item.version}</td>
                  <td>{item.date}</td>
                  <td>{item.name}</td>
                  <td> 
                    <LinkWithQuery to={item.version} project={true}>
                    <button className="btn btn-sm btn-soft-info">
                      <i className="mdi mdi-eye-outline" id="view-tooltip"></i>
                    </button>
                    </LinkWithQuery>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>           
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default VersioningFeed;
