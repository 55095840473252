import React from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "Components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { userForgetPassword } from "../../slices/thunk";
// import images
import logo from "assets/images/logo-sm.png";
import logoDark from "assets/images/logo-large.png";
import { createSelector } from 'reselect';

const ForgetPasswordPage = (props) => {

    //meta title
    document.title = "Forget Password | Content Pro";

    const dispatch = useDispatch<any>();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
        }),
        onSubmit: (values) => {
            dispatch(userForgetPassword(values, props.history));
        }
    });

   const selectProperties = createSelector(
        (state: any) => state.ForgetPassword,
        (forgetPassword) => ({
            forgetError: forgetPassword.forgetError,
            forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
        })
    );

    const { forgetError, forgetSuccessMsg } = useSelector(selectProperties);

    return (
        <React.Fragment>
            <div className="home-btn-auth d-none d-sm-block">
                <Link to="/" className="text-dark d-flex">
                <div className="navbar-brand-box">
                    <Link to="/" className="logo logo-dark">
                    <span className="logo-sm">
                        <img src={logo} alt="" height="40" />
                    </span>
                    <span className="logo-lg">
                        <img src={logoDark} alt="" height="40" />
                    </span>
                    </Link>
                </div>
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                            <div className="py-3">
                            <h2 className="px-4 pt-4 text-dark"><strong>Reset Password</strong></h2>
                            </div>
                                <CardBody className="pt-0">
                                    <div className="p-2">
                                        {forgetError && forgetError ? (
                                            <Alert color="danger" style={{ marginTop: "13px" }}>
                                                {forgetError}
                                            </Alert>
                                        ) : null}
                                        {forgetSuccessMsg ? (
                                            <Alert color="success" style={{ marginTop: "13px" }}>
                                                {forgetSuccessMsg}
                                            </Alert>
                                        ) : null}

                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <div className="mb-3">
                                                <Label className="form-label">Email</Label>
                                                <Input
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.email || ""}
                                                    invalid={
                                                        validation.touched.email && validation.errors.email ? true : false
                                                    }
                                                />
                                                {validation.touched.email && validation.errors.email ? (
                                                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                ) : null}
                                            </div>
                                            <Row className="mb-3">
                                                <Col className="text-end d-grid">
                                                    <button
                                                        className="btn btn-success w-md "
                                                        type="submit"
                                                    >
                                                        Reset
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <div className="text-center mt-5">
                                            <p><strong>Go back to <Link to="/login" className="font-weight-medium text-success ms-1">Login</Link></strong></p>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ForgetPasswordPage);