import { combineReducers } from "redux";

import ProfileReducer from "./auth/profile/reducer";
import ForgotPasswordReducer from "./auth/forgetpwd/reducer";

import businessesReducer from './businesses/reducer'; 
import billingReducer from './billing/reducer'; 
import rolesReducer from './roles/reducer'; 
import usersReducer from './users/reducer'; 
import teamsReducer from './teams/reducer'
import currentUserReducer from './current user/reducer';
import AccountReducer from "./auth/register/reducer";
import LoginReducer from "./auth/login/reducer";
import ProjectReducer from "./projects/reducer"

const rootReducer = combineReducers({
    businesses: businessesReducer,
    billing: billingReducer,
    roles: rolesReducer,
    users: usersReducer, 
    teams: teamsReducer,
    user: currentUserReducer, //current user 
    Account: AccountReducer, //register
    Login: LoginReducer,
    project: ProjectReducer, 
    
    //extra
    Profile: ProfileReducer,
    ForgetPassword: ForgotPasswordReducer,
});

export default rootReducer;