import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { getSubscription } from "helpers/API Calls/Space Settings/Subscriptions/Subscription";
import { ToastContainer, toast } from "react-toastify";
import convertDateFormat from "Components/Custom/DateFormat";


function Payments() {
  document.title = "Payments | Content Pro";
  
  const navigate = useNavigate(); 
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subscriptionData, setSubscriptionData] = useState<any>(null);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const data = await getSubscription(businessId);
      console.log(data)
      if(data?.stripe_status === 'canceled'){
        navigate(`/pricing-tables?businessId=${businessId}`)
      }else if(data?.stripe_status === 'past_due'){
        toast.error('Payment PendingPayment due date has passed. Please pay your bills as soon as possible.', { autoClose: 2000 });
      }
      setSubscriptionData(data);
      setIsLoading(false);
    };
    getData();
  }, [businessId, navigate]);

  return (
   <>
    <div className="page-content">
    <ToastContainer/>
    <Container fluid>
      {isLoading ? (
        <Spinner
          color="primary"
          className="position-absolute top-50 start-50"
        />
      ) : (
        subscriptionData ?
        <>
        <Row>

          <Col xl={3}>

            <Card>
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="avatar-xs">
                    <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded">
                      {subscriptionData.user_name?.charAt(0)}
                    </div>
                  </div>
                  <h5 className="ms-3 mb-0">{subscriptionData.user_name}</h5>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <h5 className="fw-semibold">Subscription Information</h5>

                  <ul className="list-unstyled mt-4">
                    <li className="mt-3">
                        <div className="d-flex">
                            <i className="bx bx-list-ol text-success fs-4"></i>
                            <div className="ms-3">
                                <h6 className="fs-14 mb-2">{subscriptionData.product_name}</h6>
                                <p className="text-muted mb-0">{subscriptionData.product_description || "No description available"}</p>
                            </div>
                        </div>
                    </li>
                    <li className="mt-3">
                        <div className="d-flex">
                            <i className="bx bx-dollar text-success fs-4"></i>
                            <div className="ms-3">
                                <h6 className="fs-14 mb-2">Amount</h6>
                                <p className="text-muted fs-14 mb-0">${subscriptionData.amount || 0}{subscriptionData.currency?.toUpperCase() || "USD"}/{subscriptionData.interval || "month"}</p>
                            </div>
                        </div>
                    </li>
                    <li className="mt-3">
                        <div className="d-flex">
                            <i className="bx bx-time-five text-success fs-4"></i>
                            <div className="ms-3">
                                <h6 className="fs-14 mb-2">Interval</h6>
                                <p className="text-muted fs-14 mb-0">{subscriptionData.interval.charAt(0).toUpperCase() + subscriptionData.interval.slice(1)}</p>
                            </div>
                        </div>
                    </li>
                    <li className="mt-3">
                        <div className="d-flex">
                            <i className="bx bx-pulse text-success fs-4"></i>
                            <div className="ms-3">
                                <h6 className="fs-14 mb-2">Status</h6>
                                <p className="text-muted fs-14 text-break mb-0">{subscriptionData.stripe_status.charAt(0).toUpperCase() + subscriptionData.stripe_status.slice(1)}</p>
                            </div>
                        </div>
                    </li>
                  </ul>
                  {/* <div className="mt-4">
                      <Link to="#" className="btn btn-soft-primary btn-hover w-100 rounded"><i className="mdi mdi-eye"></i> View Profile</Link>
                  </div> */}
              </CardBody>
            </Card>
  
          </Col>

          <Col xl={9}>
            <Card>
              <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="fw-semibold">Payment Details</h5>
                  <a href={subscriptionData.billing_url} className="btn btn-soft-success btn-success" target="blank">Payment</a>
              </div>

                  <div className="table-responsive">
                      <table className="table">
                          <tbody>
                              <tr>
                                  <th scope="col">Plan</th>
                                  <td headers="jobTitleHeader">{subscriptionData.product_name}</td>
                              </tr>
                             {subscriptionData.trial_ends_at && <tr>
                                  <th scope="row">Trial Ends At</th>
                                  <td>{convertDateFormat(subscriptionData.trial_ends_at)}</td>
                              </tr>}
                              <tr>
                                  <th scope="row">Subscription Created At</th>
                                  <td>{convertDateFormat(subscriptionData.created_at) || 'No Plan'}</td>
                              </tr>
                              <tr>
                                  <th scope="row">Last Updated At</th>
                                  <td>{convertDateFormat(subscriptionData.updated_at) || 'No Plan'}</td>
                              </tr>
                              {subscriptionData.due_at && <tr>
                                  <th scope="row">Due Date</th>
                                  <td>{convertDateFormat(subscriptionData.due_at)}</td>
                              </tr>}
                          </tbody>
                      </table>
                  </div>
              </CardBody>
            </Card>   
          </Col>

        </Row>
        </>
        :
        <Row><div className="text-center text-danger">Sorry! No data found.</div></Row>
      )}
    </Container>
  </div>
   </>
  );
}

export default Payments;
