import React from "react";
import { Button, Card, CardBody, Container, Table } from "reactstrap";
import Breadcrumbs from "Components/Common/Breadcrumb";
import CustomIcon from "Components/Custom/CustomIcon";
import { LinkWithQuery } from "helpers/link_helper";

const recentFile = [
  {
    id: 1,
    icon: "mdi mdi-file-document font-size-24 align-middle text-primary me-2",
    file: "index.html",
    type: "media", 
    date: "12-10-2020",
    name: "Huda Fatima", 
    size: "09",
  },
  {
    id: 2,
    icon: "mdi mdi-folder-zip font-size-24 align-middle text-warning me-2",
    file: "Project-A.zip",
    type: "text", 
    date: "11-10-2020",
    name: "Huda Fatima", 
    size: "115",
  },
  {
    id: 3,
    icon: "mdi mdi-image font-size-24 align-middle text-muted me-2",
    file: "Img-1.jpeg",
    type: "media", 
    date: "11-10-2020",
    name: "Huda Fatima", 
    size: "86",
  },
  {
    id: 4,
    icon: "mdi mdi-text-box font-size-24 align-middle text-muted me-2",
    file: "update list.txt",
    type: "text", 
    date: "10-10-2020",
    name: "Huda Fatima", 
    size: "08",
  },
  {
    id: 5,
    icon: "mdi mdi-folder font-size-24 align-middle text-warning me-2",
    file: "Project B",
    type: "text", 
    date: "10-10-2020",
    name: "Huda Fatima", 
    size: "72",
  },
  {
    id: 6,
    icon: "mdi mdi-text-box font-size-24 align-middle text-muted me-2",
    file: "Changes list.txt",
    type: "text", 
    date: "09-10-2020",
    name: "Huda Fatima", 
    size: "07",
  },
  {
    id: 7,
    icon: "mdi mdi-image font-size-24 align-middle text-success me-2",
    file: "Img-2.png",
    type: "media", 
    date: "09-10-2020",
    name: "Huda Fatima", 
    size: "31",
  },
  {
    id: 8,
    icon: "mdi mdi-folder font-size-24 align-middle text-warning me-2",
    file: "Project C",
    type: "text", 
    date: "09-10-2020",
    name: "Huda Fatima", 
    size: "20",
  },
  {
    id: 9,
    icon: "bx bxs-file font-size-24 align-middle text-primary me-2",
    file: "starter-page.html",
    type: "text", 
    date: "08-10-2020",
    name: "Huda Fatima", 
    size: "11",
  },
];

function Files() {
  document.title = "Project Files | Content Pro";

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Project" breadcrumbItem="Files" />
          <Card>
            <CardBody className="table-responsive mt-2 display-grid overflow-auto">
                <div className="text-end mb-4">
                  <LinkWithQuery to="/add-files" className="btn btn-success btn-rounded btn-secondary" project={true}>
                    <i className="mdi mdi-plus me-1"></i>Add New Files
                  </LinkWithQuery>
                </div>
              <Table className="mb-0 project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline table table-hoverS">
                
                <thead className="table-light">
                  <tr>
                    <th>#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Date modified</th>
                    <th scope="col">Modified By</th>
                    <th scope="col">Type</th>
                    <th scope="col">Size</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {(recentFile || [])?.map(
                    (item, idx: number) => (
                      <tr key={item.id}>
                        <td>{idx + 1}</td>
                        <td> <span className="text-dark fw-medium">{item.file}</span></td>
                        <td>{item.date}</td>
                        <td>{item.name}</td>
                        <td>{item.type.charAt(0).toUpperCase()+item.type.slice(1)}</td>
                        <td>{item.size} KB</td>
                        <td className="d-flex">
                          <LinkWithQuery project={true} to="" className="btn btn-sm btn-soft-primary">
                              <CustomIcon className="mdi " iconName= "mdi-download" id={`view-${item.id}`} text="View"/>
                          </LinkWithQuery>
                          <LinkWithQuery project={true} to="" className="btn btn-sm btn-soft-info mx-2 ">
                            <CustomIcon className="mdi " iconName= "mdi-pencil" id={`edit-${item.id}`} text="Edit"/>
                          </LinkWithQuery> 
                          <Button className="btn btn-sm btn-soft-danger " onClick={() => {}}>
                            <CustomIcon className="mdi " iconName= "mdi-trash-can" id={`delete-${item.id}`} text="Delete"/>
                          </Button>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
      </Container>
    </div>
  );
}

export default Files;
