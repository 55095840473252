import React, { useState } from "react";
import { get }  from "lodash";
import { Card, Button, CardBody, Modal, ModalBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, ModalHeader, Form, Label, Input, UncontrolledTooltip, } from "reactstrap";
// import img1 from "../../../assets/images/companies/img-1.png";
import { LinkWithQuery } from "../../../../helpers/link_helper";

import DOMPurify from 'dompurify';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import AuthorNotes from "./AuthorNotes";
import ActivityFeed from "./ActivityFeed";
// import ImageHolder from "./ImageHolder";
import Category from "./Category";
import Priority from "./Priority";
import HashTags from "./HashTags";
import CustomIcon from "../../../../Components/Custom/CustomIcon";
import ImageGallery from "./ImageGallery";


const ProjectDetail = ({ project , comments}) => {
  const [modal, setModal] = useState<boolean>(false);
  const [action, setAction] =useState<any>(null); 
  const [showDetail, setShowDetails] = useState<boolean>(false);

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }
  
  const closeBtn = (
    <Button className="btn btn-soft-danger waves-effect waves-light" onClick={() => setModal(false)} type="button">
      <i className="mdi mdi-close-thick"></i>
    </Button>
  );

  return (
    <>

    <Modal isOpen={modal} toggle={() => setModal(false)} centered={true} size={showDetail ? "lg" : "md"} scrollable={showDetail}>
      <div className="modal-content">
        {(action === "Archive" && !showDetail) && 
          <ModalBody className="px-4 py-5 text-center">
            <button type="button" onClick={() => setModal(false)} className="btn-close position-absolute end-0 top-0 m-3"></button>
            <div className="mb-4 mx-auto">
              <div className="text-primary font-size-20">
                Archive Activity
              </div>
            </div>
            <p className="text-muted font-size-16 mb-4">Are you sure you want to archive the Activity.</p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
              <button type="button" className="btn btn-danger" onClick={() => setModal(false)}>Archive</button>
            </div>
          </ModalBody>
        }
        {(action !== "Archive" && !showDetail) && 
        <>
        <ModalHeader toggle={() => setModal(false)} tag="h4">
          {(action === "Unarchive") && "Unarchive Activity"}
          {(action === "Status") && "Action Status"}
        </ModalHeader>
        <ModalBody className="px-4">
          <Form onSubmit={(e) => {e.preventDefault();}}>
            <Row>
              <Col xs={12}>

                <div className="mb-4">
                  <Label>Select Action Status</Label>
                  <Input
                    name="title"
                    type="select"
                    className="form-select"
                  >
                    <option>Idea in motion</option>
                    <option>Ready to Add images</option>
                    <option>Ready to Publish</option>
                  </Input>
                </div>
              </Col>
            </Row>
            <div className="hstack gap-2 justify-content-end mb-0">
              <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
              <button type="submit" className="btn btn-success" onClick={() => setModal(false)}>Save</button>
            </div>
          </Form>
        </ModalBody>
        </>
        }
        {showDetail && <>
          <ModalHeader toggle={() => setModal(false)} tag="h4" close={closeBtn}>
             ChatGPT as Visa Application Assistant for Any Country in the World
          </ModalHeader>
          <ModalBody className="pb-4">
            <h5 className="font-size-21 pb-1">Description:</h5>
            <div
              className="preview"
              dangerouslySetInnerHTML={createMarkup(get(project, "projectDetails.description"))}>
            </div>
          </ModalBody>
        </>}
      </div>
    </Modal>
 
    <Card>
      <CardBody>
        <Row>
          <Col sm={4}>
            <div className="mb-2">
              Created by <b>Charles Brown</b> on <b>08 Sept, 2019</b>.
            </div>
          </Col>
          <Col sm={4} className="">
            <div className="mb-2 text-md-center text-sm-start">
              Status: <b className="text-success">Idea in Motion</b>
            </div>
          </Col>
          <Col sm={4} className="text-end">
            <UncontrolledDropdown className="dropdown d-inline-block me-1">
              <DropdownToggle type="menu" className="btn btn-success" id="dropdownMenuButton1">
                <i className="mdi mdi-dots-vertical"></i>
              </DropdownToggle>
              <DropdownMenu>
                <li><DropdownItem><div onClick={() => {setAction("Status"); setShowDetails(false); setModal(true)}}>Action Status</div></DropdownItem></li>
                <li><LinkWithQuery project={true} to="publish"><DropdownItem>Publish</DropdownItem></LinkWithQuery></li>
                <li><DropdownItem><div onClick={() => {setAction("Archive"); setShowDetails(false); setModal(true)}}>Archive</div></DropdownItem></li>
                <li><LinkWithQuery project = {true} to="logs" ><DropdownItem>Logs</DropdownItem></LinkWithQuery></li>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>

        {/* title */}
        <Row>
          <div className="">
            <h3 className="text-truncate fw-bold ">ChatGPT as Visa Application Assistant for Any Country in the World</h3>
          </div>
        </Row>

        {/* description */}
        <Row>
          <CardBody>
            <blockquote className="blockquote font-size-14 mb-0" >
              <div className="overflow-hidden mb-3" style={{ maxHeight: '100px' }}>
                <div
                  className="preview"
                  dangerouslySetInnerHTML={createMarkup(get(project, "projectDetails.description"))}>
                </div>
              </div>
              <footer className="blockquote-footer mt-0 font-size-12 d-flex">
                <div>
                  Updated by <b>Charles Brown</b>
                  <cite title="Source Title"> on 08 Dec, 2019.</cite>
                </div>
                <ul className="list-unstyled hstack gap-1 mb-0 ms-auto">

                  <li data-bs-toggle="tooltip" data-bs-placement="top" title="View" onClick={() => {setShowDetails(true); setAction(null); setModal(true); }}>
                    <button className="btn btn-soft-info" >
                      {/* <i className="mdi mdi-eye-outline" id="view-tooltip"></i> */}
                      <CustomIcon className="mdi " iconName= "mdi-eye-outline" id="view-tooltip" text="View"/>
                    </button>
                  </li>
                  <UncontrolledTooltip placement="top" target="view-tooltip">View</UncontrolledTooltip>

                  <li data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                    <LinkWithQuery project={true} to="edit" className="btn btn-soft-primary">
                      <i className="mdi mdi-pencil-outline" id="edit-tooltip" />
                    </LinkWithQuery>
                  </li>
                  <UncontrolledTooltip placement="top" target="edit-tooltip">Edit</UncontrolledTooltip>

                </ul>
              </footer>
            </blockquote>
          </CardBody>
          <div className="card-footer bg-transparent border-top text-muted">
            <Row>
              <Col>
                <div className="mb-4 d-flex">
                  <h5 className="font-size-14 me-2">
                    Due:
                  </h5>
                  <p className="text-muted mb-0">12 Oct, 2019</p>
                </div>
              </Col>
              <Col>
                <div className="mb-4 d-flex">
                  <h5 className="font-size-14 me-2">
                    Team:
                  </h5>
                  <p className="mb-0 btn-link waves-effect text-success">TVP-Demo</p>
                </div>
              </Col>
            </Row>
          </div>
        </Row>

        <Row>
          <Category/>
          <Priority/>
          <HashTags/>
        </Row>
      
        <Row className="mb-4" style={{ overflowX: "auto" }}>
          <h5 className="fw-bold font-size-21 pb-1 mt-4">Images: </h5>
          <ImageGallery />
        </Row>


        <Row>
          <AuthorNotes comments={comments}/>
        </Row>

        <Row>
          <ActivityFeed/>
        </Row>        

      </CardBody>
    </Card>
    </>
  );
};


export default ProjectDetail;
