import React, { useEffect, useMemo, useState } from "react";
import { Card, Container, UncontrolledTooltip} from "reactstrap";
import CustomIcon from "Components/Custom/CustomIcon";
import { LinkWithQuery } from "helpers/link_helper";
import DeleteModal from "Components/Custom/DeleteModalWithMsg";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import PaginatedTable from "Components/Custom/Table and Cards/PaginatedTable";
import { getTeams } from "slices/thunk";
import convertDateFormat from "Components/Custom/DateFormat";
import { useLocation } from "react-router-dom";
import { deleteTeam } from "helpers/API Calls/Space Settings/Users Settings/Teams/Teams";
import { Link } from "react-router-dom";


function Teams() {
  document.title = "Teams | Content Pro";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");

    // redux code
    const selectProperties = createSelector(
      (state: any) => state.teams,
      (teams) => ({
        teams: teams.teamList,
        loading: teams.loading,
        pagination: teams.pagination
      })
    );
    const { teams, loading, pagination } = useSelector(selectProperties);
  
    // local state mng for getting business data
    const [teamsData, setTeamsData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
  
    useEffect(() => {
      setIsLoading(loading);
      setTeamsData(teams);
      // console.log('data from business component local state', businesses)
    }, [teams, loading]);

    
  // for deletion 
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] =useState<any>();
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const handleDelete = async () => {
    if (deleteItem) {
      const response = await deleteTeam(deleteItem.id);
      setDeleteItem(null);
      setDeleteModal(false);
      setIsDeleted(response); 
    }
  };

  useEffect(() => {
    if (isDeleted) {
      setIsDeleted(false);
    }
  }, [isDeleted]);

  const head = useMemo(() => [
    {id: 1, name: "#", isSortable: true, key: "id"},
    {id: 2, name: "Team", isSortable: true, key: "team_name"},
    {id: 3, name: "Members Count"},
    {id: 122, name: "Members"},
    {id: 31, name: "Projects Count"},
    {id: 12, name: "Projects"},
    {id: 4, name: "Created At", isSortable: true, key: "created_at"},
    {id: 5, name: "Updated At", isSortable: true, key: "updated_at"},
    {id: 8, name: "Actions"}
  ], []);

  return (
    <>
      <DeleteModal
      show={deleteModal}
      onDeleteClick={handleDelete}
      onCloseClick={() => {setDeleteModal(false); setDeleteItem({});}}
      message={`Team ${deleteItem?.team_name}`}
      />
    
    <div className="page-content">
      <Container fluid>
        <Card className="">
          <PaginatedTable
            tableHead={head}
            onGetData = {getTeams}
            pagination = {pagination}
            isLoading = {isLoading}
            buttonText = "Add New Team" 
            isAddButton = {true} 
            query = {`business_id=${businessId}`}
            to= {`create?businessId=${businessId}`}
            isDeleted = {isDeleted}
            title = 'Teams List'
          >
            {teamsData?.length !== 0 && teamsData?.map((item : any, index : any) => (
              <tr key={item.id}>
                <th scope="row">{item.id}</th>
                <td className="d-flex">
                    <h5 className='font-size-14 mb-1'>
                      <LinkWithQuery to={`${item.id}`} className='text-dark'>{item.team_name}</LinkWithQuery>
                    </h5>
                </td>

                <td>{item.members.length}</td>
                <td  className="avatar-group">
                  <div className="d-flex">
                  {item.members && item.members.length !== 0 &&
                  item.members.map(member => 
                    <div className="avatar-group-item flex-nowrap">
                  <UncontrolledTooltip
                    placement="right"
                    target={`UncontrolledTooltipExample-${member.id}`}
                  >
                    {member.name}
                  </UncontrolledTooltip>
                  <Link to="#" className="d-inline-block" id={`UncontrolledTooltipExample-${member.id}`} >
                    <div className="avatar-xs">
                      <span className={`avatar-title rounded-circle bg-primary-subtle text-secondary font-size-12`}>{member.name.charAt(0).toUpperCase()}</span>
                    </div>
                  </Link>
                  </div>
                  )} 
                  </div>
                </td>

                <td>{item.projects.length}</td>
                <td>
                  {item.projects && item.projects.length !== 0 &&
                  item.projects.map(project => 
                    <span className="badge badge-soft-info font-size-11 m-1" key={project.id}>{project.project_name}</span>
                  )} 
                </td>

                <td>{convertDateFormat(item.created_at)}</td>
                <td>{convertDateFormat(item.updated_at)}</td>
                <td>
                  <LinkWithQuery to={`${item.id}`} className="btn btn-sm btn-soft-primary">
                      <CustomIcon className="mdi " iconName= "mdi-eye-outline" id={`view-${item.id}`} text="View"/>
                  </LinkWithQuery>
                  <LinkWithQuery to={`${item.id}/edit`} className="btn btn-sm btn-soft-info mx-2" title="Edit Team">
                    <CustomIcon className="mdi " iconName= "mdi-pencil" id={`edit-${item.id}`} text="Edit"/>
                  </LinkWithQuery>
                  <button className="btn btn-sm btn-soft-danger me-2" onClick={() => {setDeleteModal(true); setDeleteItem(item);}} title="Delete Team">
                    <CustomIcon className="mdi " iconName= "mdi-delete-outline" id={`remove-${item.id}`} text="Delete"/>
                  </button>
                </td>
              </tr>
            ))}
            {teamsData?.length === 0 && <tr className="text-center"><td colSpan={head.length} className="text-danger">No Data Found</td></tr>}
          </PaginatedTable>
        </Card>
        <ToastContainer />
      </Container>
    </div>
    </>
  );
}

export default Teams;
