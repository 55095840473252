import { createSlice } from "@reduxjs/toolkit";
import { getTeams}  from "./thunk";

export const initialState = {
    teamList: [], 
    loading: true,
    pagination: {current_page : 1, per_page : 10,}
}

const TeamSlice = createSlice({
    name: "TeamSlice", 
    initialState, 
    reducers: {}, 
    extraReducers: (builder) => {
        builder.addCase(getTeams.fulfilled, (state: any, action: any) => {
            state.teamList = action.payload.items;
            state.pagination = action.payload.pagination;
            state.loading = false; // Set loading to false when request is done
        });
        builder.addCase(getTeams.pending, (state: any, action: any) => {
            state.teamList = []; // reset any previous data while fetching new data
            state.loading = true; 
        });
        builder.addCase(getTeams.rejected, (state: any, action: any) => {
            state.teamList = []; // reset any previous data
            state.loading = false; 
        });
    }
    
})


export default TeamSlice.reducer
