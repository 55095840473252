import React from "react";
import { Button, Card, CardBody, Col, Container, Form, Input, Label, Row, FormFeedback } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select"
import Breadcrumbs from "Components/Common/Breadcrumb";

const teams = [
  { label: "Development Team", value: "Development Team" },
  { label: "Testing Team", value: "Testing Team" },
  { label: "Maintenance Team", value: "Maintenance Team" },
  { label: "Management Team", value: "Management Team" },
  { label: "Deployment Team", value: "Deployment Team" },
  { label: "Monitoring Team", value: "Monitoring Team" },

]

const types = [
  { label: "Accounting & Finance", value: "Accounting & Finance" },
  { label: "Development", value: "Development" },
  { label: "Customer Service", value: "Customer Service" },
  { label: "Marketing", value: "Marketing" },
  { label: "Human Resource", value: "Human Resource" },
  { label: "Automotive Jobs", value: "Automotive Jobs" },

]

const statuses = [
  { label: "Media In Progress", value: "Media In Progress" },
  { label: "Idea In Motion", value: "Idea In Motion" },
  { label: "Published", value: "Published" },
  { label: "Archived", value: "Archived" },
  { label: "Published on Website", value: "Published on Website" },
]

const creators = [
  { label: "David McHenry", value: "David McHenry" },
  { label: "Frank Kirk", value: "Frank Kirk" },
  { label: "Rafael Morales", value: "Rafael Morales" },
  { label: "Mark Ellison", value: "Mark Ellison" },
  { label: "Minnie Walter", value: "Minnie Walter" },
]


const PostCreate = () => {

  //meta title
  document.title = "Create Activity | Content Pro";

  // validation
  const validation: any = useFormik({
    initialValues: {
      actName: '',
      status: null, 
      type: null, 
      creator: null, 
      team: null, 
    },
    validationSchema: Yup.object({
      actName: Yup.string().required("Please Enter Activity Title"),
      type: Yup
      .object()
      .shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
      .required("Please Select Activity Type"),
      team: Yup
      .object()
      .shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
      .required("Please Select a Team"),
      status: Yup
      .object()
      .shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
      .required("Please Select Activity status"),
      creator: Yup
      .object()
      .shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
      .required("Please Select a Creator"),
    }),
    onSubmit: (values: any) => {
      console.log(values);

    }
  });

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Activity" breadcrumbItem="Create Activity" />
          <Row>
            <Col>
              <Card>
                <CardBody>

                  <Form id="create-form" className="col-lg-7 col-md-10 mx-auto" onSubmit={(e: any) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>

                      <Row>
                        <Col className="mx-auto">
                          <div className="mb-3">
                            <Label htmlFor="name-input">Title</Label>
                            <Input
                              id="actName"
                              name="actName"
                              type="text"
                              placeholder="Enter Activity Title..."
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.actName}
                            />
                            {validation.touched.actName && validation.errors.actName ? (
                              <FormFeedback type="invalid" className="d-block">{validation.errors.actName}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col className="mx-auto">
                          <Label htmlFor="type">Activity Type</Label>
                          <Select
                            id="type"
                            name="type"
                            className="border border-1 border light"
                            options={types}
                            onChange={(selectedOption) => {validation.setFieldValue("type", selectedOption)}}
                            onBlur={validation.handleBlur}
                            value={validation.values.type}
                          />
                          {validation.touched.type && validation.errors.type ? (
                            <FormFeedback type="invalid" className="d-block">
                              {validation.errors.type}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col className="mx-auto">
                          <Label htmlFor="team">Team</Label>
                          <Select
                            id="team"
                            name="team"
                            className="border border-1 border light"
                            options={teams}
                            onChange={(selectedOption) => {validation.setFieldValue("team", selectedOption)}}
                            onBlur={validation.handleBlur}
                            value={validation.values.team}
                          />
                          {validation.touched.team && validation.errors.team ? (
                            <FormFeedback type="invalid" className="d-block">
                              {validation.errors.team}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col className="mx-auto">
                          <Label htmlFor="creator">Creator</Label>
                          <Select
                            id="creator"
                            name="creator"
                            className="border border-1 border light"
                            options={creators}
                            onChange={(selectedOption) => {validation.setFieldValue("creator", selectedOption)}}
                            onBlur={validation.handleBlur}
                            value={validation.values.creator}
                          />
                          {validation.touched.creator && validation.errors.creator ? (
                            <FormFeedback type="invalid" className="d-block">
                              {validation.errors.creator}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col className="mx-auto">
                          <Label htmlFor="act-status">Activity Status</Label>
                          <Select
                            id="act-status"
                            name="act-status"
                            className="border border-1 border light"
                            options={statuses}
                            onChange={(selectedOption) => {validation.setFieldValue("status", selectedOption)}}
                            onBlur={validation.handleBlur}
                            value={validation.values.status}
                          />
                          {validation.touched.status && validation.errors.status ? (
                            <FormFeedback type="invalid" className="d-block">
                              {validation.errors.status}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                      
                      <Row>
                        <div className="d-flex flex-wrap gap-2 justify-content-end mx-auto px-1 mt-1">
                          <Button type="submit" color="success" >Save Changes</Button>
                        </div>
                      </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PostCreate;
