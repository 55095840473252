import React, { useState } from 'react'
import { Button, Col, Form, FormGroup, Input, Label } from 'reactstrap';

function HashTags() {
  const [tag, setTag] = useState(""); 
  const [isOptionSelected, setIsOptionSelected] = useState(false); 

      const handleTagChange = (e: any) => {
        setTag(e.target.value)
        setIsOptionSelected(true); 
    };

    // Function to handle button click
    const handleButtonClick = () => {
        console.log(tag);
        setIsOptionSelected(false); 
    };

  return (
    <Col md={6} sm={12}>
    <Form className="">
        <FormGroup className="select2-container mb-4" row>
        <Label md={2} className="col-form-label">
            Tags
        </Label>
        <Col md={7} xs={8}>
            <Input
            id="tags"
            name="tags"
            type="text"
            placeholder="Enter Tags..."
            value ={tag}
            onChange = {handleTagChange} 
            />
        </Col>
        <Col xs={2}>
            <Button type="button" className="btn btn-soft-success waves-effect waves-light" 
              onClick={handleButtonClick}
              disabled={!isOptionSelected}
            >
            <i className="bx bx-check-circle"/>
            </Button>
        </Col>
        </FormGroup>
    </Form>
    </Col>
  )
}

export default HashTags