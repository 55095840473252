import React, { useState } from "react";
import { CardBody } from "reactstrap";
import CollapseItem from "./UI/CollapseItem";
import img6 from "../../assets/images/small/img-7.jpg";


function Backlogs() {
  const [collapseStates, setCollapseStates] = useState(true);
  return (
    <CollapseItem title="Backlogs" col={collapseStates} toggle={() => setCollapseStates((prevState) => !prevState)}>
        <CardBody>
        <div className="d-flex py-3 border-bottom overflow-hidden">
            <div className="flex-shrink-0 me-3">
            <img src={img6} className="avatar-xs rounded-circle" alt=" img" />
            </div>

            <div className="flex-grow-1">
            <h5 className="mb-1 font-size-15">Brian</h5>
            <p className="text-muted">If several languages coalesce, the grammar of the resulting language.</p>
            <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-success me-1"></i> 5 hrs ago</div>
            </div>
        </div>
        <div className="d-flex py-3 border-bottom overflow-hidden">
            <div className="flex-shrink-0 me-3">
            <img src={img6} className="avatar-xs rounded-circle" alt=" img" />
            </div>

            <div className="flex-grow-1">
            <h5 className="mb-1 font-size-15">Denver</h5>
            <p className="text-muted">To an English person, it will seem like simplified English, as a skeptical Cambridge.</p>
            <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-success me-1"></i> 5 hrs ago</div>
            </div>
        </div>
        </CardBody>
    </CollapseItem>
  );
}

export default Backlogs;
