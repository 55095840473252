import React from "react";
import { Card, CardBody, Col, Container, Label, Row, Table } from "reactstrap";
import Breadcrumbs from "Components/Common/Breadcrumb";

function Reports() {
  document.title = "Project Reports | Content Pro";

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Project" breadcrumbItem="Report" />
        <Card className="p-3">
          <CardBody className="border-bottom">Reports</CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default Reports;
