import React, { useState } from 'react';
import { Card, Progress, CardBody, Col, Container, Row, CardTitle, CardImg, CardText } from 'reactstrap';
import Breadcrumb from 'Components/Common/Breadcrumb';
import CollapseItem from 'pages/Dashboard/UI/CollapseItem';
import img6 from "assets/images/small/img-7.jpg";
import { LinkWithQuery } from 'helpers/link_helper';
export interface ProgressType {
  id: number, color: string, label: string, percentage: number
}

const PROJECTS = [
  {
    id: 1, 
    name: "Activity 1", 
    description: "This is a wider card with supporting text below.", 
    img: img6,
    createdBy: "John Smith", 
    createdAt: " 22 Oct, 19",
  }, 
  {
    id: 3, 
    name: "Activity 2", 
    description: "This is a wider card with supporting text below.", 
    img: img6,
    createdBy: "John Smith", 
    createdAt: " 22 Oct, 19",
  }, 
  // {
  //   id: 2, 
  //   name: "Activity 3", 
  //   description: "This is a wider card with supporting text below.", 
  //   img: img6,
  //   createdBy: "John Smith", 
  //   createdAt: " 22 Oct, 19",
  // }
]

const progressData: ProgressType[] = [
  { id: 1, color: "primary", label: "Pending", percentage: 78 },
  { id: 2, color: "warning", label: "In Progress", percentage: 69 },
  { id: 3, color: "success", label: "Completed", percentage: 61 }
];

const Dashboard = () => {

const [collapseStates, setCollapseStates] = useState({
    col1: true,
    col2: true,
  });

  const toggleCollapse = (col) => {
    setCollapseStates((prevState) => ({
      ...prevState,
      [col]: !prevState[col],
    }));
  };

  document.title = "Project | Content Pro";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Home" breadcrumbItem="Project" />
          <Row>

            <Col lg={8}>

              <CollapseItem title = "Activities" col={collapseStates.col1} toggle={() => toggleCollapse("col1")}>
                {PROJECTS.map(item => 
                  <LinkWithQuery to ={`/posts-details/${item.id}`} key={item.id} project={true}>
                    <div className="d-flex ms-3 ">
                      <div className="flex-shrink-0 m-auto">
                        <CardImg className="avatar-sm rounded px-0" src={item.img} alt="Skote" />
                      </div>
                      <div className="flex-grow-1">
                        <CardBody>
                          <CardTitle tag="h4">{item.name}</CardTitle>
                          <CardText className="text-muted">{item.description}</CardText>
                        </CardBody>
                      </div>
                    </div>
                  </LinkWithQuery>)
                }
              </CollapseItem>

              <CollapseItem title = "Logs" col={collapseStates.col2} toggle={() => toggleCollapse("col2")}>
                <CardBody>
                  <div className="d-flex py-3 border-bottom overflow-hidden">
                    <div className="flex-shrink-0 me-3">
                      <img src={img6} className="avatar-xs rounded-circle" alt=" img" />
                    </div>

                    <div className="flex-grow-1">
                      <h5 className="mb-1 font-size-15">Brian</h5>
                      <p className="text-muted">If several languages coalesce, the grammar of the resulting language.</p>
                      <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-primary me-1"></i> 5 hrs ago</div>
                    </div>
                  </div>
                  <div className="d-flex py-3 border-bottom overflow-hidden">
                    <div className="flex-shrink-0 me-3">
                      <img src={img6} className="avatar-xs rounded-circle" alt=" img" />
                    </div>

                    <div className="flex-grow-1">
                      <h5 className="mb-1 font-size-15">Denver</h5>
                      <p className="text-muted">To an English person, it will seem like simplified English, as a skeptical Cambridge.</p>
                      <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-primary me-1"></i> 5 hrs ago</div>
                    </div>
                  </div>
                </CardBody>
              </CollapseItem>

            </Col>

            <Col lg={4}>

            <Card>
              <CardBody>
                <div className="d-flex flex-wrap align-items-start">
                  <div className="me-2">
                    <h5 className="card-title mb-3">Status</h5>
                  </div>
                </div>
                {/* <Row className="text-center">
                  <Col xs={6}>
                    <div className="mt-3">
                      <p className="text-muted mb-1">Today</p>
                      <h5>1024</h5>
                    </div>
                  </Col>

                  <Col xs={6}>
                    <div className="mt-3">
                      <p className="text-muted mb-1">This Month</p>
                      <h5>
                        12356{" "}
                        <span className="text-success font-size-13"> 0.2 % <i className="mdi mdi-arrow-up ms-1"></i></span>
                      </h5>
                    </div>
                  </Col>
                </Row> */}

                {/* <hr /> */}
                <div>
                  <ul className="list-group list-group-flush">
                    {(progressData || [])?.map((item: ProgressType, index: number) => (
                      <li className="list-group-item" key={index}>
                        <div className="py-2">
                          <h5 className="font-size-14">
                            {item.label} <span className="float-end">{item.percentage}%</span>
                          </h5>
                          <div className="progress animated-progess progress-sm">
                            <Progress
                              className={`progress-bar bg-${item.color}`}
                              value={78}
                              style={{ width: `${item.percentage}%` }}
                            />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </CardBody>
            </Card>

            <Card>
                <CardBody>
                  <CardTitle className="h4">Categories</CardTitle>
                  <p className="card-title-desc">
                    Include multiple progress bars in a progress component if
                    you need.
                  </p>
                  <div >
                    <Progress multi>
                      <Progress bar color="primary" value={40}></Progress>
                      <Progress bar color="success" value={30}></Progress>
                      <Progress bar color="danger" value={20}></Progress>
                    </Progress>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div >

    </React.Fragment >
  );
}

export default Dashboard;