import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import withRouter from "Components/Common/withRouter";
// users
import user1 from "assets/images/users/avatar-1.jpg";
import { LinkWithQuery } from "helpers/link_helper";

const ProfileMenu = (props: any) => {

  const [menu, setMenu] = useState(false);
  
  const user = JSON.parse(localStorage.getItem("authUser") || "");
  const username = user.name; 

  return (
    <React.Fragment>

      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">

        <DropdownToggle className="btn header-item drop-menu-icon" id="page-header-user-dropdown" tag="button">
          <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar"/>
          <span className="d-none d-xl-inline-block ms-2 me-1">{username || "user"}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block my-auto" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">

          <DropdownItem tag="div">
            <LinkWithQuery to="/profile"  admin={user.role === "Admin"} className="text-dark">
              <i className="bx bx-user font-size-16 align-middle me-1" />
              <span>Profile</span>
            </LinkWithQuery>
          </DropdownItem>

          <DropdownItem tag="div">  
            <LinkWithQuery to="/change-password"  admin={user.role === "Admin"} className="text-dark">
              <i className="mdi mdi-key-variant font-size-16 align-middle me-1" />
              <span>Change Password</span>
            </LinkWithQuery>
          </DropdownItem>

          <div className="dropdown-divider" />

          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </Link>
          
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(ProfileMenu));