import { toast } from "react-toastify";
import request from "../../../request_helper";

export const getSubscription = async (id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "stripe/subscriptions/" + id,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        // toast.success(response.message, { autoClose: 2000 });
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}